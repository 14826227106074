// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let account = await store.getState()["blockchain"]["account"];
      let userBalance = await store
        .getState()
        .blockchain.shlSmartContract.methods.balanceOf(account)
        .call();

      let approvedShlToTurtleTreasure = await store
        .getState()
        .blockchain.shlSmartContract.methods.allowance(account, "0x6Fc303504c27604AA2eBb6f13E6EaccDAc6fD6a7")
        .call();
      let contractFeePoints = await store
        .getState()
        .blockchain.smartContract.methods.contractFeePoints()
        .call();
      let cooldownBlocks = await store
        .getState()
        .blockchain.smartContract.methods.cooldownBlocks()
        .call();
      let currentPot = await store
        .getState()
        .blockchain.smartContract.methods.currentPot()
        .call();
      let finderFeePoints = await store
        .getState()
        .blockchain.smartContract.methods.finderFeePoints()
        .call();
      let getLotteryEndBlock = await store
        .getState()
        .blockchain.smartContract.methods.getLotteryEndBlock()
        .call();
      let getPlayers = await store
        .getState()
        .blockchain.smartContract.methods.getPlayers()
        .call();
      let isLotteryClaimable = await store
        .getState()
        .blockchain.smartContract.methods.isLotteryClaimable()
        .call();
      let ticketCost = await store
        .getState()
        .blockchain.smartContract.methods.ticketCost()
        .call();
      let recentWinner = await store
        .getState()
        .blockchain.smartContract.methods.recentWinner()
        .call();
      let recentWinPot = await store
        .getState()
        .blockchain.smartContract.methods.recentWinPot()
        .call();

      let turtlesStaked = await store
        .getState()
        .blockchain.shlSmartContract.methods.getTokensStaked(account)
        .call();
      //let approvedShlToBrawlContract = await store
      //  .getState()
      //  .blockchain.shlSmartContract.methods.allowance(account, "0xc6c6239614723298591f16bB2f779C9199b5AB1a")
      //  .call();

      dispatch(
        fetchDataSuccess({
          account,
          userBalance,

          TurtleTreasure : {
            approvedShlToTurtleTreasure,
            contractFeePoints,
            cooldownBlocks,
            currentPot,
            finderFeePoints,
            getLotteryEndBlock,
            getPlayers,
            isLotteryClaimable,
            ticketCost,
            recentWinner,
            recentWinPot
          },
          TurtleBrawl : {
            turtlesStaked
          }
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
