import { connect } from '../redux/blockchain/blockchainActions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../redux/data/dataActions'
import { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import BrawlBackground from '../TurtleBrawl/battleHomeBackground'
import bgimg from '../assets/images/bg.png'
import SplashScreen from './SplashScreen'
import TurtleMetadata from '../Nftdata/TurtleMetadata'
import GameBoard from './GameBoard'
import BattleHomeAnimted from './battleHomeAnimated'
import TubbyTurtleBrawl from '../assets/TubbyTurtleBrawl'
import GrassHome from '../assets/GrassHome'
import UnknownOponnent from '../assets/images/unknownOponnent.png'
import allElements from '../assets/images/allElements.png'
import BrawlCard from './BrawlCard'
import BrawlGameRules from './BrawlGameRules'
import TabSwitcher from './TabSwitcher'
import ShellCoin from '../assets/images/shellcoin.png'
import Schematic from '../assets/images/turtleBattleGameSchematic.png'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { dbTwo } from '../firebase'
import { onValue, ref, get } from 'firebase/database'

import elementsSheet from '../assets/images/elementSheet.png'

import { BiRightArrow, BiDownArrow } from 'react-icons/bi'

import Fire from '../assets/images/elementsymbols/Fire.png';
import Water from '../assets/images/elementsymbols/Water.png';
import Poison from '../assets/images/elementsymbols/Poison.png';
import Electric from '../assets/images/elementsymbols/Electric.png';
import Frost from '../assets/images/elementsymbols/Frost.png';
import Rock from '../assets/images/elementsymbols/Rock.png';
import Nature from '../assets/images/elementsymbols/Nature.png';
import MetamaskAccountIcon from '../components/MetamaskAccountIcon'
import Web3 from 'web3'

import { TurtleRarities, TurtleElements } from './TurtleData';

const TurtleTreasurePage = () => {
    /* global BigInt */

    const weiToEther = (weiInput) => {
        let ethBalance = Web3.utils.fromWei(weiInput, 'ether');
        return ethBalance;
    }

    const etherToWei = (etherInput) => {
        let ethBalance = Web3.utils.toWei(etherInput, 'ether');
        return ethBalance;
    }

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: null
        },
        GAS_LIMIT: null
    });
    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);

    const [isConnected, setIsConnected] = useState(false);
    useEffect(() => {
        getData();
        if (blockchain.account !== "" && blockchain.account !== null) {
            dispatch(fetchData());
            setIsConnected(true)
        }
    }, [blockchain.account]);

    const [introScreenDisplayed, setIntroScreenDisplayed] = useState(true);
    // ---------------------

    const [UserHasSelected, setUserHasSelected] = useState(false)
    const [userSelectedTurtle, setUserSelectedTurtle] = useState('');
    const [userSelectedMaskElement, setUserSelectedMaskElement] = useState('');
    const [elementMaskingExpanded, setElementMaskingExpanded] = useState(false);

    const emptyBrawls = [{
        turtleId1: 0,
        turtleId2: 0,
        turtleElem1: '',
        turtleElem2: '',
        player1: '',
        player2: '',
        wagerAmount: 0
    },
    {
        turtleId1: 0,
        turtleId2: 0,
        turtleElem1: '',
        turtleElem2: '',
        player1: '',
        player2: '',
        wagerAmount: 0
    },
    {
        turtleId1: 0,
        turtleId2: 0,
        turtleElem1: '',
        turtleElem2: '',
        player1: '',
        player2: '',
        wagerAmount: 0
    }]

    function checkIfTurtleHasBrawlCooldown(turtleIdToCheck) {
        if (turtleIdToCheck === '505') {
            return true
        }
        else {
            return false
        }
    }

    const [listWithCooldownTurtles, setListWithCooldownTurtles] = useState(false);

    useEffect(() => {
        let CooldownTurtles = [];
        for (let i = 0; i < data.TurtleBrawl.turtlesStaked.length; i++) {
            const element = data.TurtleBrawl.turtlesStaked[i];
            if (checkIfTurtleHasBrawlCooldown(element) === true) {
                CooldownTurtles.push(element);
            }
        }
        setListWithCooldownTurtles(CooldownTurtles)
    }, [data.loading])

    console.log(listWithCooldownTurtles)

    console.log(blockchain.brawlSmartContract)
    /* Brawl functions */
    function approveShellsToBrawlContract() {
        let approveAmountStaking = '100000000000000000000000';
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.shlSmartContract.methods.approve(CONFIG.BRAWL_ADDRESS, approveAmountStaking)
            .send({
                to: CONFIG.SHL_CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
    }

    function getTokenIdOfTurtle(tokenId) {
        return TurtleElements[parseInt(tokenId - 1)]
    }
    function createBrawl(tokenId) {
        try {
            let turtleElem = getTokenIdOfTurtle(tokenId)
            let cost = 0;
            let totalCostWei = String(cost);
            let wagerAmount = document.getElementById('wagerAmountInput').value
            console.log(etherToWei(wagerAmount))
            blockchain.brawlSmartContract.methods.createBrawl(tokenId, turtleElem, etherToWei(wagerAmount))
                .send({
                    to: CONFIG.BRAWL_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .catch(function (error) {
                    console.log('\x1b[31m%s\x1b[0m', 'Transaction interrupted or canceled', error);
                });
        } catch (error) {
            console.log('\x1b[31m%s\x1b[0m', 'Error:', error);
        }
    }

    const [brawlsListFirebase, setBrawlsListFirebase] = useState(null);
    useEffect(() => {
        const dbRef = ref(dbTwo);
        const unsubscribe = onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const dataArray = Object.values(data);
                dataArray.reverse()
                setBrawlsListFirebase(dataArray);
            } else {
                setBrawlsListFirebase([])
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    console.log(brawlsListFirebase)

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    const codeString = `
    const randomNumber = Math.floor(Math.random() * (220 - 180 + 1)) + 180;
    const multiplier = (100 - rarityTurtle) / 100;
    const weightFactor = 0.1;
    let modifiedRandomNumber = 0;
    // If player has the element advantage
    if (determineElementAdvantage(turtleId1, turtleId2) === 1) {
        modifiedRandomNumber = Math.floor((randomNumber * (1 - weightFactor + weightFactor * multiplier)) * 1.05);
    }
    // else the 5% boost won't be applied
    else {
        modifiedRandomNumber = Math.floor(randomNumber * (1 - weightFactor + weightFactor * multiplier));
    }
  `;

    return (
        <div>
            <div className='flex mx-auto justify-center'>

                <GrassHome />
                <div style={{ backgroundImage: `url(${allElements})` }} className='max-h-[90vh] overflow-y-scroll absolute top-24 w-[95%] sm:w-[85%] md:w-[75%] lg:w-[70%] border-[6px] border-[#779B49] shadow-2xl bg-cover rounded-3xl pb-8'>

                    <div className="tab-switcher">
                        <button
                            className={`w-1/2 h-[60px] font-bold text-xl tab-button rounded-tl-2xl ${activeTab === 'tab1' ? 'active-tab bg-[rgba(235,235,235,0.8)]' : 'bg-[rgba(150,150,150,0.8)]'}`}
                            onClick={() => handleTabChange('tab1')}
                        >
                            Brawl
                        </button>
                        <button
                            className={`w-1/2 h-[60px] font-bold text-xl tab-button rounded-tr-2xl ${activeTab === 'tab2' ? 'active-tab bg-[rgba(235,235,235,0.8)]' : 'bg-[rgba(150,150,150,0.8)]'}`}
                            onClick={() => handleTabChange('tab2')}
                        >
                            Rules
                        </button>
                    </div>
                    {activeTab === 'tab1' ? (
                        <div className='ThisContent'>
                            <div className='flex justify-center'>
                                <TubbyTurtleBrawl />
                            </div>

                            {isConnected ? (
                                <div className='flex flex-col items-center'>
                                    <div className='flex flex-row'>
                                        <button disabled className='font-bold h-full rounded-lg bg-green-400 grayscale 2xs:px-[2px] xs:px-1 px-2 sm:px-3 xs:py-[2px] py-[3px] sm:py-[4px] flex items-center text-center text-xl' onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(connect());
                                            getData();
                                        }}>
                                            <svg width="32px" height="32px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                            </svg>
                                            Connected
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className='flex flex-col items-center'>
                                    <div className='flex flex-row'>
                                        <button className='font-bold hover:brightness-110 h-full rounded-lg bg-green-400 2xs:px-[2px] xs:px-1 px-2 sm:px-3 xs:py-[2px] py-[3px] sm:py-[4px] flex items-center text-center text-xl' onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(connect());
                                            getData();
                                        }}>
                                            <svg width="32px" height="32px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                            </svg>
                                            Connect
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div className='my-2'>
                                {isConnected ? (
                                    <div className='my-2'>
                                        <abbr className='!no-underline' title='Only turtles that are on a sea adventure can be used to brawl with'>
                                            <p className='font-bold text-lg text-center'>Your collection of turtles ready to brawl:</p>
                                        </abbr>

                                        {data.TurtleBrawl.turtlesStaked.length === 0 && data.loading === true ? (
                                            <div className='flex mx-auto justify-center'>
                                                <ClipLoader />
                                            </div>
                                        ) : (
                                            data.loading === false && data.TurtleBrawl.turtlesStaked.length === 0 ? (
                                                <div>
                                                    <p className='text-lg text-center'>You don't have any turtles, you can either <a className='hover:brightness-110 font-semibold text-[#779B49]' href='https://tubbyturtles.com/mint' target='_blank'>mint</a> one, <a className='hover:brightness-110 font-semibold text-[#779B49]' href='https://raregems.io/energyweb/tubby-turtles' target='_blank'>buy</a> one or <a className='hover:brightness-110 font-semibold text-[#779B49]' href='https://auction.energywebnfts.com/' target='_blank'>buy one on auction</a></p>
                                                    <div className='flex justify-center'>
                                                        <div>
                                                            <img className={`border-4 border-white w-[64px] rounded-lg mx-[3px]`} src={UnknownOponnent} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='flex flex-wrap justify-center'>
                                                    {data.TurtleBrawl.turtlesStaked.map((turtle) => (
                                                        <div className='cursor-pointer hover:brightness-110 hover:scale-[1.1]'>
                                                            <img onClick={() => { setUserSelectedTurtle(turtle); setUserHasSelected(true) }} className={`${turtle === userSelectedTurtle ? 'border-4 border-green-600' : 'border-4 border-white'} w-[64px] rounded-lg mx-[3px]`} src={`https://storage.googleapis.com/turtlestubby/${turtle}.png`} />
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        )}

                                        <div className='my-4'>
                                            {/*
                                        <div className='text-center text-lg'>
                                            {data.TurtleBrawl.approvedShlToBrawlContract !== null && data.loading === false ? (
                                                <p>You have {Math.round(parseFloat(BigInt(data.TurtleBrawl.approvedShlToBrawlContract) / BigInt(1e18)))} SHL approved to the TurtleBrawl contract</p>
                                            ) : (
                                                <p>You have ? SHL approved to the TurtleBrawl contract</p>
                                            )}
                                        </div>
                                            */}
                                            <p className='text-center'>Make sure to always have enough SHL approved</p>
                                            <button className='hover:brightness-110 flex mx-auto bg-blue-300 py-[2px] px-[6px] rounded-lg' onClick={() => { approveShellsToBrawlContract() }}>Approve SeaShells</button>


                                            <div className='my-3 flex justify-center flex-row flex-wrap rounded-lg'>
                                                {/*
                                                <div className='border-2 border-gray-700 bg-white rounded-xl mx-[10px] my-[5px] w-[220px] h-[200px]'>

                                                    <div className='h-[106px] flex flex-row m-1 justify-around border-2 border-gray-700 rounded-lg'>
                                                        <div className='w-full sm:w-[50%] relative'>
                                                            <div className='absolute flex w-full z-[20]'>
                                                                <img className='flex         2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={UnknownOponnent} />
                                                            </div>

                                                            <img className='rounded-l-md w-full' src={UnknownOponnent} />

                                                        </div>
                                                        <div className='w-full sm:w-[50%] relative'>
                                                            <div className='absolute flex w-full z-[20]'>
                                                                <img className='flex 2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={UnknownOponnent} />
                                                            </div>

                                                            <img className='rounded-r-md w-full' src={UnknownOponnent} />

                                                            <svg className="absolute inset-0 w-full h-full -translate-x-[48%]" version="1.1" viewBox="0 0 35.454 66.146" xmlns="http://www.w3.org/2000/svg">
                                                                <g strokeLinecap="butt" strokeLinejoin="miter">
                                                                    <path d="M21.41 34.214l-.455-.032-.659-.094-.479-.133-.478-.188-.443-.29-.424-.38-.264-.356-.21-.416-.11-.32-.071-.315-.067-.596.04-.533.16-.58.212-.604.18-.587.079-.518-.031-.47-.071-.462-.086-.486.384.258.392.235.306.228.314.274.337.321.365-.528.31-.585.204-.603.063-.549.008-.486-.032-.517.463.376.66.604.391.423.338.455.337.533.204.479.188.563.118.4.314-.33.29-.274.18-.172.15-.157.02.321.074.326.157.454.243.85.074.485.012.448-.01.4-.037.369-.07.43-.228.651-.243.455-.314.376-.432.314-.337.203-.635.228-.502.094-.44.023z" fill="#ff2929" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.151" />
                                                                    <path d="M19.405 28.908l.017.556-.004.436-.047.433-.072.318-.072.229-.055.228-.05.297.003.34.038.275.094.305.12.218.105.197.077.127.105.134.114.117.14.116.152.09.163.082.175.075.187.077.182.07.185.055.205.051.215.038.314.02.244-.015.134-.013.326-.057.263-.08.276-.13.24-.163.2-.146.188-.189.11-.144.075-.161.06-.132.065-.205.085-.297.06-.299.03-.3.02-.304-.034-.364-.08-.375-.115-.337-.123-.346-.093-.244-.194.217-.186.186-.234.225-.17-.513-.124-.343-.17-.346-.242-.377-.216-.28-.297-.322-.187-.2-.208-.25-.034.255-.034.284-.046.288-.06.28-.076.246-.076.19-.11.212-.166.234-.22.275-.247-.233-.263-.195-.19-.11-.238-.144z" fill="#ff7e29" opacity=".645" stroke="#ff7519" fillOpacity="1" strokeOpacity="1" strokeWidth="0.012" />
                                                                    <path d="M20.715 32.633l-.007-.27.068-.377.11-.356.119-.263.148-.276.17-.216.274-.286.214.29.186.276.144.258.114.24.102.293.078.27.012.276-.042.246-.084.185-.126.174-.168.102-.243.096-.216.036-.23-.02-.185-.067-.194-.135-.123-.132-.08-.148z" fill="#ffde29" opacity=".645" stroke="#ffd800" fillOpacity="1" strokeOpacity="1" strokeWidth="0.012" />
                                                                    <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff228" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.164" />
                                                                    <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="ivory" fillOpacity="1" strokeOpacity="1" strokeWidth="0.017" />
                                                                    <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff228" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.164" />
                                                                    <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="ivory" fillOpacity="1" strokeOpacity="1" strokeWidth="0.017" />
                                                                    <path d="M21.41 34.214l-.455-.032-.659-.094-.479-.133-.478-.188-.443-.29-.424-.38-.264-.356-.21-.416-.11-.32-.071-.315-.067-.596.04-.533.16-.58.212-.604.18-.587.079-.518-.031-.47-.071-.462-.086-.486.384.258.392.235.306.228.314.274.337.321.365-.528.31-.585.204-.603.063-.549.008-.486-.032-.517.463.376.66.604.391.423.338.455.337.533.204.479.188.563.118.4.314-.33.29-.274.18-.172.15-.157.02.321.074.326.157.454.243.85.074.485.012.448-.01.4-.037.369-.07.43-.228.651-.243.455-.314.376-.432.314-.337.203-.635.228-.502.094-.44.023z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="0.25" strokeWidth="0.151" />
                                                                    <path d="M19.405 28.908l.017.556-.004.436-.047.433-.072.318-.072.229-.055.228-.05.297.003.34.038.275.094.305.12.218.105.197.077.127.105.134.114.117.14.116.152.09.163.082.175.075.187.077.182.07.185.055.205.051.215.038.314.02.244-.015.134-.013.326-.057.263-.08.276-.13.24-.163.2-.146.188-.189.11-.144.075-.161.06-.132.065-.205.085-.297.06-.299.03-.3.02-.304-.034-.364-.08-.375-.115-.337-.123-.346-.093-.244-.194.217-.186.186-.234.225-.17-.513-.124-.343-.17-.346-.242-.377-.216-.28-.297-.322-.187-.2-.208-.25-.034.255-.034.284-.046.288-.06.28-.076.246-.076.19-.11.212-.166.234-.22.275-.247-.233-.263-.195-.19-.11-.238-.144z" fill="#fff" opacity=".645" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.012" />
                                                                    <path d="M20.715 32.633l-.007-.27.068-.377.11-.356.119-.263.148-.276.17-.216.274-.286.214.29.186.276.144.258.114.24.102.293.078.27.012.276-.042.246-.084.185-.126.174-.168.102-.243.096-.216.036-.23-.02-.185-.067-.194-.135-.123-.132-.08-.148z" fill="#fff" opacity=".645" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.012" />
                                                                    <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="0.25" strokeWidth="0.164" />
                                                                    <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.017" />
                                                                    <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="0.25" strokeWidth="0.164" />
                                                                    <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.017" />
                                                                    <path d="m11.766 65.419 9.043-28.193 3.957 1.018-3.82 27.175z" fill="#fff" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.454" />
                                                                    <path d="M23.133.67l-9.394 23.771-3.171-1.129L14.393.67z" fill="#fff" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.346" />
                                                                    <path d="M5.623 45.855l2.313-7.364-7.365-.944 5.477-5.712-5.335-5.38 7.082-2.172-1.511-6.75 8.167 3.257 3.824-7.741 2.597 7.694 7.365-3.21-1.889 6.797 8.074.472-5.713 5.664 6.009 3.685-6.387 1.272 3.352 5.806-6.515-.897.047 6.325-5.523-4.248-5.24 6.23-1.653-7.269z" fill="#255cd0" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.546" />
                                                                    <path d="M9.257 23.575l-.944-6.184 7.316 3.635 4.107-6.94 1.793 7.506 6.94-2.36-2.455 5.995 7.222 1.133-5.381 4.767 4.909 3.682-5.712.991 2.407 5.381-5.758-1.227-.425 5.523-4.626-4.201-5.381 5.192-.897-6.608-6.844 3.21 2.596-6.467-6.326-1.464 5.193-4.956-4.39-5.098z" fill="#fff" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.159" />
                                                                    <path d="M4.544 26.054l7.734 12.2 3.587-.707 2.678-14.27-3.976.652-1.275 9.228-4.513-7.993z" stroke="#000" fillOpacity="1" strokeOpacity="1" strokeWidth="0.018" />
                                                                    <path d="M19.874 34.896l-1.502 2.57c3.13 1.381 6.07 2.444 8.968.308 2.623-2.393 2.482-5.087-1.053-7.344-1.677-1.043-3.862-2.276-2.473-2.646.988-.192 2.109.523 3.348 1.101l1.301-2.476c-1.813-1.536-3.879-2.012-6.313-1.25-1.719.71-2.823 1.463-2.9 3.88.188 1.236.9 2.493 2.72 3.792l2.05 1.435c.664.388 1.603.86 1 1.353-1.81.807-2.89.478-5.146-.723z" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.271" />
                                                                    <path d="M5.57 25.36l7.733 12.2 3.588-.708 2.677-14.269-3.975.651-1.275 9.228-4.513-7.992z" fill="#a30000" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.451" />
                                                                    <path d="M20.164 33.926l-1.502 2.57c3.129 1.381 6.401 2.596 9.299.46 2.622-2.393 1.82-4.378-2.21-6.942-1.677-1.044-3.036-2.63-1.647-3.001.988-.192 2.439.476 3.678 1.054l1.302-2.476c-1.813-1.535-3.88-2.012-6.314-1.25-1.718.71-2.823 1.463-2.9 3.88.188 1.236.9 2.493 2.72 3.793l2.05 1.434c.664.388 1.604.86 1 1.353-1.81.807-3.22.325-5.476-.875z" fill="#a30000" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.451" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>

                                                    <div className='h-[calc(94px-16px)] text-sm border-gray-700 rounded-lg m-1 border-2 flex flex-col xs:p-[2px] p-[3px] sm:p-[4px]'>
                                                        <p className='text-center truncate'>Wager amount: <input id='wagerAmountInput' className='border-[1px] border-gray-800 rounded-md px-[2px] w-[70px]' placeholder='Amount' type="text"></input> <img className='w-[16px] inline-block -translate-y-[2px]' src={ShellCoin} /></p>
                                                        <div className='flex flex-row'>
                                                            <p className='w-1/2 flex flex-row justify-center'>Player 1: <span className='ml-1 flex items-center -translate-y-[1px]'><img className='w-[16px] h-[16px] rounded-full' src={UnknownOponnent} /></span></p>
                                                            <p className='w-1/2 flex flex-row justify-center'>Player 2: <span className='ml-1 flex items-center -translate-y-[1px]'><img className='w-[16px] h-[16px] rounded-full' src={UnknownOponnent} /></span></p>
                                                        </div>

                                                        <div className='flex justify-center'>
                                                            {UserHasSelected === true ? (
                                                                <button onClick={() => { createBrawl(userSelectedTurtle) }} className='bg-green-300 rounded-lg py-[2px] px-[5px]'>Create Brawl</button>
                                                            ) : (
                                                                <button disabled className='bg-green-300 grayscale rounded-lg py-[2px] px-[5px]'>Create Brawl</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                */}
                                                {brawlsListFirebase !== null && brawlsListFirebase.length > 0 ? (
                                                    brawlsListFirebase.map((brawl) => (
                                                        <BrawlCard brawlInfo={brawl} turnedOff={false} noTurtleSelected={UserHasSelected} selectedTurtle={userSelectedTurtle} />
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    <div>
                                        <p className='text-center text-lg'>
                                            Connect to view available turtle brawls to join, or create your own brawl.
                                        </p>
                                        <div className='flex justify-center flex-row flex-wrap rounded-lg'>
                                            {emptyBrawls.map((brawl) => (
                                                <BrawlCard brawlInfo={brawl} turnedOff={true} />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    ) : (
                        <div>
                            <div className='flex justify-center'>
                                <TubbyTurtleBrawl />
                            </div>
                            <div className='px-4 sm:px-8 md:px-12'>
                                <div>
                                    <h1 className='font-bold text-lg'>The game rules</h1>
                                    <ul className="list-disc">
                                        <li>
                                            <p>Only staked turtles are able to brawl.</p>
                                        </li>
                                        <li>
                                            <p>You need to have 2x the amount of SHL you are wagering. This is to incentivize players to always end the brawls even when one player seems to be going to lose anyway.</p>
                                        </li>
                                    </ul>
                                    <p>For example: If 2 players want to wager 1000 SHL in a turtle brawl, they must each deposit 2000 SHL, totaling 4000 SHL in the contract. The loser reclaims their 1000 SHL, while the winner receives 2920 SHL (-2% fees). The 2% fee (80 SHL) from the winner is retained in the contract for potential future use or burning.</p>
                                </div>

                                <div className='flex flex-col'>
                                    <img className='w-[90%] sm:w-[70%] md:w-[60%] mx-auto' src={Schematic} />
                                    <p className='text-sm text-[rgb(70,70,70)] mb-1'>Turtle element advantage table: For example if player 1 has a poison turtle and player 2 has a rock turtle, then rock will have the advantage as it shows the color orange in the table.</p>
                                    <p>Each element has a slight advantages over others, and rarities also impact the power of the moves slightly, below shown the math behind the attack move for example (the heal and defend moves work in the same way).</p>
                                    <SyntaxHighlighter language="javascript" style={docco}>
                                        {codeString}
                                    </SyntaxHighlighter>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TurtleTreasurePage;