const initialState = {
  loading: false,
  account: "",
  userBalance: 0,

  TurtleTreasure : {
  approvedShlToTurtleTreasure: 0,
  contractFeePoints: 0,
  cooldownBlocks: 0,
  currentPot: 0,
  finderFeePoints: 0,
  getLotteryEndBlock: 0,
  getPlayers: [],
  isLotteryClaimable: null,
  ticketCost: 0,
  recentWinner: "",
  recentWinPot: null
  },
  TurtleBrawl : {
    turtlesStaked: []
  },
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        userBalance: action.payload.userBalance,

        TurtleTreasure : {
        approvedShlToTurtleTreasure: action.payload.TurtleTreasure.approvedShlToTurtleTreasure,
        contractFeePoints: action.payload.TurtleTreasure.contractFeePoints,
        cooldownBlocks: action.payload.TurtleTreasure.cooldownBlocks,
        currentPot: action.payload.TurtleTreasure.currentPot,
        finderFeePoints: action.payload.TurtleTreasure.finderFeePoints,
        getLotteryEndBlock: action.payload.TurtleTreasure.getLotteryEndBlock,
        getPlayers: action.payload.TurtleTreasure.getPlayers,
        isLotteryClaimable: action.payload.TurtleTreasure.isLotteryClaimable,
        ticketCost: action.payload.TurtleTreasure.ticketCost,
        recentWinner: action.payload.TurtleTreasure.recentWinner,
        recentWinPot: action.payload.TurtleTreasure.recentWinPot
        },
        TurtleBrawl : {
          turtlesStaked: action.payload.TurtleBrawl.turtlesStaked
        },
        error: false,
        errorMsg: ""
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
