import { useState } from 'react'
import { Link } from 'react-router-dom'

const TabSwitcher = () => {
    const [activeTab, setActiveTab] = useState('BrawlTab');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='w-full h-[60px] flex flex-row rounded-t-2xl'>
            <Link to='/brawl'
                className={`rounded-tl-2xl bg-green-300 hover:brightness-[1.1] w-1/2 flex items-center justify-center text-2xl font-bold 
                ${activeTab === 'BrawlTab' ? '' : 'brightness-[0.8]'}`}
                onClick={() => handleTabChange('BrawlTab')}>
                Brawl
            </Link>
            <Link to='/brawl/rules'
                className={`rounded-tr-2xl bg-green-300 hover:brightness-[1.1] w-1/2 flex items-center justify-center text-2xl font-bold 
                ${activeTab === 'RulesTab' ? '' : 'brightness-[0.8]'}`}
                onClick={() => handleTabChange('RulesTab')}>
                Game rules
            </Link>
        </div>
    );
}

export default TabSwitcher;