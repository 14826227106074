import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyANuJiG-HTX_l5YZ9CePtZI1OdtebvVkhQ",
    authDomain: "tubbyturtles-86bc9.firebaseapp.com",
    databaseURL: "https://tubbyturtlesgames.firebaseio.com",
    projectId: "tubbyturtles-86bc9",
    storageBucket: "https://storage.googleapis.com/turtlestubby",
    messagingSenderId: "817724247427",
    appId: "1:817724247427:web:2dee18be5211d98c307ab6",
    measurementId: "G-6EWN7KDPS4"
};
const app = initializeApp(firebaseConfig);
const app_two = initializeApp({
    databaseURL: "https://tubbyturtlesbrawls00.firebaseio.com",
}, 'appTwo');
const app_three = initializeApp({
    databaseURL: "https://turtlestubby.firebaseio.com",
}, 'appThree');

export const db = getDatabase(app)
export const dbTwo = getDatabase(app_two)
export const dbThree = getDatabase(app_three)