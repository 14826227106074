const HealingIcon = ({ size }) => {
    return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 26.458 26.458"
    >
      <g
        fill="#000"
        fillOpacity="1"
        stroke="none"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeMiterlimit="4"
        strokeWidth="2.432"
        paintOrder="fill markers stroke"
      >
        <circle cx="4.233" cy="17.462" r="1.587"></circle>
        <circle cx="10.186" cy="17.462" r="1.587"></circle>
        <circle cx="16.14" cy="17.462" r="1.587"></circle>
        <circle cx="22.093" cy="17.462" r="1.587"></circle>
      </g>
    </svg>
    );
}

export default HealingIcon;