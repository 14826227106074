const BackgroundSvg = () => {
    return (
        <svg className='bg-cover max-w-[100%] max-h-[100vh]'
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
            width="2560"
            height="1440"
            version="1.1"
            viewBox="0 0 677.333 381"
        >
            <g display="inline">
                <path
                    fill="#01c0d2"
                    fillOpacity="1"
                    stroke="#01c0d2"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeOpacity="1"
                    strokeWidth="0.265"
                    d="M0 344.633V159.231l677.333 8.307V347.57z"
                    display="inline"
                ></path>
            </g>
            <g
                fillOpacity="1"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeOpacity="1"
                strokeWidth="0.265"
                display="inline"
            >
                <path
                    fill="#25ccdb"
                    stroke="#25ccdb"
                    d="M0 0h677.333v167.538L0 159.231z"
                ></path>
                <path
                    fill="#17c6d9"
                    stroke="#17c6d9"
                    d="M0 98.308c80.754 12.716 112.277 16.02 168.923 15.23 92.44-6.735 136.3-2.833 182.77.693 72.124 6.118 94.717 7.034 117.692 7.615 120.276-2.531 163.164-7.048 207.948-16.769v102.615c-37.32-.972-57.471-7.669-124.871 1.385-57.975 2.332-81.183 12.038-193.847 2.77-61.392-.945-111.124-14.54-184.153-2.77-26.77.93-54.231.89-81-5.539-33.087-4.138-48.889-6.457-93.462 0z"
                ></path>
            </g>
            <g
                fillOpacity="1"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeOpacity="1"
                strokeWidth="0.265"
                display="inline"
            >
                <path
                    fill="#00828f"
                    stroke="#00828f"
                    d="M0 381v-74.308c60.457.307 130.69 8.708 229.154 15.231 108.539 8.065 155.911 3.465 260.308 3.462 89.212-2.039 144.684-11.5 187.871-13.847l.232 69.462z"
                ></path>
                <path
                    fill="#00788f"
                    stroke="#00788f"
                    d="M0 356.382v-11.749l143.923 1.958 181.129 6.854 190.918-4.896 161.363-.979.232 33.43H0v-24.618"
                ></path>
            </g>
            <g
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeOpacity="1"
                display="inline"
                opacity="0.4"
            >
                <path
                    fill="none"
                    stroke="#000"
                    strokeWidth="0.265"
                    d="M181.289 615.007c.938-.86 2.153-3.554 2.572-3.554 1.026-.215 3.62 5.544 4.678 5.472.789-.223 5.313-5.883 6.641-5.753.708.268 1.94 4.579 3.134 4.396 2.194-.488 2.07-1.609 3.04-2.432.705-.772 5.566 2.19 5.66 3.461.192.7-.62 3.62-.094 3.976 3.348 1.877 6.296 7.237 6.642 10.15-.132 1.585.798 3.272-3.695 4.35-2.011.358-4.763.157-8.7-.89-2.043-.477-6.885-.833-7.53-1.122-.738-.309-.3-3.196-.795-3.601-.475-.52-7.36-1.085-8.092-1.824-1.03-.984-1.243-4.62-1.31-5.052-.378-.694-3.608-1.306-5.004-3.32-.787-1.14-.217-2.053.468-2.947.962-.995 1.772-.51 2.385-1.31z"
                ></path>
                <path
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.08"
                    d="M534.278 449.948c-1.24-.576-1.919-1.673-1.45-3.835l1.404-5.753c.446-1.58 2.606-2.386 5.18-1.941 4.697.787 8.392 1.908 11.985 3.063 3.34.993 6.638 1.078 9.916.702 2.579-.565 4.015-1.477 4.537-2.666.27-1.196.165-2.327-.468-3.368-1.07-1.633-1.718-3.057-1.544-4.069.324-.926 1.151-.988 1.59-.608.439.434 2.03 3.36 2.807 4.07.891.595 2.216.815 4.7.362.718-.141 1.379-.14 2.035-.129 1.092.011.671.537.445.997-.29.432-.586.863-1.03 1.295-.688.582-1.558.921-3.297 1.216-1.514.53-2.334 1.907-3.18 3.789-.723 1.511-2.477 3.012-5.847 5.051-2.31 1.215-4.954 2.309-7.951 3.274-.7.193-1.41.383-2.386.515 1.29 2.962-.193 3.346-2.058 2.432-1.254-.877-1.267-1.822-1.78-1.907-2.47.141-5.737-.027-8.83-.199-1.74-.152-3.477-.298-5.26-.794-.353-.193-.213-.84.299-.826h1.356c.343.065.382-.332.033-.364a4.73 4.73 0 01-1.206-.307z"
                ></path>
                <path className='whale'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.344"
                    d="M119.371 120.864c-5.344-2.481-8.266-7.207-6.244-16.517l6.043-24.776c1.923-6.802 11.224-10.277 22.308-8.36 20.231 3.39 36.142 8.22 51.616 13.194 14.383 4.276 28.588 4.639 42.703 3.022 11.107-2.435 17.292-6.362 19.539-11.482 1.165-5.151.711-10.023-2.015-14.503-4.605-7.036-7.4-13.167-6.647-17.524 1.393-3.988 4.956-4.258 6.849-2.619 1.886 1.869 8.742 14.47 12.085 17.525 3.84 2.564 9.544 3.51 20.244 1.56 3.09-.607 5.936-.605 8.762-.553 4.701.046 2.89 2.312 1.917 4.293-1.25 1.859-2.527 3.718-4.435 5.577-2.966 2.504-6.712 3.966-14.2 5.237-6.52 2.282-10.053 8.211-13.698 16.316-3.11 6.51-10.667 12.973-25.178 21.754-9.953 5.232-21.337 9.943-34.243 14.1-3.014.83-6.075 1.65-10.273 2.216 5.558 12.757-.832 14.409-8.863 10.474-5.402-3.774-5.457-7.848-7.668-8.214-10.633.611-24.707-.114-38.03-.854-7.488-.654-14.973-1.284-22.646-3.419-1.525-.834-.92-3.622 1.282-3.56h5.84c1.48.282 1.646-1.43.142-1.567-1.76-.228-3.497-.624-5.19-1.32z"
                ></path>
                <path
                    fill="none"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.05"
                    d="M558.875 456.498c-1.87-.303-2.424-1.27-.406-2.804.328-.33.146-.837.467-.952 2.694-.154 2.395.646 2.692 1.167.217.247.586.205.91.133.368-.152.464-.691.742-.818.422-.166.411.208.336.526-.044.186-.196.43-.15.572.071.127.367.285.543.364.18.136-.049.41-.326.515-.363.057-.769-.228-.898-.233-.196-.031-.66.225-.678.38-.005.075.221.223.337.316.14.26-.103.43-.585.527-.315.06-.516-.336-.628-.364-.136 0-.44.322-.713.46-.262.133-.623.124-.652.237-.004.046.026.112.067.16.226.267.236.687-.483.532-.362-.132-.354-.277-.48-.634-.02-.045-.033-.058-.061-.072z"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.126"
                    d="M329.398 151.992c-4.696-.762-6.087-3.19-1.019-7.042.824-.832.367-2.104 1.173-2.392 6.765-.386 6.015 1.622 6.76 2.932.546.62 1.472.514 2.285.332.927-.382 1.166-1.735 1.864-2.054 1.062-.416 1.035.522.845 1.321-.11.468-.492 1.08-.376 1.437.179.32.922.715 1.363.916.453.341-.122 1.029-.818 1.292-.912.144-1.93-.572-2.255-.586-.494-.078-1.659.567-1.703.955-.013.188.555.56.846.794.353.655-.258 1.079-1.47 1.324-.79.151-1.297-.844-1.577-.913-.342-.001-1.103.807-1.79 1.155-.658.332-1.567.309-1.638.595-.01.114.064.28.167.4.57.67.594 1.726-1.213 1.336-.908-.33-.889-.695-1.205-1.591-.05-.113-.083-.146-.153-.182z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.126"
                    d="M286.91 159.46c-4.696-.762-6.087-3.19-1.019-7.043.824-.832.367-2.104 1.173-2.392 6.765-.385 6.015 1.622 6.76 2.932.546.62 1.472.514 2.284.332.927-.382 1.167-1.735 1.865-2.054 1.062-.415 1.034.523.844 1.321-.109.469-.49 1.08-.375 1.437.178.32.922.715 1.363.916.453.341-.122 1.03-.819 1.292-.91.144-1.93-.572-2.255-.586-.494-.077-1.658.567-1.702.955-.013.189.555.56.845.794.354.655-.257 1.079-1.468 1.325-.792.15-1.298-.845-1.579-.914-.342-.001-1.102.807-1.79 1.155-.657.333-1.566.31-1.637.595-.01.114.064.28.166.401.57.67.595 1.725-1.212 1.335-.908-.33-.89-.695-1.205-1.59-.05-.114-.084-.147-.153-.183z"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.126"
                    d="M262.785 131.954c4.696-.762 6.087-3.19 1.018-7.042-.824-.832-.367-2.104-1.173-2.392-6.765-.386-6.015 1.622-6.76 2.932-.546.62-1.472.513-2.284.332-.927-.382-1.166-1.735-1.864-2.055-1.062-.415-1.035.523-.845 1.322.11.468.491 1.08.376 1.436-.179.32-.922.716-1.363.917-.454.34.122 1.029.818 1.292.911.144 1.93-.573 2.255-.586.494-.078 1.659.567 1.703.955.013.188-.555.56-.846.794-.354.655.258 1.078 1.469 1.324.791.151 1.297-.844 1.578-.914.342 0 1.103.808 1.79 1.156.658.332 1.567.309 1.638.595.01.114-.064.28-.167.4-.57.67-.594 1.726 1.213 1.336.908-.33.889-.696 1.205-1.591.049-.113.083-.147.153-.182z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.146"
                    d="M310.39 91.945c5.05-2.27 5.883-5.418-.973-8.223-1.177-.688-1.046-2.255-2.038-2.336-7.721 1.601-6.274 3.633-6.718 5.33-.427.861-1.5 1.02-2.468 1.06-1.156-.15-1.833-1.6-2.714-1.749-1.318-.147-1.005.9-.551 1.74.263.493.876 1.066.854 1.502-.105.414-.821 1.082-1.257 1.44-.407.52.447 1.12 1.309 1.206 1.068-.112 1.998-1.224 2.359-1.337.532-.236 2.035.138 2.201.562.071.207-.455.796-.712 1.146-.2.843.615 1.136 2.05 1.047.935-.068 1.205-1.339 1.5-1.502.383-.104 1.482.576 2.36.76.838.177 1.853-.123 2.019.177.045.125.012.334-.067.5-.439.925-.149 2.119 1.765 1.137.922-.644.79-1.05.876-2.152.021-.141.05-.189.117-.25z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.126"
                    d="M15.164 153.023c-4.696-.762-6.087-3.19-1.019-7.042.824-.832.367-2.104 1.173-2.392 6.765-.386 6.015 1.622 6.76 2.932.546.62 1.472.514 2.285.332.926-.382 1.166-1.735 1.864-2.054 1.062-.415 1.034.522.844 1.321-.109.468-.49 1.08-.375 1.437.179.32.922.715 1.363.916.453.341-.122 1.03-.818 1.292-.912.144-1.931-.572-2.255-.586-.495-.078-1.659.567-1.703.955-.013.188.555.56.845.794.354.655-.257 1.079-1.468 1.324-.792.151-1.298-.844-1.579-.913-.342-.001-1.102.807-1.79 1.155-.657.332-1.566.31-1.637.595-.01.114.064.28.167.4.57.67.594 1.726-1.213 1.336-.908-.33-.89-.695-1.205-1.591-.05-.113-.083-.146-.153-.182z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.126"
                    d="M82.244 275.825c4.696-.762 6.087-3.19 1.019-7.042-.824-.832-.367-2.104-1.173-2.392-6.765-.386-6.015 1.622-6.76 2.932-.546.62-1.472.514-2.285.332-.926-.382-1.166-1.735-1.864-2.054-1.062-.416-1.034.522-.844 1.321.109.468.49 1.08.375 1.437-.179.32-.922.715-1.363.916-.453.341.122 1.03.818 1.292.912.144 1.931-.572 2.255-.586.495-.078 1.66.567 1.703.955.013.188-.555.56-.845.794-.354.655.257 1.079 1.468 1.324.792.151 1.298-.844 1.579-.913.342-.001 1.102.807 1.79 1.155.657.332 1.566.309 1.637.595.01.114-.064.28-.166.4-.57.67-.595 1.726 1.212 1.336.908-.33.89-.695 1.205-1.591.05-.113.084-.146.153-.182z"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.164"
                    d="M394.524 47.832c6.119-.993 7.932-4.157 1.327-9.177-1.073-1.084-.478-2.742-1.528-3.117-8.816-.502-7.839 2.114-8.81 3.82-.71.81-1.918.67-2.976.434-1.208-.498-1.52-2.261-2.43-2.678-1.383-.54-1.348.682-1.1 1.722.142.61.64 1.407.49 1.872-.234.418-1.202.933-1.777 1.194-.59.445.159 1.342 1.067 1.684 1.187.188 2.515-.746 2.938-.764.644-.1 2.161.74 2.22 1.245.016.245-.724.73-1.103 1.035-.46.853.336 1.405 1.914 1.726 1.031.196 1.69-1.1 2.057-1.191.445-.002 1.436 1.052 2.333 1.505.856.434 2.04.403 2.133.776.013.148-.083.365-.217.522-.743.873-.775 2.248 1.58 1.74 1.184-.43 1.159-.906 1.57-2.073.065-.148.109-.191.2-.238z"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.219"
                    d="M276.147 12.304c-8.204-1.331-10.635-5.574-1.78-12.304 1.44-1.453.641-3.676 2.05-4.179 11.818-.674 10.508 2.834 11.81 5.122.953 1.084 2.571.898 3.99.58 1.62-.666 2.038-3.03 3.258-3.588 1.855-.726 1.807.913 1.475 2.308-.19.818-.858 1.886-.656 2.51.312.56 1.61 1.25 2.382 1.6.791.597-.214 1.799-1.43 2.258-1.593.252-3.373-1-3.94-1.024-.863-.135-2.898.99-2.975 1.67-.022.328.97.977 1.478 1.386.617 1.145-.45 1.885-2.567 2.314-1.382.264-2.266-1.475-2.757-1.596-.597-.003-1.926 1.41-3.128 2.018-1.148.58-2.736.54-2.86 1.04-.017.199.111.49.291.7.995 1.17 1.039 3.014-2.119 2.333-1.586-.576-1.553-1.215-2.105-2.78-.086-.198-.145-.256-.267-.318z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.164"
                    d="M198.9 65.804c6.119-.993 7.931-4.158 1.327-9.177-1.074-1.084-.478-2.742-1.529-3.117-8.815-.503-7.838 2.114-8.809 3.82-.711.809-1.918.67-2.976.433-1.208-.497-1.52-2.26-2.43-2.677-1.383-.54-1.348.681-1.1 1.722.142.61.64 1.407.489 1.872-.233.417-1.201.933-1.776 1.194-.59.445.159 1.341 1.066 1.684 1.188.188 2.516-.746 2.939-.764.644-.101 2.161.739 2.219 1.245.016.245-.724.73-1.102 1.034-.461.854.335 1.406 1.914 1.726 1.03.197 1.69-1.1 2.056-1.19.446-.002 1.437 1.051 2.333 1.505.857.433 2.041.403 2.134.775.013.15-.084.366-.217.523-.743.873-.775 2.248 1.58 1.74 1.183-.43 1.158-.907 1.57-2.074.064-.147.109-.19.2-.237z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.146"
                    d="M125.75 2.749c4.35-3.401 4.408-6.65-2.901-7.734-1.304-.385-1.55-1.935-2.53-1.777-7.098 3.39-5.213 5.013-5.238 6.762-.209.936-1.21 1.346-2.138 1.615-1.156.13-2.156-1.113-3.045-1.047-1.312.171-.76 1.11-.12 1.816.372.416 1.102.824 1.184 1.252-.003.425-.537 1.243-.874 1.694-.27.6.7.979 1.555.857 1.008-.363 1.644-1.662 1.966-1.857.46-.356 2.004-.351 2.267.02.118.183-.252.879-.417 1.28.007.864.865.953 2.235.526.889-.29.848-1.585 1.094-1.812.347-.193 1.573.204 2.467.174.855-.03 1.766-.561 1.998-.31.074.11.092.32.055.5-.205 1 .36 2.088 1.98.68.74-.842.516-1.204.337-2.292-.014-.142.003-.195.053-.27z"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.192"
                    d="M424.998 252.102c7.24.654 10.22-2.413 4.18-10.038-.905-1.544.255-3.254-.83-3.986-9.867-3.137-9.519.12-11.118 1.775-1.044.712-2.374.202-3.508-.375-1.227-.917-1.067-3.01-1.98-3.748-1.414-1.017-1.73.381-1.751 1.636-.017.734.317 1.784.01 2.268-.386.407-1.636.71-2.365.84-.8.333-.21 1.57.721 2.223 1.295.559 3.075-.115 3.56-.012.762.072 2.24 1.468 2.159 2.06-.053.283-1.034.618-1.553.854-.772.836-.028 1.695 1.671 2.518 1.114.524 2.241-.758 2.683-.754.507.128 1.326 1.613 2.212 2.39.847.741 2.2 1.051 2.198 1.501-.029.173-.201.391-.399.53-1.098.776-1.534 2.329 1.289 2.437 1.469-.143 1.58-.692 2.387-1.898.116-.149.179-.185.296-.211z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.154"
                    d="M189.132 228.059c-4.53-3.688-4.521-7.124 3.23-8.117 1.387-.38 1.68-2.013 2.714-1.825 7.433 3.734 5.405 5.41 5.396 7.26.2.995 1.25 1.449 2.226 1.753 1.22.162 2.304-1.131 3.242-1.043 1.384.209.78 1.19.09 1.923-.404.432-1.185.848-1.28 1.298-.007.45.542 1.326.888 1.81.273.64-.76 1.02-1.662.873-1.058-.405-1.704-1.791-2.04-2.005-.478-.385-2.112-.413-2.397-.027-.129.192.248.936.414 1.363-.025.913-.935.99-2.374.509-.935-.324-.864-1.693-1.12-1.94-.363-.21-1.667.184-2.612.133-.903-.05-1.856-.63-2.106-.37-.081.115-.104.337-.069.528.196 1.062-.425 2.2-2.108.678-.764-.907-.52-1.285-.307-2.431.017-.15 0-.207-.052-.288z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.154"
                    d="M23.47 218.85c-4.53-3.688-4.522-7.124 3.23-8.117 1.387-.38 1.68-2.013 2.714-1.826 7.433 3.735 5.405 5.412 5.395 7.261.2.995 1.251 1.449 2.227 1.753 1.22.162 2.304-1.131 3.242-1.043 1.384.209.78 1.19.089 1.923-.403.431-1.184.847-1.28 1.298-.006.45.542 1.326.889 1.81.273.64-.76 1.02-1.663.873-1.057-.405-1.703-1.791-2.04-2.005-.478-.386-2.111-.413-2.396-.027-.129.192.247.936.413 1.362-.025.914-.935.99-2.374.51-.934-.325-.863-1.694-1.12-1.94-.362-.21-1.667.184-2.611.133-.904-.05-1.856-.63-2.107-.37-.08.115-.104.337-.068.528.196 1.062-.425 2.2-2.109.678-.764-.907-.52-1.285-.307-2.431.017-.15.001-.207-.051-.288z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.156"
                    d="M479.584 151.181c4.41-3.927 4.248-7.397-3.626-8.053-1.418-.322-1.787-1.958-2.823-1.722-7.34 4.104-5.217 5.706-5.124 7.574-.158 1.014-1.2 1.52-2.17 1.87-1.226.217-2.378-1.04-3.322-.909-1.388.273-.735 1.237-.004 1.947.426.418 1.233.803 1.35 1.254.027.454-.488 1.363-.816 1.868-.247.66.813.996 1.718.807 1.05-.456 1.64-1.885 1.97-2.116.466-.41 2.115-.512 2.42-.135.139.189-.208.957-.357 1.395.067.922.99.958 2.421.408.93-.369.796-1.748 1.044-2.008.357-.23 1.692.11 2.644.016.91-.09 1.846-.72 2.11-.468.088.113.121.336.093.531-.15 1.081.527 2.203 2.16.59.731-.95.467-1.32.201-2.469-.024-.151-.01-.208.04-.293z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.156"
                    d="M464.688 183.001c4.41-3.927 4.247-7.397-3.627-8.053-1.417-.322-1.786-1.958-2.822-1.723-7.34 4.105-5.217 5.707-5.124 7.575-.159 1.014-1.2 1.519-2.171 1.87-1.225.217-2.377-1.04-3.321-.909-1.389.273-.735 1.237-.004 1.947.426.418 1.233.803 1.35 1.254.026.454-.488 1.363-.817 1.868-.247.659.814.996 1.719.807 1.05-.456 1.64-1.886 1.97-2.116.466-.41 2.115-.512 2.42-.135.138.189-.208.956-.357 1.395.066.922.989.958 2.42.408.93-.37.797-1.749 1.045-2.008.357-.23 1.692.11 2.644.016.91-.09 1.846-.72 2.11-.468.087.113.12.336.093.531-.15 1.081.527 2.203 2.16.59.731-.95.467-1.32.201-2.47-.024-.15-.01-.208.04-.292z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.156"
                    d="M517.864 128.43c-5.891.405-8.258-2.138-3.219-8.224.762-1.238-.15-2.646.743-3.222 8.068-2.375 7.728.264 8.998 1.636.835.596 1.924.206 2.855-.243 1.012-.723.92-2.426 1.674-3.009 1.166-.8 1.397.34 1.393 1.36 0 .596-.289 1.442-.048 1.841.306.337 1.316.605 1.906.723.644.285.143 1.279-.625 1.793-1.06.431-2.495-.147-2.89-.072-.62.045-1.846 1.153-1.79 1.635.038.23.83.52 1.247.72.612.693-.007 1.377-1.402 2.016-.914.406-1.806-.654-2.165-.66-.414.095-1.105 1.288-1.838 1.902-.701.588-1.806.816-1.811 1.181.02.141.156.321.315.438.877.648 1.205 1.917-1.09 1.956-1.19-.142-1.27-.59-1.905-1.583-.091-.123-.142-.154-.236-.177z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.184"
                    d="M526.855 82.228c-6.424-2.748-7.547-6.701 1.04-10.399 1.469-.894 1.267-2.869 2.518-2.994 9.78 1.844 8 4.44 8.599 6.57.558 1.078 1.916 1.254 3.138 1.283 1.456-.217 2.276-2.06 3.385-2.269 1.66-.216 1.29 1.111.736 2.182-.321.63-1.082 1.365-1.043 1.915.14.52 1.06 1.346 1.618 1.789.525.646-.538 1.423-1.624 1.552-1.35-.117-2.549-1.5-3.007-1.634-.677-.285-2.564.222-2.765.76-.084.263.593.994.925 1.43.272 1.06-.75 1.447-2.562 1.369-1.182-.065-1.551-1.663-1.927-1.861-.487-.124-1.857.76-2.96 1.013-1.055.241-2.342-.114-2.545.269-.054.159-.008.422.096.63.576 1.156.237 2.67-2.2 1.474-1.178-.79-1.022-1.306-1.155-2.694-.03-.179-.067-.238-.154-.314z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.184"
                    d="M491.467 39.393c-4.94-4.941-4.511-9.03 4.835-9.265 1.697-.283 2.245-2.19 3.452-1.841 8.39 5.351 5.772 7.099 5.535 9.299.117 1.208 1.312 1.877 2.436 2.358 1.432.34 2.88-1.065 3.986-.846 1.62.418.783 1.511-.13 2.3-.532.464-1.512.864-1.68 1.389-.063.535.483 1.644.836 2.262.247.796-1.03 1.121-2.085.836-1.21-.61-1.808-2.34-2.183-2.635-.522-.517-2.463-.75-2.85-.325-.176.213.181 1.144.327 1.672-.142 1.085-1.234 1.064-2.888.316-1.072-.5-.82-2.12-1.095-2.444-.406-.295-2.007.015-3.125-.162-1.069-.168-2.131-.976-2.461-.697-.11.127-.165.389-.146.62.103 1.288-.774 2.567-2.592.55-.798-1.173-.461-1.592-.068-2.931.038-.177.026-.246-.026-.348z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.162"
                    d="M611.344 5.947c4.846-3.742 4.93-7.344-3.168-8.593-1.442-.436-1.706-2.155-2.794-1.986-7.891 3.713-5.81 5.525-5.85 7.464-.237 1.037-1.35 1.484-2.381 1.777-1.283.136-2.384-1.248-3.37-1.18-1.456.18-.85 1.225-.145 2.012.41.463 1.218.92 1.306 1.395-.005.472-.604 1.376-.98 1.873-.303.665.77 1.09 1.719.96 1.12-.396 1.833-1.832 2.192-2.046.511-.391 2.224-.376 2.513.036.13.205-.285.974-.47 1.417.001.958.953 1.063 2.474.598.988-.315.95-1.752 1.225-2.003.386-.211 1.743.237 2.734.21.948-.028 1.962-.612 2.218-.332.081.123.1.357.057.556-.234 1.107.386 2.317 2.191.767.825-.93.58-1.332.388-2.54-.014-.158.004-.216.06-.3z"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.184"
                    d="M646.618 99.644c-4.94-4.941-4.51-9.03 4.836-9.264 1.696-.284 2.245-2.192 3.452-1.842 8.39 5.351 5.772 7.1 5.535 9.3.117 1.207 1.312 1.876 2.436 2.357 1.432.34 2.88-1.065 3.985-.846 1.622.418.784 1.511-.129 2.3-.532.464-1.512.864-1.68 1.389-.063.535.483 1.644.836 2.262.246.796-1.03 1.121-2.085.836-1.21-.61-1.808-2.34-2.183-2.635-.522-.517-2.463-.75-2.85-.325-.176.214.181 1.144.326 1.672-.14 1.085-1.233 1.064-2.887.317-1.072-.5-.82-2.12-1.095-2.445-.406-.295-2.007.015-3.125-.161-1.069-.169-2.131-.977-2.462-.698-.11.128-.164.389-.145.621.103 1.287-.774 2.566-2.592.549-.798-1.173-.461-1.592-.069-2.93.039-.178.027-.246-.025-.35z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.148"
                    d="M678.166 199.969c5.168-2.208 6.074-5.388-.833-8.365-1.18-.72-1.018-2.309-2.024-2.41-7.868 1.48-6.437 3.57-6.92 5.283-.45.866-1.541 1.007-2.525 1.03-1.17-.175-1.83-1.659-2.721-1.826-1.336-.175-1.038.893-.593 1.755.258.506.87 1.098.838 1.54-.113.419-.853 1.083-1.302 1.438-.423.52.432 1.146 1.305 1.25 1.086-.094 2.052-1.205 2.42-1.313.544-.23 2.063.179 2.224.612.068.212-.478.8-.744 1.15-.22.852.602 1.164 2.06 1.102.95-.052 1.248-1.337 1.55-1.496.392-.1 1.494.612 2.382.816.848.194 1.883-.09 2.046.217.044.128.006.34-.077.507-.464.93-.192 2.147 1.77 1.187.947-.637.821-1.05.93-2.168.024-.143.053-.19.123-.252z"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.184"
                    d="M637.17 252.105c-4.94-4.941-4.511-9.03 4.835-9.265 1.696-.283 2.244-2.19 3.452-1.841 8.39 5.351 5.772 7.099 5.535 9.299.117 1.208 1.312 1.877 2.436 2.358 1.432.34 2.88-1.065 3.985-.846 1.622.418.784 1.511-.129 2.3-.532.464-1.512.864-1.681 1.389-.062.535.483 1.644.837 2.262.246.796-1.03 1.121-2.085.836-1.21-.61-1.809-2.34-2.183-2.635-.522-.517-2.463-.75-2.85-.325-.176.213.18 1.144.326 1.672-.141 1.085-1.233 1.064-2.887.316-1.072-.5-.82-2.12-1.095-2.444-.406-.295-2.007.015-3.125-.162-1.07-.168-2.132-.976-2.462-.697-.11.127-.164.389-.145.62.103 1.288-.774 2.567-2.592.55-.799-1.173-.461-1.592-.069-2.931.039-.177.027-.246-.026-.348z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.107"
                    d="M348.288 114.397c-3.723-1.592-4.374-3.884.603-6.027.851-.518.735-1.662 1.46-1.735 5.667 1.069 4.636 2.574 4.983 3.808.323.625 1.11.726 1.819.743.844-.126 1.319-1.194 1.961-1.315.962-.125.748.644.427 1.265-.186.364-.627.791-.605 1.11.082.301.615.78.938 1.036.305.375-.312.825-.94.9-.783-.068-1.478-.869-1.744-.947-.392-.165-1.486.128-1.602.44-.05.153.344.577.536.83.158.613-.434.838-1.485.792-.685-.037-.899-.963-1.117-1.078-.282-.072-1.076.44-1.716.587-.61.14-1.357-.066-1.474.156-.032.092-.005.244.056.365.333.67.137 1.547-1.276.855-.683-.459-.592-.757-.67-1.562-.017-.104-.038-.138-.088-.182z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.107"
                    d="M597.875 70.508c3.723-1.592 4.374-3.883-.602-6.026-.852-.519-.735-1.663-1.46-1.736-5.668 1.069-4.636 2.574-4.983 3.809-.324.624-1.11.726-1.82.742-.843-.125-1.318-1.194-1.96-1.314-.963-.126-.748.644-.427 1.264.186.365.627.791.605 1.11-.082.301-.615.78-.939 1.037-.304.375.312.825.942.9.782-.069 1.477-.87 1.742-.947.393-.166 1.487.128 1.603.44.049.152-.344.576-.536.829-.158.613.434.838 1.485.793.685-.038.899-.964 1.116-1.079.283-.071 1.077.441 1.716.587.611.14 1.357-.065 1.475.156.031.093.005.245-.056.366-.333.67-.137 1.547 1.276.854.682-.459.591-.757.669-1.562.017-.103.039-.138.089-.181z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.089"
                    d="M482.523 87.184c2.385-2.358 2.19-4.321-2.296-4.46-.813-.14-1.071-1.057-1.652-.893-4.041 2.546-2.79 3.392-2.681 4.449-.06.58-.635.897-1.176 1.125-.688.16-1.379-.52-1.91-.417-.78.196-.38.723.055 1.104.255.224.724.419.804.671.028.257-.237.788-.408 1.084-.12.381.491.541.998.407.583-.29.875-1.118 1.055-1.259.252-.247 1.184-.353 1.369-.148.084.103-.09.548-.161.802.065.52.589.513 1.385.16.516-.238.4-1.016.532-1.171.196-.14.963.012 1.5-.07.514-.077 1.026-.462 1.184-.327.052.061.078.187.068.298-.053.618.364 1.234 1.242.27.387-.56.226-.762.041-1.406-.018-.085-.012-.118.014-.167z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.089"
                    d="M640.91 150.353c2.384-2.358 2.19-4.321-2.296-4.46-.814-.14-1.072-1.057-1.652-.893-4.042 2.546-2.79 3.392-2.682 4.449-.06.58-.635.897-1.175 1.125-.689.16-1.38-.52-1.91-.417-.78.196-.381.723.055 1.104.254.224.723.419.803.671.029.257-.236.788-.408 1.084-.12.381.491.54.999.407.582-.29.874-1.119 1.055-1.26.252-.246 1.184-.352 1.368-.147.084.103-.09.548-.16.801.064.521.588.514 1.384.16.516-.237.4-1.016.533-1.17.195-.141.963.012 1.5-.07.513-.078 1.025-.463 1.183-.328.053.062.078.187.068.299-.053.617.365 1.233 1.243.27.386-.56.226-.763.04-1.406-.017-.085-.011-.118.014-.167z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.089"
                    d="M76.829 50.524c-3.084-1.318-3.623-3.216.499-4.99.705-.43.608-1.378 1.209-1.438 4.693.885 3.84 2.132 4.127 3.154.268.517.92.602 1.506.615.699-.104 1.092-.989 1.624-1.089.797-.103.62.534.354 1.048-.154.302-.52.655-.501.919.068.25.51.646.777.858.252.31-.258.684-.78.745-.648-.056-1.223-.72-1.443-.784-.325-.137-1.23.107-1.327.365-.04.126.285.477.444.686.13.509-.36.695-1.23.657-.567-.031-.744-.798-.925-.893-.233-.06-.891.365-1.42.486-.507.116-1.125-.054-1.222.13-.026.076-.004.202.046.302.276.555.114 1.281-1.056.707-.566-.38-.49-.626-.555-1.293-.014-.086-.032-.114-.073-.15z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.146"
                    d="M71.274 77.403c-4.367-3.41-4.425-6.668 2.907-7.76 1.308-.387 1.554-1.942 2.538-1.784 7.122 3.398 5.231 5.027 5.257 6.782.21.94 1.215 1.35 2.146 1.62 1.16.128 2.163-1.118 3.055-1.053 1.316.171.763 1.113.121 1.822-.374.417-1.106.827-1.188 1.256.002.427.54 1.247.878 1.7.27.602-.702.982-1.56.86-1.011-.364-1.65-1.666-1.974-1.862-.46-.357-2.01-.351-2.273.02-.119.185.253.883.418 1.285-.006.867-.868.956-2.242.528-.892-.289-.85-1.589-1.099-1.817-.348-.193-1.577.206-2.474.176-.858-.03-1.772-.562-2.005-.31-.074.11-.092.321-.055.502.207 1.003-.36 2.094-1.986.684-.742-.846-.517-1.209-.338-2.3.013-.143-.003-.196-.054-.272z"
                    opacity="1"
                ></path>
                <path className='fishFacingLeft2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.113"
                    d="M-1.097 30.283c-4.117-1.19-5.098-3.512-.15-6.409.828-.655.557-1.842 1.309-2.012 6.091.387 5.203 2.101 5.728 3.353.421.614 1.261.619 2.007.544.87-.242 1.23-1.425 1.89-1.636.994-.256.868.58.612 1.274-.148.407-.556.912-.491 1.244.125.305.747.74 1.12.967.368.354-.22.907-.872 1.067-.83.03-1.665-.721-1.953-.768-.434-.123-1.545.327-1.626.67-.032.167.435.56.67.801.246.624-.347.937-1.457 1.026-.724.05-1.069-.896-1.312-.988-.306-.038-1.074.603-1.726.84-.624.226-1.434.107-1.529.354-.021.102.027.258.106.377.437.66.345 1.607-1.229 1.063-.776-.393-.72-.718-.906-1.553-.031-.107-.058-.14-.117-.18z"
                ></path>
                <path className='fishFacingLeft1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.146"
                    d="M162.152 301.321c-4.366-3.41-4.425-6.668 2.908-7.76 1.307-.387 1.553-1.941 2.538-1.783 7.121 3.397 5.23 5.027 5.257 6.781.21.94 1.214 1.35 2.145 1.62 1.16.129 2.163-1.118 3.055-1.053 1.316.172.763 1.114.121 1.823-.373.417-1.106.826-1.188 1.256.003.427.54 1.247.878 1.699.271.602-.702.982-1.56.86-1.011-.363-1.65-1.666-1.973-1.862-.461-.356-2.011-.35-2.274.02-.118.186.253.883.418 1.285-.006.867-.867.957-2.241.529-.893-.29-.851-1.59-1.1-1.818-.347-.193-1.577.206-2.474.176-.857-.029-1.772-.562-2.005-.31-.074.111-.092.322-.054.503.206 1.003-.36 2.094-1.987.683-.742-.846-.517-1.208-.338-2.3.014-.143-.003-.196-.054-.271z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight2'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.21"
                    d="M139.845 163.161c6.448-4.643 6.714-9.31-3.733-11.282-1.852-.628-2.119-2.87-3.538-2.698-10.395 4.472-7.777 6.912-7.912 9.425-.353 1.334-1.816 1.866-3.165 2.201-1.67.12-3.037-1.722-4.32-1.678-1.895.172-1.155 1.553-.275 2.605.513.618 1.54 1.246 1.633 1.866-.027.612-.843 1.757-1.352 2.386-.423.848.95 1.447 2.187 1.32 1.47-.465 2.457-2.296 2.932-2.559.68-.485 2.9-.39 3.257.157.16.271-.412 1.25-.671 1.817-.04 1.243 1.19 1.42 3.183.883 1.295-.365 1.308-2.23 1.676-2.544.51-.257 2.25.384 3.537.39 1.23.006 2.57-.707 2.89-.333.1.164.114.467.05.724-.352 1.426.4 3.021 2.808 1.09 1.111-1.17.81-1.703.613-3.277-.01-.206.016-.28.093-.386z"
                    opacity="1"
                ></path>
                <path className='fishFacingRight1'
                    fill="#00a1b3"
                    fillOpacity="1"
                    stroke="none"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeWidth="0.21"
                    d="M357.225 199.316c7.9-.844 10.441-4.767 2.34-11.652-1.298-1.462-.42-3.542-1.739-4.095-11.246-1.26-10.178 2.157-11.54 4.273-.966.986-2.501.723-3.84.347-1.51-.722-1.786-3-2.921-3.596-1.733-.789-1.773.777-1.529 2.127.14.79.72 1.844.495 2.43-.327.517-1.602 1.109-2.356 1.403-.787.528.11 1.728 1.247 2.23 1.507.323 3.272-.779 3.814-.772.831-.085 2.714 1.096 2.753 1.748.004.315-.977.883-1.483 1.246-.65 1.06.332 1.823 2.33 2.343 1.305.324 2.24-1.29 2.714-1.38.57.028 1.765 1.446 2.88 2.089 1.067.615 2.584.658 2.677 1.141.006.192-.132.462-.315.654-1.011 1.065-1.148 2.823 1.9 2.337 1.545-.467 1.547-1.08 2.156-2.544.091-.184.151-.236.271-.29z"
                    opacity="1"
                ></path>
            </g>
            <g
                fillOpacity="1"
                strokeDasharray="none"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                display="inline"
            >
                <path
                    fill="#fb7152"
                    stroke="#f46c4b"
                    strokeLinecap="butt"
                    strokeWidth="0.773"
                    d="M77.385 332.106l-9.39-1.138-1.17-.316-.285-.538.063-.6.253-.317.443-.221.664-.127 2.403-.063 6.893.032.38-.095.252-.317v-.442l-.253-.633-1.77-3.478-.064-.537.127-.316.316-.222h.506l.443.127 1.28 1.027 4.316 3.431.443.221.632.158.854.032 3.573.032 3.921.284 2.15.285.759.284.221.57-.158.347-.284.443-.822.538-2.182.664-3.099.6-.854.127-.442.316.031.411.19.411.348.348 5.597 4.617.252.569-.22.474-.317.19-.917.095-.759-.348-8.853-4.617-.443-.158h-.538l-.632.222-.885.569-4.016 2.15-3.447 1.58-.695.159-.649-.174-.19-.332.032-.49.49-.727.98-.79 2.182-1.503 2.483-1.66.474-.38.016-.3-.127-.316z"
                ></path>
                <path
                    fill="#fb6195"
                    stroke="#f0619a"
                    strokeLinecap="round"
                    strokeWidth="0.654"
                    d="M142.984 362.546c-.434-.16-.66-.158-1.122-.041-5.265 1.328-9.082 3.579-16.367 3.621-.433.002-.581.068-.785-.14-.075-.19-.071-.355.294-.6 2.688-1.802 9.602-3.796 14.557-5.7.264-.102.353-.166.35-.323-.053-.177-.31-.32-.555-.434-2.16-1.008-4.621-1.564-6.265-2.894-.45-.364-.618-.698-.583-.998.081-.279.44-.362.972-.377 7.086-.202 9.2.99 14.038 1.395 6.193-1.615 11.35-4.265 18.444-4.98.324-.032.435-.06.555.085.182.261.128.352-.104.613-1.595 1.796-5.578 3.591-8.632 5.386-.445.262-.55.245-.558.739.046.616.287.526.818.757 2.396 1.042 5.2 1.964 7.887 2.75 2.786.815 5.173 1.991 6.685 2.966.268.173.497.291.492.567-.125.34-.446.324-.76.354-4.703.441-11.98-1.058-18.534-1.72-.7-.07-1.276.103-1.89.156-.43.19-1.143.594-1.43.814-3.456 2.655-6.39 5.558-9.983 7.395-.506.258-.862.545-1.32.334-.376-.238-.175-.797-.024-1.36.682-2.537 2.938-4.989 3.792-7.258.188-.5.262-.578.028-1.107z"
                ></path>
                <path
                    fill="#fb6195"
                    stroke="#f0619a"
                    strokeLinecap="round"
                    strokeWidth="0.546"
                    d="M674.765 365.039c.363-.134.552-.132.937-.035 4.4 1.11 7.59 2.99 13.678 3.027.362.002.485.057.655-.117.064-.159.06-.297-.245-.502-2.246-1.506-8.024-3.172-12.164-4.764-.221-.085-.295-.138-.293-.27.045-.147.26-.267.464-.362 1.804-.842 3.861-1.307 5.235-2.418.376-.304.516-.584.488-.834-.069-.233-.369-.303-.813-.315-5.922-.169-7.687.827-11.73 1.166-5.176-1.35-9.486-3.565-15.414-4.162-.27-.027-.363-.05-.463.071-.152.218-.107.294.087.513 1.333 1.5 4.66 3 7.213 4.5.372.22.46.205.466.618-.038.514-.24.44-.683.632-2.003.871-4.345 1.641-6.59 2.298-2.33.681-4.324 1.664-5.587 2.48-.224.144-.415.242-.411.472.104.285.372.272.635.296 3.93.369 10.01-.884 15.488-1.437.585-.059 1.065.086 1.579.13.36.16.955.497 1.194.68 2.89 2.219 5.34 4.645 8.343 6.18.423.216.72.456 1.102.279.316-.198.148-.666.021-1.136-.57-2.12-2.455-4.17-3.169-6.065-.157-.419-.218-.483-.023-.925z"
                    display="inline"
                ></path>
                <path
                    fill="#fbd252"
                    stroke="#f4d24b"
                    strokeLinecap="butt"
                    strokeWidth="0.529"
                    d="M434.552 341.586l-6.425.779-.8.216-.195.368.043.41.173.217.303.152.454.086 1.644.043 4.717-.021.26.065.172.216v.303l-.173.433-1.211 2.38-.044.367.087.217.216.151h.347l.302-.086.877-.704 2.953-2.347.303-.151.432-.109.584-.021 2.445-.022 2.683-.194 1.471-.195.52-.195.15-.39-.107-.237-.195-.303-.563-.368-1.492-.454-2.12-.411-.585-.087-.303-.216.022-.281.13-.282.238-.238 3.829-3.159.173-.389-.151-.324-.217-.13-.627-.065-.52.238-6.057 3.158-.303.109h-.368l-.432-.152-.606-.39-2.748-1.47-2.358-1.082-.476-.108-.443.119-.13.227.021.335.336.498.67.54 1.493 1.028 1.699 1.136.324.26.01.205-.086.217z"
                ></path>
                <path
                    fill="#fb8795"
                    stroke="#f0879a"
                    strokeLinecap="round"
                    strokeWidth="0.529"
                    d="M341.008 368.376c.35-.13.534-.128.907-.034 4.263 1.075 7.351 2.897 13.249 2.932.35.002.47.055.634-.113.062-.154.059-.288-.237-.487-2.176-1.458-7.772-3.072-11.783-4.614-.214-.082-.285-.133-.283-.261.043-.143.25-.259.449-.351 1.748-.816 3.74-1.266 5.07-2.343.365-.294.5-.565.473-.807-.066-.226-.357-.293-.787-.306-5.736-.163-7.446.802-11.362 1.13-5.014-1.307-9.188-3.453-14.93-4.03-.262-.027-.352-.05-.449.068-.147.211-.103.285.085.496 1.29 1.453 4.514 2.906 6.986 4.36.36.212.445.198.452.597-.037.499-.233.427-.662.613-1.94.844-4.209 1.59-6.384 2.226-2.255.66-4.187 1.612-5.41 2.401-.218.14-.403.235-.4.459.102.275.361.262.616.286 3.807.357 9.696-.856 15.002-1.392.567-.057 1.032.084 1.53.127.348.153.924.48 1.157.658 2.798 2.149 5.172 4.5 8.08 5.986.41.209.699.441 1.068.27.306-.192.143-.645.02-1.1-.552-2.054-2.378-4.039-3.069-5.875-.152-.406-.212-.468-.022-.896z"
                ></path>
            </g>
            <g display="inline">
                <g
                    fill="none"
                    stroke="#f78500"
                    strokeDasharray="none"
                    strokeLinecap="round"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="2.646"
                    display="inline"
                    transform="matrix(-1.31767 0 0 1.31767 712.946 -95.08)"
                >
                    <path d="M192.337 295.743c2.545 11.375 10.062 22.144 22.067 32.366-6.178-13.188-16.667-23.295-12.375-43.962"></path>
                    <path d="M208.953 288.3c.997 13.434 3.36 26.594 5.451 39.809 1.429-18.063 1.837-37.73 7.875-48.549"></path>
                    <path d="M225.568 297.907c-.278 12.035-6.293 20.791-11.164 30.202 6.368-5.608 13.093-10.68 18.606-17.568"></path>
                    <path d="M214.404 328.109c10.954-.845 11.062-.54 16.789-7.962"></path>
                    <path d="M214.404 328.109c-8.477-1.844-13.308-5.329-21.72-14.366"></path>
                </g>
            </g>
            <g
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                display="inline"
            >
                <g fillOpacity="1" transform="matrix(.93335 0 0 .93335 66.132 31.1)">
                    <path
                        fill="#4489fe"
                        stroke="#4484fe"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264l-34.633-58.335c-.26-.505-.307-.422-.858-1.725-.135-.957.316-2.301.737-3.09 2.876-5.387 7.1-7.304 12.36-8.393.508-.105 1.084-.05 1.895-.006.77.352 1.183.857 1.362 1.346 8.24 22.553 12.483 46.107 19.137 70.203z"
                    ></path>
                    <path
                        fill="#82b1ff"
                        stroke="#82acff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264c1.307.35-1.793 3.09-4.91 1.985-9.66-3.42-16.629-16.89-32.846-38.221-.411-.54-.829-1.093-1.252-1.657 0 0-.314-.485-.442-1.025 0 0-.061-.711.084-1.061 1.382-3.342 3.76-5.836 10.945-7.026.52-.086.857.02 1.176.07 0 0 .788.457.89.615 17.27 26.62 17.463 30.8 26.355 46.32z"
                    ></path>
                    <path
                        fill="#2876ff"
                        stroke="#2871ff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M449.088 329.824c1.885-13.617 3.951-25.994 5.011-42.293 5.516-1.568 10.396-1.863 12.483 3.427-3.35 19.792-6.812 26.826-12.317 34.473-1.091 1.515-2.638 3.002-5.177 4.393z"
                    ></path>
                    <path
                        fill="#458aff"
                        stroke="#4585ff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264c-4.427-26.558-11.613-53.116-13.438-79.673-.054-.775-.06-1.42.113-2.216.19-.478.557-.782.97-1.084 5.336-3.912 11.149-4.019 17.31-1.341.67.29 1.235.658 1.653 1.1.204.444.47.926.519 1.447 4.428 47.483-4.361 55.67-7.127 81.767z"
                    ></path>
                    <path
                        fill="#82b1ff"
                        stroke="#82acff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264c-6.094-17.416-9.017-16.766-19.69-60.258-.085-.35-.085-.992-.085-.992.11-.43.267-.762.642-1.08.298-.252.833-.631 1.268-.84 7.075-3.386 12.031-4.643 18.934-1.785 0 0 .499.237.9.422 0 0 .402.148.624.457.374.822.58 1.425.682 2.262.103.861.166 1.96.225 2.777 2.085 28.58-1.757 40.706-3.5 59.037z"
                    ></path>
                    <ellipse
                        cx="247.307"
                        cy="433.751"
                        fill="#4388ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.237"
                        rx="5.094"
                        ry="3.03"
                        transform="matrix(.91248 -.40911 .44169 .89717 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="18.14"
                        cy="490.888"
                        fill="#2b79ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.331"
                        rx="6.322"
                        ry="2.826"
                        transform="matrix(.83638 -.54816 .8291 .55911 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="333.124"
                        cy="332.82"
                        fill="#1a67ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.079"
                        rx="4.089"
                        ry="2.869"
                        transform="matrix(.99884 -.04808 .38529 .9228 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="136.025"
                        cy="419.44"
                        fill="#2879ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.446"
                        rx="6.7"
                        ry="3.146"
                        transform="matrix(.97404 -.2264 .74646 .66543 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="70.229"
                        cy="466.126"
                        fill="#4786f8"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.743"
                        rx="8.32"
                        ry="3.683"
                        transform="matrix(.98109 -.19355 .79744 .6034 0 0)"
                    ></ellipse>
                </g>
                <g
                    fill="none"
                    stroke="#ff1480"
                    strokeLinecap="round"
                    strokeLinejoin="miter"
                    strokeWidth="3.175"
                    display="inline"
                    transform="matrix(.65047 0 0 .65047 585.582 130.415)"
                >
                    <path d="M10.28 137.07c-.45 16.465 2.568 33.477 12.842 52.697 4.684 13.5 5.286 34.62 7.23 49.126 9.02 44.28 15.135 53.696 22.518 78.326-3.16-19.993-8.925-27.61-8.812-63.15 3.678-37.883.17-37.99.574-53.765 1.105-25.786-5.378-43.294-8.812-64.13"></path>
                    <path d="M45.346 108.692c4.63 7.023 3.886 14.717 5.192 22.154-.78 10.832-3.768 21.295-2.076 32.539.395 16.22 2.259 30.971 5.884 43.961-.302 8.497 2.207 10.669-3.115 30.462-3.746 75.834 1.092 52.94 1.639 79.411 6.85-33.612 1.507-38.238 13.592-85.988 1.48-10.393 4.133-20.297.346-32.885l-6.923-28.038c-.54-9.231-1.9-18.462 2.423-27.693"></path>
                    <path d="M78.23 105.923c-.925 11.856 2.508 16.863-4.153 37.73-2.506 8.278-5.135 16.608-4.5 23.54-2.455 14.468-3.077 24.05-2.77 31.153-9.48 55.584-15.193 84.112-13.937 118.873 4.013-12.855 8.89-21.8 12.903-38.564l4.323-13.501c4.039-14.662 7.212-24.646 11.25-46.73 4.246-10.294 5.112-23.966 6.23-37.386-1.775-22.107.48-35.002 1.732-50.192"></path>
                </g>
            </g>
            <g
                strokeDasharray="none"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                display="inline"
            >
                <g
                    fill="none"
                    stroke="#f78500"
                    strokeLinecap="round"
                    strokeWidth="2.646"
                    display="inline"
                    transform="translate(-136.755 -208.389) scale(1.63284)"
                >
                    <path d="M192.337 295.743c2.545 11.375 10.062 22.144 22.067 32.366-6.178-13.188-16.667-23.295-12.375-43.962"></path>
                    <path d="M208.953 288.3c.997 13.434 3.36 26.594 5.451 39.809 1.429-18.063 1.837-37.73 7.875-48.549"></path>
                    <path d="M225.568 297.907c-.278 12.035-6.293 20.791-11.164 30.202 6.368-5.608 13.093-10.68 18.606-17.568"></path>
                    <path d="M214.404 328.109c10.954-.845 11.062-.54 16.789-7.962"></path>
                    <path d="M214.404 328.109c-8.477-1.844-13.308-5.329-21.72-14.366"></path>
                </g>
                <g
                    fill="none"
                    fillOpacity="1"
                    stroke="#82ffff"
                    strokeLinecap="round"
                    strokeWidth="5.292"
                    display="inline"
                    transform="matrix(-1.14271 -.00444 -.00444 1.14271 814.29 -94.072)"
                >
                    <path d="M122.452 385.183c1.317-1.132 2.754-1.962 3.635-4.183 1.245-4.266 1.859-8.469 5.278-12.952.763-1.064 1.694-1.948 2.032-3.47.242-1.095.179-2.52-.474-4.578-.315-1.499-.7-3.033-1.471-4.76-.832-3.337-.302-4.125.52-5.625 2.304-2.81 3.996-5.2 5.624-8.913 1.694-5.386 1.177-10.496 1.125-15.664-.563-2.781-1.897-4.871-3.127-7.653-.428-.968-.929-2.607-1.113-3.597-.61-3.84-1.669-6.873-1.212-12.634.26-2.284.02-2.602 1.676-6.02.289-.596.742-1.27 1.353-2.115 1.738-2.19 3.224-4.244 4.327-9.606.366-3.994.209-8.526-1.024-15.948-1.222-5.867-1.72-9.536-7.71-29.888-.43-1.46-.919-2.942-1.131-4.702-.594-7.11.714-13.195 3.115-18.692"></path>
                    <path d="M124.569 386.13c3.787.331 5.169-.202 11.155-.498 3.48-.38 6.766-1.341 9.92-2.7 1.26-.6 3.361-2.456 6.577-5.97 2.08-2.047 3.343-4.502 4.81-6.854 1.459-2.568 2.093-6.74 2.626-10.266.43-3.91.235-6.624-1.724-13.602-.364-3.039-1.397-4.407.065-12.01.47-2.955 1.316-5.415.244-9.178 0 0-1.012-3.436-3.304-7.221-1.301-.552-2.185-3.192-3.182-5.263-.998-4.661-.771-5.954-.49-7.098"></path>
                    <path d="M108.866 317.001c-1.403 2.895-3.667 6.936-3.808 10.634.797 6.38 2.392 8.507 3.85 11.368.578 1.424.98 3.794 1.212 7.064-.806 5.944-2.787 9.13-1.168 14.02.69 2.286.488 3.498 3.28 7.835.4.62 1.604 3.838 1.566 4.54-.217 1.868-.11 2.535.13 4.175.271.852.77 2.047 1.148 2.526 2.16 2.732 4.645 4.673 7.376 6.02.34-1.326.713-2.634.606-4.183-.616-5.846-2.498-9.989-4.126-18.867-.293-1.795-.716-3.173-.46-6.72 1.348-7.084 2.092-8.862 1.73-13.024-.295-3.36.913-2.953-2.782-15.314-.972-1.776-.687-4.11-.68-6.32 1.673-5.17 3.175-8.304 3.722-14.15.67-4.078-.834-7.773-2.045-11.52-.73-3.979-1.219-7.92-.465-11.672 4.934-11.498 2.595-10.775 2.596-16.269"></path>
                    <path d="M122.452 385.183c2.03-.862 4.21-1.951 7.153-4.183 1.237-1.293 2.797-2.505 4.956-3.568 1.237-.394 2.859-1.269 5.018-2.815 1.64-2.464 3.7-4.478 5.113-8.3.902-2.243 1.135-3.67 1.818-10.125-.165-5.243-1.184-8.904-.952-14.798.356-2.52.829-5.156 1.644-8.134.754-2.85 1.594-5.93 4.413-10.385 1.882-2.674 2.774-4.886 6.837-9.692 1.144-1.867 2.485-5.414 2.25-9.433-.619-3.368-1.113-5.974-1.298-11.51.369-1.898.8-3.858 2.077-6.663 2.075-3.517 2.012-4.897 2.596-6.923.276-1.752.057-3.943-1.73-7.529-1.368-1.795-2.817-4.238-4.414-7.875-.89-2.413-1.76-4.9-2.423-8.221-.23-3.294-.706-5.232-.52-10.817-.074-3.555.479-6.86 1.558-9.952"></path>
                </g>
                <path
                    fill="#fbd252"
                    fillOpacity="1"
                    stroke="#f4d24b"
                    strokeLinecap="butt"
                    strokeWidth="1.356"
                    d="M43.179 366.872l-16.071 4.122-1.963.816-.372 1 .247 1.03.512.492.82.284 1.184.069 4.193-.438 11.98-1.627.681.078.512.492.101.77-.296 1.158-2.286 6.452.013.949.292.521.6.313.88-.116.741-.32 1.993-2.08 6.723-6.95.72-.486 1.063-.419 1.477-.25 6.207-.869 6.753-1.39 3.674-.984 1.255-.668.255-1.04-.354-.57-.596-.704-1.552-.747-3.946-.658-5.526-.338-1.513-.025-.842-.449-.039-.722.236-.758.526-.684 8.68-9.305.31-1.047-.493-.774-.593-.258-1.617.044-1.24.778-14.343 10.047-.734.376-.935.122-1.15-.24-1.67-.788-7.473-2.823-6.354-1.964-1.246-.116-1.087.45-.254.62.166.846 1.019 1.153 1.884 1.151 4.137 2.114 4.695 2.32.911.553.096.518-.148.58z"
                    display="inline"
                ></path>
            </g>
            <g
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                display="inline"
            >
                <g
                    fill="none"
                    stroke="#fffa07"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="1.852"
                    transform="rotate(8.882 169.652 -1405.92) scale(1.88026)"
                >
                    <path d="M318.076 97.173c-1.466 3.54-4.438 2.038-3.182 14.686 1.15 7.995-.614 9.253-1.102 13.462-1.348 4.258.433 7.379 1.469 10.77v10.647l-.367 6.854.367 3.182-3.916 7.832c-.938 2.122-2.43 3.69-4.406 4.773l-.612-4.16c1.713-3.443 3.498-6.798 3.304-12.484l-1.224-8.444-2.692-10.648-2.815-6.976-1.101-4.038-2.08-6.487c-1.422-3.645-2.293-7.351-4.651-10.892"></path>
                    <path d="M314.894 111.859c4.75-2.91 8.176-7.035 10.158-12.483"></path>
                    <path d="M310.243 93.868c2.266 12.217 2.987 21.962 3.55 31.453 3.917-.966 6.28-2.681 6.853-5.262"></path>
                    <path d="M315.26 136.09c1.918-4.072 4.467-8.012 8.568-11.626-1.312-4.145-3.61-8.05 3.304-14.196"></path>
                    <path d="M323.828 124.464c2.27-2.216 4.525-4.449 6.119-7.343"></path>
                    <path d="M315.26 156.774c2.365-.899 3.114-2.74 4.162-4.406.258-3.738-.155-7.236 1.59-11.504l7.466-10.648"></path>
                    <path d="M299.718 116.142c1.775-1.131 2.375-3.128 1.958-5.874"></path>
                    <path d="M302.9 126.667c-.091-3.27.693-6.376 2.448-9.3.825-3.122 1.441-6.383-1.469-11.994-2.235-1.104-4.56-1.171-6.241-8.69"></path>
                    <path d="M303.88 105.373c-.711-3.304-.841-6.441.856-9.057"></path>
                    <path d="M313.792 125.321c-6.046-1.645-4.75-13.54-5.018-23.253"></path>
                    <path d="M305.715 133.643c1.04-2.506 1.793-5.122 1.346-8.2"></path>
                    <path d="M314.894 153.592l.367-6.609c-2.347-2.615-4.595-5.254-5.018-8.322"></path>
                    <path d="M328.723 126.545c-.432 1.294-.384 2.492-.245 3.671l3.427.245"></path>
                    <path d="M326.765 107.575c-.294.967-.025 1.84.367 2.693 2.063.121 2.122-.304 3.06-.49"></path>
                    <path d="M329.457 113.94c-.275.997-.04 2.068.49 3.181 2.06-.284 2.434-.438 3.06-.612"></path>
                    <path d="M324.684 95.827c-.595.824.129 2.307.368 3.549 1.778.03 2.533-.579 3.426-1.102"></path>
                    <path d="M317.341 93.501c-.209 1.148.27 2.411.735 3.672l2.937-1.102"></path>
                    <path d="M309.264 91.176c-.204.897.552 1.795.98 2.692 1.674-.284 1.99-1.248 2.57-2.08"></path>
                    <path d="M295.435 95.092c.13.598 1.04 1.109 2.203 1.591 1.32-1.264 1.213-2.529 1.713-3.794"></path>
                    <path d="M309.631 152.735c-3.494-1.093-4.222-2.482-4.528-3.916"></path>
                    <path d="M308.407 144.29c-2.694 1.127-5.695-1.289-9.423-12.115-2.247-2.326.06-4.651-7.71-6.976-3.653-1.006-7.321-1.858-10.525-7.466-.433-2.3 1.572-1.898-2.693-8.444l-3.427-.735"></path>
                    <path d="M278.056 109.289v-2.693"></path>
                    <path d="M280.749 117.733c-2.567-4.501-4.876-4.105-7.221-4.406"></path>
                    <path d="M291.274 125.199c-1.144-2.725-1.062-3.942-3.55-8.322-2.376-3.264-4.377-6.528-5.262-9.791"></path>
                    <path d="M287.724 116.877c2.707-1.613.8-5.169-.734-8.567"></path>
                    <path d="M298.984 132.175l1.224-4.773-1.469-1.836c-3.796-6.34-6.61-12.623-8.444-18.847-2.036-.889-3.794-1.224-6.242-2.937"></path>
                    <path d="M290.295 106.719c-.426-2.489-.747-4.977-1.836-7.466-1.622-.118-2.461-.522-2.815-1.101"></path>
                    <path d="M288.459 99.253c1.134-1.036 1.222-2.42 1.346-3.794"></path>
                    <path d="M281.116 104.026c.382.118 1.521-.017 2.937-.244.225-1.102-.03-2.203-.612-3.305"></path>
                    <path d="M306.327 165.218c-1.317-5.258-.718-9.96-5.263-16.154-1.553-5.326-3.009-10.626-6.241-16.4l-2.937-1.101"></path>
                    <path d="M294.823 132.664l-.123-2.448"></path>
                    <path d="M301.064 148.941c-3.456-2.799-4.713-2.786-12.728-14.686l-4.038-2.325c-3.019-2.93-6.428-4.22-8.934-9.301l-2.08-1.224"></path>
                    <path d="M275.364 122.629c1.201-.861 1.267-2.246.979-3.794"></path>
                    <path d="M284.298 131.93c-3.373 1.524-6.924-.624-10.525-3.794"></path>
                    <path d="M288.336 134.255c.715-1.897.021-3.995-.856-6.12"></path>
                    <path d="M321.013 140.864c6.108.662 9-3.993 12.605-7.466-.128-.693.048-1.387.612-2.08"></path>
                    <path d="M333.618 133.398c1.055.33 2.055.687 2.203 1.469"></path>
                    <path d="M319.422 152.368l5.752-2.448c.1-.694.432-1.295 0-2.203"></path>
                    <path d="M325.174 149.92c.919.599 1.766.698 2.57.49"></path>
                    <path d="M311.345 164.606c4.772-.387 5.92-1.379 7.343-2.325"></path>
                    <path d="M306.939 169.38l-5.018-5.018-3.182-3.794c.262-2.023.049-4.104-.612-6.242-1.661-.102-2.152-.673-2.692-1.224"></path>
                    <path d="M298.127 154.326c.557-.956.49-2.12.367-3.304"></path>
                    <path d="M298.739 160.568c-2.284.286-4.782-.909-8.322-9.302l-2.815-7.22c-3.524-2.074-6.079-3.098-11.382-7.098l-5.63-7.955-1.713-7.833-2.203-1.469"></path>
                    <path d="M268.877 121.16l1.347-2.203"></path>
                    <path d="M270.59 128.993c-2.529 1.08-5.058.05-7.587-4.896l-2.693-1.101"></path>
                    <path d="M263.003 124.097l-.122-3.304"></path>
                    <path d="M282.217 145.882c2.157-.16 3.879-.863 5.385-1.836-.135-2.343-.493-4.76-2.08-7.588"></path>
                    <path d="M290.417 151.266c-2.026.231-2.876-.21-3.794-.611"></path>
                    <path d="M301.921 164.362c-2.284.18-3.647 1.08-9.424-1.47-3.346-6.293-9.782-12.042-15.787-17.867l-9.056-7.466-6.61-5.507-4.16.123"></path>
                    <path d="M261.045 132.052l-1.224-3.916"></path>
                    <path d="M268.265 138.049c-2.955.964-6.164 1.397-10.892-1.346"></path>
                    <path d="M306.939 169.38c-2.586 1.46-4.293 2.072-11.3-1.311-2.841-2.119-6.62-3.679-13.3-5.788l-8.077-3.55c-.97-1.794-2.296-3.59-4.406-5.384"></path>
                    <path d="M274.262 158.732c-3.168.165-6.01-1.191-8.689-3.304"></path>
                    <path d="M283.578 162.9l-11.763-14.081c-4.553-3.844-9.325-7.4-17.501-6.487-.935-.972-1.666-1.536-2.448-2.202"></path>
                    <path d="M254.314 142.332l-1.836 1.959"></path>
                    <path d="M271.815 148.819c-3.91.364-7.828.723-14.442-.49-1.291.418-2.475.495-3.12.429"></path>
                    <path d="M257.373 148.33l-1.59-3.183"></path>
                </g>
                <g
                    fill="none"
                    stroke="#a24bb4"
                    strokeLinecap="round"
                    strokeLinejoin="miter"
                    strokeWidth="3.175"
                    transform="translate(.78 31.884)"
                >
                    <path d="M10.28 137.07c-.45 16.465 2.568 33.477 12.842 52.697 4.684 13.5 5.286 34.62 7.23 49.126 9.02 44.28 15.135 53.696 22.518 78.326-3.16-19.993-8.925-27.61-8.812-63.15 3.678-37.883.17-37.99.574-53.765 1.105-25.786-5.378-43.294-8.812-64.13"></path>
                    <path d="M45.346 108.692c4.63 7.023 3.886 14.717 5.192 22.154-.78 10.832-3.768 21.295-2.076 32.539.395 16.22 2.259 30.971 5.884 43.961-.302 8.497 2.207 10.669-3.115 30.462-3.746 75.834 1.092 52.94 1.639 79.411 6.85-33.612 1.507-38.238 13.592-85.988 1.48-10.393 4.133-20.297.346-32.885l-6.923-28.038c-.54-9.231-1.9-18.462 2.423-27.693"></path>
                    <path d="M78.23 105.923c-.925 11.856 2.508 16.863-4.153 37.73-2.506 8.278-5.135 16.608-4.5 23.54-2.455 14.468-3.077 24.05-2.77 31.153-9.48 55.584-15.193 84.112-13.937 118.873 4.013-12.855 8.89-21.8 12.903-38.564l4.323-13.501c4.039-14.662 7.212-24.646 11.25-46.73 4.246-10.294 5.112-23.966 6.23-37.386-1.775-22.107.48-35.002 1.732-50.192"></path>
                </g>
                <g
                    fill="none"
                    fillOpacity="1"
                    stroke="#f7859f"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="1.323"
                    display="inline"
                    transform="scale(1.62417) rotate(.898 10292.216 6604.84)"
                >
                    <path d="M234.52 385.096c-.02-3.999-.255-5.499.259-13.327l.346-11.856.865-9.086 1.558-9v-5.106l.606-5.538.865-1.904 2.596-6.317 2.012-5.671 4.738-6.618 6.404-6.23"></path>
                    <path d="M234.779 370.471c-8.162-6.324-8.444-9.419-9.866-12.98"></path>
                    <path d="M235.125 359.913c-9.201-6.392-7.859-10.842-8.776-15.708"></path>
                    <path d="M235.99 350.827c-2.85-4.458-6.516-6.475-7.56-16.352"></path>
                    <path d="M237.548 341.827c-5.209-6.854-9.068-13.343-7.528-18.366"></path>
                    <path d="M238.154 331.183c-2.096-3.74-6.147-3.08-3.544-17.39"></path>
                    <path d="M240.545 325.419c-1.724-2.862-4.669-3.088-3.06-13.157"></path>
                    <path d="M244.095 302.227c-.99 5.125-1.322 10.169-.468 15.064 4.177.374 7.874-1.771 11.605-3.744"></path>
                    <path d="M248.365 310.673c-2.071-2.77 1.407-8.813 3.562-14.076"></path>
                    <path d="M259.087 292.742c-2.296 4.065-4.915 8.192-4.318 11.7 2.133-2.588 4.527-5.203 7.806-7.906"></path>
                    <path d="M254.77 304.442c3.324-.041 6.083-2.439 8.722-5.336"></path>
                    <path d="M248.365 310.673c3.934-.494 7.336-1.46 10.232-2.878"></path>
                    <path d="M241.708 322.42c4.314.935 7.925-1.875 11.565-4.528"></path>
                    <path d="M238.154 331.183c1.501-2.517 9.729-4.553 13.406-8.763"></path>
                    <path d="M237.548 336.721c5.805-3.098 9.048-5.464 12.666-7.937"></path>
                    <path d="M237.548 341.827c2.993-2.678 7.323-4.686 11.932-6.556"></path>
                    <path d="M235.99 350.827c4.203-1.408 7.481-4.004 10.552-6.867"></path>
                    <path d="M234.779 370.471l4.846-5.971 3.029-5.192 1.298-2.337 2.942-3.461 1.904-3.116 2.51-3.029 1.47-2.163 2.857-3.808 3.72-7.442 1.558-3.721 2.597-8.568c.792-2.987 1.707-5.996 1.557-8.826"></path>
                    <path d="M261.692 311.452c-.377 3.65.236 7.051 1.818 10.211 3.94-1.522 6.052-4.54 6.403-9"></path>
                    <path d="M260.913 330.23c5.5-1.604 7.032-4.291 9.087-6.836"></path>
                    <path d="M259.356 333.952c-2.025-2.533-3.01-7.012-1.298-16.529"></path>
                    <path d="M255.635 341.394c4.716-1.74 7.776-4.585 10.557-7.615"></path>
                    <path d="M253.73 327.375c.538 6.411-.35 13.09-2.422 19.99 2.693.227 7.785-4.204 14.105-5.538"></path>
                    <path d="M248.798 350.394c-1.242-4.72-.356-8.801-.086-13.067"></path>
                    <path d="M246.894 353.51c6.178-.257 10.12-1.681 12.548-3.895"></path>
                    <path d="M251.744 361.583c-1.9 1.666-4.283 3.176-12.119 2.917-.953-5.298-1.036-11.085.615-17.848"></path>
                    <path d="M243.952 356.971c-1.374-4.949-.443-9.898.755-14.847"></path>
                    <path d="M242.381 359.625c6.766.71 9.235-2.094 12.606-4.161"></path>
                    <path d="M234.433 382.76c4.246-3.797 8.596-7.385 13.413-10.039"></path>
                    <path d="M241.219 370.028l-1.53 8.75 9.24.612"></path>
                    <path d="M242.87 376.27c.493-2.818 1.59-4.126 2.51-5.875"></path>
                    <path d="M244.278 375.413l5.875 1.101"></path>
                </g>
                <g
                    fill="none"
                    stroke="#00f500"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="1.852"
                    display="inline"
                    transform="matrix(-1.17974 .18437 .18437 1.17974 362.352 106.703)"
                >
                    <path d="M318.076 97.173c-1.466 3.54-4.438 2.038-3.182 14.686 1.15 7.995-.614 9.253-1.102 13.462-1.348 4.258.433 7.379 1.469 10.77v10.647l-.367 6.854.367 3.182-3.916 7.832c-.938 2.122-2.43 3.69-4.406 4.773l-.612-4.16c1.713-3.443 3.498-6.798 3.304-12.484l-1.224-8.444-2.692-10.648-2.815-6.976-1.101-4.038-2.08-6.487c-1.422-3.645-2.293-7.351-4.651-10.892"></path>
                    <path d="M314.894 111.859c4.75-2.91 8.176-7.035 10.158-12.483"></path>
                    <path d="M310.243 93.868c2.266 12.217 2.987 21.962 3.55 31.453 3.917-.966 6.28-2.681 6.853-5.262"></path>
                    <path d="M315.26 136.09c1.918-4.072 4.467-8.012 8.568-11.626-1.312-4.145-3.61-8.05 3.304-14.196"></path>
                    <path d="M323.828 124.464c2.27-2.216 4.525-4.449 6.119-7.343"></path>
                    <path d="M315.26 156.774c2.365-.899 3.114-2.74 4.162-4.406.258-3.738-.155-7.236 1.59-11.504l7.466-10.648"></path>
                    <path d="M299.718 116.142c1.775-1.131 2.375-3.128 1.958-5.874"></path>
                    <path d="M302.9 126.667c-.091-3.27.693-6.376 2.448-9.3.825-3.122 1.441-6.383-1.469-11.994-2.235-1.104-4.56-1.171-6.241-8.69"></path>
                    <path d="M303.88 105.373c-.711-3.304-.841-6.441.856-9.057"></path>
                    <path d="M313.792 125.321c-6.046-1.645-4.75-13.54-5.018-23.253"></path>
                    <path d="M305.715 133.643c1.04-2.506 1.793-5.122 1.346-8.2"></path>
                    <path d="M314.894 153.592l.367-6.609c-2.347-2.615-4.595-5.254-5.018-8.322"></path>
                    <path d="M328.723 126.545c-.432 1.294-.384 2.492-.245 3.671l3.427.245"></path>
                    <path d="M326.765 107.575c-.294.967-.025 1.84.367 2.693 2.063.121 2.122-.304 3.06-.49"></path>
                    <path d="M329.457 113.94c-.275.997-.04 2.068.49 3.181 2.06-.284 2.434-.438 3.06-.612"></path>
                    <path d="M324.684 95.827c-.595.824.129 2.307.368 3.549 1.778.03 2.533-.579 3.426-1.102"></path>
                    <path d="M317.341 93.501c-.209 1.148.27 2.411.735 3.672l2.937-1.102"></path>
                    <path d="M309.264 91.176c-.204.897.552 1.795.98 2.692 1.674-.284 1.99-1.248 2.57-2.08"></path>
                    <path d="M295.435 95.092c.13.598 1.04 1.109 2.203 1.591 1.32-1.264 1.213-2.529 1.713-3.794"></path>
                    <path d="M309.631 152.735c-3.494-1.093-4.222-2.482-4.528-3.916"></path>
                    <path d="M308.407 144.29c-2.694 1.127-5.695-1.289-9.423-12.115-2.247-2.326.06-4.651-7.71-6.976-3.653-1.006-7.321-1.858-10.525-7.466-.433-2.3 1.572-1.898-2.693-8.444l-3.427-.735"></path>
                    <path d="M278.056 109.289v-2.693"></path>
                    <path d="M280.749 117.733c-2.567-4.501-4.876-4.105-7.221-4.406"></path>
                    <path d="M291.274 125.199c-1.144-2.725-1.062-3.942-3.55-8.322-2.376-3.264-4.377-6.528-5.262-9.791"></path>
                    <path d="M287.724 116.877c2.707-1.613.8-5.169-.734-8.567"></path>
                    <path d="M298.984 132.175l1.224-4.773-1.469-1.836c-3.796-6.34-6.61-12.623-8.444-18.847-2.036-.889-3.794-1.224-6.242-2.937"></path>
                    <path d="M290.295 106.719c-.426-2.489-.747-4.977-1.836-7.466-1.622-.118-2.461-.522-2.815-1.101"></path>
                    <path d="M288.459 99.253c1.134-1.036 1.222-2.42 1.346-3.794"></path>
                    <path d="M281.116 104.026c.382.118 1.521-.017 2.937-.244.225-1.102-.03-2.203-.612-3.305"></path>
                    <path d="M306.327 165.218c-1.317-5.258-.718-9.96-5.263-16.154-1.553-5.326-3.009-10.626-6.241-16.4l-2.937-1.101"></path>
                    <path d="M294.823 132.664l-.123-2.448"></path>
                    <path d="M301.064 148.941c-3.456-2.799-4.713-2.786-12.728-14.686l-4.038-2.325c-3.019-2.93-6.428-4.22-8.934-9.301l-2.08-1.224"></path>
                    <path d="M275.364 122.629c1.201-.861 1.267-2.246.979-3.794"></path>
                    <path d="M284.298 131.93c-3.373 1.524-6.924-.624-10.525-3.794"></path>
                    <path d="M288.336 134.255c.715-1.897.021-3.995-.856-6.12"></path>
                    <path d="M321.013 140.864c6.108.662 9-3.993 12.605-7.466-.128-.693.048-1.387.612-2.08"></path>
                    <path d="M333.618 133.398c1.055.33 2.055.687 2.203 1.469"></path>
                    <path d="M319.422 152.368l5.752-2.448c.1-.694.432-1.295 0-2.203"></path>
                    <path d="M325.174 149.92c.919.599 1.766.698 2.57.49"></path>
                    <path d="M311.345 164.606c4.772-.387 5.92-1.379 7.343-2.325"></path>
                    <path d="M306.939 169.38l-5.018-5.018-3.182-3.794c.262-2.023.049-4.104-.612-6.242-1.661-.102-2.152-.673-2.692-1.224"></path>
                    <path d="M298.127 154.326c.557-.956.49-2.12.367-3.304"></path>
                    <path d="M298.739 160.568c-2.284.286-4.782-.909-8.322-9.302l-2.815-7.22c-3.524-2.074-6.079-3.098-11.382-7.098l-5.63-7.955-1.713-7.833-2.203-1.469"></path>
                    <path d="M268.877 121.16l1.347-2.203"></path>
                    <path d="M270.59 128.993c-2.529 1.08-5.058.05-7.587-4.896l-2.693-1.101"></path>
                    <path d="M263.003 124.097l-.122-3.304"></path>
                    <path d="M282.217 145.882c2.157-.16 3.879-.863 5.385-1.836-.135-2.343-.493-4.76-2.08-7.588"></path>
                    <path d="M290.417 151.266c-2.026.231-2.876-.21-3.794-.611"></path>
                    <path d="M301.921 164.362c-2.284.18-3.647 1.08-9.424-1.47-3.346-6.293-9.782-12.042-15.787-17.867l-9.056-7.466-6.61-5.507-4.16.123"></path>
                    <path d="M261.045 132.052l-1.224-3.916"></path>
                    <path d="M268.265 138.049c-2.955.964-6.164 1.397-10.892-1.346"></path>
                    <path d="M306.939 169.38c-2.586 1.46-4.293 2.072-11.3-1.311-2.841-2.119-6.62-3.679-13.3-5.788l-8.077-3.55c-.97-1.794-2.296-3.59-4.406-5.384"></path>
                    <path d="M274.262 158.732c-3.168.165-6.01-1.191-8.689-3.304"></path>
                    <path d="M283.578 162.9l-11.763-14.081c-4.553-3.844-9.325-7.4-17.501-6.487-.935-.972-1.666-1.536-2.448-2.202"></path>
                    <path d="M254.314 142.332l-1.836 1.959"></path>
                    <path d="M271.815 148.819c-3.91.364-7.828.723-14.442-.49-1.291.418-2.475.495-3.12.429"></path>
                    <path d="M257.373 148.33l-1.59-3.183"></path>
                </g>
                <g
                    fill="none"
                    stroke="#00f500"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="1.852"
                    display="inline"
                    transform="matrix(1.11862 .20818 -.17482 1.33208 301.283 84.957)"
                >
                    <path d="M318.076 97.173c-1.466 3.54-4.438 2.038-3.182 14.686 1.15 7.995-.614 9.253-1.102 13.462-1.348 4.258.433 7.379 1.469 10.77v10.647l-.367 6.854.367 3.182-3.916 7.832c-.938 2.122-2.43 3.69-4.406 4.773l-.612-4.16c1.713-3.443 3.498-6.798 3.304-12.484l-1.224-8.444-2.692-10.648-2.815-6.976-1.101-4.038-2.08-6.487c-1.422-3.645-2.293-7.351-4.651-10.892"></path>
                    <path d="M314.894 111.859c4.75-2.91 8.176-7.035 10.158-12.483"></path>
                    <path d="M310.243 93.868c2.266 12.217 2.987 21.962 3.55 31.453 3.917-.966 6.28-2.681 6.853-5.262"></path>
                    <path d="M315.26 136.09c1.918-4.072 4.467-8.012 8.568-11.626-1.312-4.145-3.61-8.05 3.304-14.196"></path>
                    <path d="M323.828 124.464c2.27-2.216 4.525-4.449 6.119-7.343"></path>
                    <path d="M315.26 156.774c2.365-.899 3.114-2.74 4.162-4.406.258-3.738-.155-7.236 1.59-11.504l7.466-10.648"></path>
                    <path d="M299.718 116.142c1.775-1.131 2.375-3.128 1.958-5.874"></path>
                    <path d="M302.9 126.667c-.091-3.27.693-6.376 2.448-9.3.825-3.122 1.441-6.383-1.469-11.994-2.235-1.104-4.56-1.171-6.241-8.69"></path>
                    <path d="M303.88 105.373c-.711-3.304-.841-6.441.856-9.057"></path>
                    <path d="M313.792 125.321c-6.046-1.645-4.75-13.54-5.018-23.253"></path>
                    <path d="M305.715 133.643c1.04-2.506 1.793-5.122 1.346-8.2"></path>
                    <path d="M314.894 153.592l.367-6.609c-2.347-2.615-4.595-5.254-5.018-8.322"></path>
                    <path d="M328.723 126.545c-.432 1.294-.384 2.492-.245 3.671l3.427.245"></path>
                    <path d="M326.765 107.575c-.294.967-.025 1.84.367 2.693 2.063.121 2.122-.304 3.06-.49"></path>
                    <path d="M329.457 113.94c-.275.997-.04 2.068.49 3.181 2.06-.284 2.434-.438 3.06-.612"></path>
                    <path d="M324.684 95.827c-.595.824.129 2.307.368 3.549 1.778.03 2.533-.579 3.426-1.102"></path>
                    <path d="M317.341 93.501c-.209 1.148.27 2.411.735 3.672l2.937-1.102"></path>
                    <path d="M309.264 91.176c-.204.897.552 1.795.98 2.692 1.674-.284 1.99-1.248 2.57-2.08"></path>
                    <path d="M295.435 95.092c.13.598 1.04 1.109 2.203 1.591 1.32-1.264 1.213-2.529 1.713-3.794"></path>
                    <path d="M309.631 152.735c-3.494-1.093-4.222-2.482-4.528-3.916"></path>
                    <path d="M308.407 144.29c-2.694 1.127-5.695-1.289-9.423-12.115-2.247-2.326.06-4.651-7.71-6.976-3.653-1.006-7.321-1.858-10.525-7.466-.433-2.3 1.572-1.898-2.693-8.444l-3.427-.735"></path>
                    <path d="M278.056 109.289v-2.693"></path>
                    <path d="M280.749 117.733c-2.567-4.501-4.876-4.105-7.221-4.406"></path>
                    <path d="M291.274 125.199c-1.144-2.725-1.062-3.942-3.55-8.322-2.376-3.264-4.377-6.528-5.262-9.791"></path>
                    <path d="M287.724 116.877c2.707-1.613.8-5.169-.734-8.567"></path>
                    <path d="M298.984 132.175l1.224-4.773-1.469-1.836c-3.796-6.34-6.61-12.623-8.444-18.847-2.036-.889-3.794-1.224-6.242-2.937"></path>
                    <path d="M290.295 106.719c-.426-2.489-.747-4.977-1.836-7.466-1.622-.118-2.461-.522-2.815-1.101"></path>
                    <path d="M288.459 99.253c1.134-1.036 1.222-2.42 1.346-3.794"></path>
                    <path d="M281.116 104.026c.382.118 1.521-.017 2.937-.244.225-1.102-.03-2.203-.612-3.305"></path>
                    <path d="M306.327 165.218c-1.317-5.258-.718-9.96-5.263-16.154-1.553-5.326-3.009-10.626-6.241-16.4l-2.937-1.101"></path>
                    <path d="M294.823 132.664l-.123-2.448"></path>
                    <path d="M301.064 148.941c-3.456-2.799-4.713-2.786-12.728-14.686l-4.038-2.325c-3.019-2.93-6.428-4.22-8.934-9.301l-2.08-1.224"></path>
                    <path d="M275.364 122.629c1.201-.861 1.267-2.246.979-3.794"></path>
                    <path d="M284.298 131.93c-3.373 1.524-6.924-.624-10.525-3.794"></path>
                    <path d="M288.336 134.255c.715-1.897.021-3.995-.856-6.12"></path>
                    <path d="M321.013 140.864c6.108.662 9-3.993 12.605-7.466-.128-.693.048-1.387.612-2.08"></path>
                    <path d="M333.618 133.398c1.055.33 2.055.687 2.203 1.469"></path>
                    <path d="M319.422 152.368l5.752-2.448c.1-.694.432-1.295 0-2.203"></path>
                    <path d="M325.174 149.92c.919.599 1.766.698 2.57.49"></path>
                    <path d="M311.345 164.606c4.772-.387 5.92-1.379 7.343-2.325"></path>
                    <path d="M306.939 169.38l-5.018-5.018-3.182-3.794c.262-2.023.049-4.104-.612-6.242-1.661-.102-2.152-.673-2.692-1.224"></path>
                    <path d="M298.127 154.326c.557-.956.49-2.12.367-3.304"></path>
                    <path d="M298.739 160.568c-2.284.286-4.782-.909-8.322-9.302l-2.815-7.22c-3.524-2.074-6.079-3.098-11.382-7.098l-5.63-7.955-1.713-7.833-2.203-1.469"></path>
                    <path d="M268.877 121.16l1.347-2.203"></path>
                    <path d="M270.59 128.993c-2.529 1.08-5.058.05-7.587-4.896l-2.693-1.101"></path>
                    <path d="M263.003 124.097l-.122-3.304"></path>
                    <path d="M282.217 145.882c2.157-.16 3.879-.863 5.385-1.836-.135-2.343-.493-4.76-2.08-7.588"></path>
                    <path d="M290.417 151.266c-2.026.231-2.876-.21-3.794-.611"></path>
                    <path d="M301.921 164.362c-2.284.18-3.647 1.08-9.424-1.47-3.346-6.293-9.782-12.042-15.787-17.867l-9.056-7.466-6.61-5.507-4.16.123"></path>
                    <path d="M261.045 132.052l-1.224-3.916"></path>
                    <path d="M268.265 138.049c-2.955.964-6.164 1.397-10.892-1.346"></path>
                    <path d="M306.939 169.38c-2.586 1.46-4.293 2.072-11.3-1.311-2.841-2.119-6.62-3.679-13.3-5.788l-8.077-3.55c-.97-1.794-2.296-3.59-4.406-5.384"></path>
                    <path d="M274.262 158.732c-3.168.165-6.01-1.191-8.689-3.304"></path>
                    <path d="M283.578 162.9l-11.763-14.081c-4.553-3.844-9.325-7.4-17.501-6.487-.935-.972-1.666-1.536-2.448-2.202"></path>
                    <path d="M254.314 142.332l-1.836 1.959"></path>
                    <path d="M271.815 148.819c-3.91.364-7.828.723-14.442-.49-1.291.418-2.475.495-3.12.429"></path>
                    <path d="M257.373 148.33l-1.59-3.183"></path>
                </g>
                <g
                    fill="none"
                    stroke="#76519e"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="1.852"
                    display="inline"
                    transform="matrix(-.7956 .14806 .12434 .94743 680.59 168.33)"
                >
                    <path d="M318.076 97.173c-1.466 3.54-4.438 2.038-3.182 14.686 1.15 7.995-.614 9.253-1.102 13.462-1.348 4.258.433 7.379 1.469 10.77v10.647l-.367 6.854.367 3.182-3.916 7.832c-.938 2.122-2.43 3.69-4.406 4.773l-.612-4.16c1.713-3.443 3.498-6.798 3.304-12.484l-1.224-8.444-2.692-10.648-2.815-6.976-1.101-4.038-2.08-6.487c-1.422-3.645-2.293-7.351-4.651-10.892"></path>
                    <path d="M314.894 111.859c4.75-2.91 8.176-7.035 10.158-12.483"></path>
                    <path d="M310.243 93.868c2.266 12.217 2.987 21.962 3.55 31.453 3.917-.966 6.28-2.681 6.853-5.262"></path>
                    <path d="M315.26 136.09c1.918-4.072 4.467-8.012 8.568-11.626-1.312-4.145-3.61-8.05 3.304-14.196"></path>
                    <path d="M323.828 124.464c2.27-2.216 4.525-4.449 6.119-7.343"></path>
                    <path d="M315.26 156.774c2.365-.899 3.114-2.74 4.162-4.406.258-3.738-.155-7.236 1.59-11.504l7.466-10.648"></path>
                    <path d="M299.718 116.142c1.775-1.131 2.375-3.128 1.958-5.874"></path>
                    <path d="M302.9 126.667c-.091-3.27.693-6.376 2.448-9.3.825-3.122 1.441-6.383-1.469-11.994-2.235-1.104-4.56-1.171-6.241-8.69"></path>
                    <path d="M303.88 105.373c-.711-3.304-.841-6.441.856-9.057"></path>
                    <path d="M313.792 125.321c-6.046-1.645-4.75-13.54-5.018-23.253"></path>
                    <path d="M305.715 133.643c1.04-2.506 1.793-5.122 1.346-8.2"></path>
                    <path d="M314.894 153.592l.367-6.609c-2.347-2.615-4.595-5.254-5.018-8.322"></path>
                    <path d="M328.723 126.545c-.432 1.294-.384 2.492-.245 3.671l3.427.245"></path>
                    <path d="M326.765 107.575c-.294.967-.025 1.84.367 2.693 2.063.121 2.122-.304 3.06-.49"></path>
                    <path d="M329.457 113.94c-.275.997-.04 2.068.49 3.181 2.06-.284 2.434-.438 3.06-.612"></path>
                    <path d="M324.684 95.827c-.595.824.129 2.307.368 3.549 1.778.03 2.533-.579 3.426-1.102"></path>
                    <path d="M317.341 93.501c-.209 1.148.27 2.411.735 3.672l2.937-1.102"></path>
                    <path d="M309.264 91.176c-.204.897.552 1.795.98 2.692 1.674-.284 1.99-1.248 2.57-2.08"></path>
                    <path d="M295.435 95.092c.13.598 1.04 1.109 2.203 1.591 1.32-1.264 1.213-2.529 1.713-3.794"></path>
                    <path d="M309.631 152.735c-3.494-1.093-4.222-2.482-4.528-3.916"></path>
                    <path d="M308.407 144.29c-2.694 1.127-5.695-1.289-9.423-12.115-2.247-2.326.06-4.651-7.71-6.976-3.653-1.006-7.321-1.858-10.525-7.466-.433-2.3 1.572-1.898-2.693-8.444l-3.427-.735"></path>
                    <path d="M278.056 109.289v-2.693"></path>
                    <path d="M280.749 117.733c-2.567-4.501-4.876-4.105-7.221-4.406"></path>
                    <path d="M291.274 125.199c-1.144-2.725-1.062-3.942-3.55-8.322-2.376-3.264-4.377-6.528-5.262-9.791"></path>
                    <path d="M287.724 116.877c2.707-1.613.8-5.169-.734-8.567"></path>
                    <path d="M298.984 132.175l1.224-4.773-1.469-1.836c-3.796-6.34-6.61-12.623-8.444-18.847-2.036-.889-3.794-1.224-6.242-2.937"></path>
                    <path d="M290.295 106.719c-.426-2.489-.747-4.977-1.836-7.466-1.622-.118-2.461-.522-2.815-1.101"></path>
                    <path d="M288.459 99.253c1.134-1.036 1.222-2.42 1.346-3.794"></path>
                    <path d="M281.116 104.026c.382.118 1.521-.017 2.937-.244.225-1.102-.03-2.203-.612-3.305"></path>
                    <path d="M306.327 165.218c-1.317-5.258-.718-9.96-5.263-16.154-1.553-5.326-3.009-10.626-6.241-16.4l-2.937-1.101"></path>
                    <path d="M294.823 132.664l-.123-2.448"></path>
                    <path d="M301.064 148.941c-3.456-2.799-4.713-2.786-12.728-14.686l-4.038-2.325c-3.019-2.93-6.428-4.22-8.934-9.301l-2.08-1.224"></path>
                    <path d="M275.364 122.629c1.201-.861 1.267-2.246.979-3.794"></path>
                    <path d="M284.298 131.93c-3.373 1.524-6.924-.624-10.525-3.794"></path>
                    <path d="M288.336 134.255c.715-1.897.021-3.995-.856-6.12"></path>
                    <path d="M321.013 140.864c6.108.662 9-3.993 12.605-7.466-.128-.693.048-1.387.612-2.08"></path>
                    <path d="M333.618 133.398c1.055.33 2.055.687 2.203 1.469"></path>
                    <path d="M319.422 152.368l5.752-2.448c.1-.694.432-1.295 0-2.203"></path>
                    <path d="M325.174 149.92c.919.599 1.766.698 2.57.49"></path>
                    <path d="M311.345 164.606c4.772-.387 5.92-1.379 7.343-2.325"></path>
                    <path d="M306.939 169.38l-5.018-5.018-3.182-3.794c.262-2.023.049-4.104-.612-6.242-1.661-.102-2.152-.673-2.692-1.224"></path>
                    <path d="M298.127 154.326c.557-.956.49-2.12.367-3.304"></path>
                    <path d="M298.739 160.568c-2.284.286-4.782-.909-8.322-9.302l-2.815-7.22c-3.524-2.074-6.079-3.098-11.382-7.098l-5.63-7.955-1.713-7.833-2.203-1.469"></path>
                    <path d="M268.877 121.16l1.347-2.203"></path>
                    <path d="M270.59 128.993c-2.529 1.08-5.058.05-7.587-4.896l-2.693-1.101"></path>
                    <path d="M263.003 124.097l-.122-3.304"></path>
                    <path d="M282.217 145.882c2.157-.16 3.879-.863 5.385-1.836-.135-2.343-.493-4.76-2.08-7.588"></path>
                    <path d="M290.417 151.266c-2.026.231-2.876-.21-3.794-.611"></path>
                    <path d="M301.921 164.362c-2.284.18-3.647 1.08-9.424-1.47-3.346-6.293-9.782-12.042-15.787-17.867l-9.056-7.466-6.61-5.507-4.16.123"></path>
                    <path d="M261.045 132.052l-1.224-3.916"></path>
                    <path d="M268.265 138.049c-2.955.964-6.164 1.397-10.892-1.346"></path>
                    <path d="M306.939 169.38c-2.586 1.46-4.293 2.072-11.3-1.311-2.841-2.119-6.62-3.679-13.3-5.788l-8.077-3.55c-.97-1.794-2.296-3.59-4.406-5.384"></path>
                    <path d="M274.262 158.732c-3.168.165-6.01-1.191-8.689-3.304"></path>
                    <path d="M283.578 162.9l-11.763-14.081c-4.553-3.844-9.325-7.4-17.501-6.487-.935-.972-1.666-1.536-2.448-2.202"></path>
                    <path d="M254.314 142.332l-1.836 1.959"></path>
                    <path d="M271.815 148.819c-3.91.364-7.828.723-14.442-.49-1.291.418-2.475.495-3.12.429"></path>
                    <path d="M257.373 148.33l-1.59-3.183"></path>
                </g>
                <g
                    fillOpacity="1"
                    display="inline"
                    transform="scale(-1.26105 1.26105) rotate(14.597 247.78 -2207.483)"
                >
                    <path
                        fill="#4489fe"
                        stroke="#4484fe"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264l-34.633-58.335c-.26-.505-.307-.422-.858-1.725-.135-.957.316-2.301.737-3.09 2.876-5.387 7.1-7.304 12.36-8.393.508-.105 1.084-.05 1.895-.006.77.352 1.183.857 1.362 1.346 8.24 22.553 12.483 46.107 19.137 70.203z"
                    ></path>
                    <path
                        fill="#82b1ff"
                        stroke="#82acff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264c1.307.35-1.793 3.09-4.91 1.985-9.66-3.42-16.629-16.89-32.846-38.221-.411-.54-.829-1.093-1.252-1.657 0 0-.314-.485-.442-1.025 0 0-.061-.711.084-1.061 1.382-3.342 3.76-5.836 10.945-7.026.52-.086.857.02 1.176.07 0 0 .788.457.89.615 17.27 26.62 17.463 30.8 26.355 46.32z"
                    ></path>
                    <path
                        fill="#2876ff"
                        stroke="#2871ff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M449.088 329.824c1.885-13.617 3.951-25.994 5.011-42.293 5.516-1.568 10.396-1.863 12.483 3.427-3.35 19.792-6.812 26.826-12.317 34.473-1.091 1.515-2.638 3.002-5.177 4.393z"
                    ></path>
                    <path
                        fill="#458aff"
                        stroke="#4585ff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264c-4.427-26.558-11.613-53.116-13.438-79.673-.054-.775-.06-1.42.113-2.216.19-.478.557-.782.97-1.084 5.336-3.912 11.149-4.019 17.31-1.341.67.29 1.235.658 1.653 1.1.204.444.47.926.519 1.447 4.428 47.483-4.361 55.67-7.127 81.767z"
                    ></path>
                    <path
                        fill="#82b1ff"
                        stroke="#82acff"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeWidth="0.529"
                        d="M448.714 329.264c-6.094-17.416-9.017-16.766-19.69-60.258-.085-.35-.085-.992-.085-.992.11-.43.267-.762.642-1.08.298-.252.833-.631 1.268-.84 7.075-3.386 12.031-4.643 18.934-1.785 0 0 .499.237.9.422 0 0 .402.148.624.457.374.822.58 1.425.682 2.262.103.861.166 1.96.225 2.777 2.085 28.58-1.757 40.706-3.5 59.037z"
                    ></path>
                    <ellipse
                        cx="247.307"
                        cy="433.751"
                        fill="#4388ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.237"
                        rx="5.094"
                        ry="3.03"
                        transform="matrix(.91248 -.40911 .44169 .89717 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="18.14"
                        cy="490.888"
                        fill="#2b79ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.331"
                        rx="6.322"
                        ry="2.826"
                        transform="matrix(.83638 -.54816 .8291 .55911 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="333.124"
                        cy="332.82"
                        fill="#1a67ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.079"
                        rx="4.089"
                        ry="2.869"
                        transform="matrix(.99884 -.04808 .38529 .9228 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="136.025"
                        cy="419.44"
                        fill="#2879ff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.446"
                        rx="6.7"
                        ry="3.146"
                        transform="matrix(.97404 -.2264 .74646 .66543 0 0)"
                    ></ellipse>
                    <ellipse
                        cx="70.229"
                        cy="466.126"
                        fill="#4786f8"
                        stroke="none"
                        strokeLinecap="round"
                        strokeWidth="1.743"
                        rx="8.32"
                        ry="3.683"
                        transform="matrix(.98109 -.19355 .79744 .6034 0 0)"
                    ></ellipse>
                </g>
            </g>
            <g
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeOpacity="1"
                strokeWidth="0.432"
                display="inline"
            >
                <g transform="translate(30.319 1.93)">
                    <path
                        fill="#8ca0d4"
                        fillOpacity="1"
                        stroke="none"
                        d="M157.248 338.012l-.44.734-.848.448-1.182.41-1.44.916-2.05.543-3.385.057-4.74-.162-4.29-.057-2.346-.887-2.632-2.012-1.45-2.279-.82-2.098-.42-2.345-.018-1.342.362-2.711.887-2.413 1.44-2.202 2.07-1.822 2.85-1.325 2.909-.668 2.631.01 2.384.525 1.726.753 1.43.906 1.355 1.04 1.125 1.182.915 1.287.887 1.678.572 1.955.4 2.317.067 1.993-.038 1.655 1.326 1.702.798 1.127z"
                        display="inline"
                    ></path>
                    <path
                        fill="none"
                        stroke="#4e6cbc"
                        d="M139.197 341.063l4.539-.038 2.25.114 2.098.039h2.212l1.87-.23 1.792-.648.648-.534.84-.381 1.067-.343.61-.61.23-.65-.058-.838-.572-.935-.782-.896-.782-1.016c.065-.37.764-7.249-3.018-11.272-2.643-2.812-6.617-5.009-12.083-3.81-12.039 2.638-11.522 19.688-.861 22.048z"
                    ></path>
                    <path
                        fill="none"
                        fillOpacity="1"
                        stroke="#4d6cbd"
                        d="M143.736 341.025c3.432-2.259 4.913-5.321 4.996-8.697-1.217-6.658-4.574-7.66-8.12-6.752-.67.172-1.643.65-2.176 1.328-4.275 5.754-.748 8.68 2.096 9.41 3.103.015 5.038-1.207 4.692-4.844-.161-1.415-.89-2.413-2.842-2.241-1.806.375-2.707.787-2.537 3.957.055.48.287.732.536.861.655.339 1.703.012 1.562-1.624"
                        display="inline"
                    ></path>
                </g>
                <g
                    display="inline"
                    transform="matrix(-.85971 0 0 .85971 537.717 70.022)"
                >
                    <path
                        fill="#8ca0d4"
                        fillOpacity="1"
                        stroke="none"
                        d="M164.453 341.314l-.438.735-.85.448-1.181.41-1.44.915-2.05.544-3.386.057-4.739-.162-4.29-.057-2.347-.887-2.631-2.012-1.45-2.28-.82-2.097-.42-2.346-.018-1.342.362-2.71.887-2.413 1.44-2.203 2.069-1.821 2.85-1.325 2.91-.668 2.631.01 2.384.525 1.726.753 1.43.906 1.354 1.04 1.126 1.182.915 1.287.887 1.678.572 1.955.4 2.317.067 1.993-.038 1.654 1.326 1.703.797 1.127z"
                        display="inline"
                    ></path>
                    <path
                        fill="none"
                        stroke="#4e6cbc"
                        d="M146.402 344.366l4.54-.038 2.25.114 2.098.038h2.212l1.869-.229 1.793-.648.648-.534.84-.381 1.067-.344.61-.61.23-.649-.058-.839-.572-.934-.782-.896-.782-1.016c.065-.37.764-7.25-3.018-11.272-2.643-2.812-6.617-5.01-12.083-3.811-12.039 2.639-11.523 19.69-.862 22.049z"
                    ></path>
                    <path
                        fill="none"
                        fillOpacity="1"
                        stroke="#4d6cbd"
                        d="M150.941 344.328c3.433-2.26 4.914-5.322 4.997-8.697-1.217-6.658-4.574-7.66-8.121-6.752-.669.171-1.643.65-2.175 1.328-4.276 5.754-.748 8.68 2.096 9.41 3.103.014 5.038-1.207 4.691-4.845-.16-1.415-.889-2.412-2.841-2.24-1.807.374-2.708.787-2.537 3.957.054.48.286.732.536.86.655.34 1.702.012 1.562-1.623"
                        display="inline"
                    ></path>
                </g>
            </g>
            <g
                fill="#6ef7f7"
                fillOpacity="0.1"
                stroke="#6ef7f7"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeOpacity="0.1"
                strokeWidth="0.265"
                display="inline"
            >
                <path d="M146.86 0L0 158.732v-15.717L137.07 0z"></path>
                <path d="M0 236.77L164.077 0h24.23L29.078 381H0z"></path>
                <path d="M229.154 0l-81.692 381h65.076L247.154 0z"></path>
                <path d="M299.077 0L267.23 381h29.077l6.23-381z"></path>
                <path d="M321.87 0l-3.408 381h65.076L337.846 0z"></path>
                <path d="M416.595 0l38.943 381h52.616L427.846 0z"></path>
                <path d="M474.923 0l171.692 381h30.95l-.232-142.154L495 0z"></path>
                <path d="M526.25 0l151.083 174.764V147.84L534.083 0z"></path>
            </g>
        </svg>
    );
}

export default BackgroundSvg;