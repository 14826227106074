

const Board = () => {
    return (

        <svg
            className='bg-cover w-[100%] max-h-[100vh]'
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
            width="1920"
            height="1080"
            version="1.1"
            viewBox="0 0 508 285.75"
        >
            <g>
                <path
                    fill="#86d665"
                    fillOpacity="1"
                    stroke="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeOpacity="1"
                    strokeWidth="0.265"
                    d="M0 0h508v285.75H0z"
                ></path>
            </g>
            <g
                fill="none"
                stroke="#478635"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="0.925"
                display="inline"
            >
                <path d="M10.237 26.501V16.416"></path>
                <path d="M19.428 17.951V7.866" display="inline"></path>
                <path d="M15.72 41.199v-7.152" display="inline"></path>
                <path d="M14.12 26.501v-5.656"></path>
                <path
                    d="M5.631 37.057c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M1.748 37.057c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M30.082 11.544c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M26.199 11.544c.498-1.446.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M24.332 36.064c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M28.216 36.064c.143-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M7.46 11.819c2.032-6.544.713-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M3.577 11.819c-.144-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M46.024 26.778V16.694" display="inline"></path>
                <path d="M55.215 18.229V8.144" display="inline"></path>
                <path d="M51.507 41.476v-7.152" display="inline"></path>
                <path d="M49.908 26.778v-5.655" display="inline"></path>
                <path
                    d="M41.418 37.335c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M37.535 37.335c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M65.87 11.821c-.52-3.039-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M61.986 11.821c.498-1.446.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M60.12 36.342c-1.061-6.544-.669-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M64.003 36.342c.144-1.886 1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M43.248 12.096c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M39.364 12.096c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M81.186 26.55V16.463" display="inline"></path>
                <path d="M90.377 18V7.913" display="inline"></path>
                <path d="M86.669 41.247v-7.152" display="inline"></path>
                <path d="M85.07 26.55v-5.657" display="inline"></path>
                <path
                    d="M76.58 37.105c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M72.696 37.105c.075-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M101.031 11.592c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M97.147 11.592c.5-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M95.28 36.112c-1.06-6.543-.667-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M99.164 36.112c.144-1.885 1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M78.41 11.866c2.031-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M74.526 11.866c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M116.973 26.826V16.741" display="inline"></path>
                <path d="M126.164 18.276V8.192" display="inline"></path>
                <path d="M122.456 41.524v-7.152" display="inline"></path>
                <path d="M120.856 26.826v-5.655" display="inline"></path>
                <path
                    d="M112.367 37.382c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M108.483 37.382c.075-1.879 1.358-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M136.818 11.87c-.52-3.04-1.694-5.636 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M132.935 11.87c.498-1.447.926-3.82 0-5.657"
                    display="inline"
                ></path>
                <path
                    d="M131.068 36.39c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M134.951 36.39c.144-1.886 1.063-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M114.197 12.144c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M110.313 12.144c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M152.323 26.49V16.407" display="inline"></path>
                <path d="M161.514 17.94V7.857" display="inline"></path>
                <path d="M157.806 41.188v-7.152" display="inline"></path>
                <path d="M156.207 26.49v-5.655" display="inline"></path>
                <path
                    d="M147.717 37.047c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M143.834 37.047c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M172.168 11.533c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M168.285 11.533c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M166.418 36.054c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M170.302 36.054c.144-1.886 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M149.547 11.808c2.032-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M145.664 11.808c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M188.11 26.768V16.683" display="inline"></path>
                <path d="M197.301 18.218V8.133" display="inline"></path>
                <path d="M193.593 41.465v-7.151" display="inline"></path>
                <path d="M191.994 26.768v-5.656" display="inline"></path>
                <path
                    d="M183.504 37.324c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M179.621 37.324c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M207.956 11.81c-.52-3.039-1.695-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M204.072 11.81c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M202.206 36.33c-1.061-6.543-.669-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M206.089 36.33c.144-1.884 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M185.334 12.085c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M181.45 12.085c-.143-1.885-1.061-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M223.272 26.539V16.454" display="inline"></path>
                <path d="M232.463 17.989V7.904" display="inline"></path>
                <path d="M228.755 41.236v-7.152" display="inline"></path>
                <path d="M227.156 26.539v-5.656" display="inline"></path>
                <path
                    d="M218.666 37.095c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M214.783 37.095c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M243.117 11.581c-.52-3.039-1.694-5.634 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M239.234 11.581c.499-1.446.926-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M237.367 36.102c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M241.25 36.102c.145-1.886 1.063-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M220.496 11.856c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M216.613 11.856c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M259.06 26.816V16.73" display="inline"></path>
                <path d="M268.25 18.266V8.18" display="inline"></path>
                <path d="M264.542 41.513v-7.152" display="inline"></path>
                <path d="M262.943 26.816V21.16" display="inline"></path>
                <path
                    d="M254.453 37.372c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M250.57 37.372c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M278.904 11.859c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M275.02 11.859c.5-1.447.928-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M273.154 36.379c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M277.038 36.379c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M256.283 12.133c2.032-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M252.4 12.133c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M294.216 26.432V16.347" display="inline"></path>
                <path d="M303.407 17.882V7.797" display="inline"></path>
                <path d="M299.699 41.13v-7.152" display="inline"></path>
                <path d="M298.1 26.432v-5.656" display="inline"></path>
                <path
                    d="M289.61 36.988c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M285.726 36.988c.075-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M314.061 11.475c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M310.177 11.475c.5-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M308.31 35.995c-1.06-6.543-.667-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M312.194 35.995c.144-1.885 1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M291.44 11.75c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M287.556 11.75c-.144-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M330.003 26.71V16.624" display="inline"></path>
                <path d="M339.194 18.16V8.075" display="inline"></path>
                <path d="M335.486 41.407v-7.152" display="inline"></path>
                <path d="M333.886 26.71v-5.656" display="inline"></path>
                <path
                    d="M325.397 37.266c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M321.513 37.266c.075-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M349.848 11.752c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M345.965 11.752c.498-1.446.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M344.098 36.272c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M347.981 36.272c.144-1.885 1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M327.227 12.027c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M323.343 12.027c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M365.165 26.48V16.395" display="inline"></path>
                <path d="M374.356 17.93V7.845" display="inline"></path>
                <path d="M370.648 41.178v-7.152" display="inline"></path>
                <path d="M369.048 26.48v-5.656" display="inline"></path>
                <path
                    d="M360.559 37.036c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M356.675 37.036c.075-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M385.01 11.523c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M381.126 11.523c.5-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M379.26 36.043c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M383.143 36.043c.144-1.885 1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M362.389 11.797c2.031-6.543.711-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M358.505 11.797c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M400.952 26.757V16.672" display="inline"></path>
                <path d="M410.143 18.207V8.123" display="inline"></path>
                <path d="M406.435 41.455v-7.152" display="inline"></path>
                <path d="M404.835 26.757v-5.655" display="inline"></path>
                <path
                    d="M396.346 37.313c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M392.462 37.313c.075-1.879 1.358-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M420.797 11.8c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M416.913 11.8c.5-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M415.047 36.32c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M418.93 36.32c.144-1.885 1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M398.176 12.075c2.031-6.544.711-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M394.292 12.075c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M436.302 26.422V16.337" display="inline"></path>
                <path d="M445.493 17.872V7.787" display="inline"></path>
                <path d="M441.785 41.119v-7.152" display="inline"></path>
                <path d="M440.186 26.422v-5.656" display="inline"></path>
                <path
                    d="M431.696 36.978c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M427.813 36.978c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M456.147 11.464c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M452.264 11.464c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M450.397 35.985c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M454.28 35.985c.145-1.886 1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M433.526 11.739c2.032-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M429.643 11.739c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M472.09 26.699V16.614" display="inline"></path>
                <path d="M481.28 18.149V8.064" display="inline"></path>
                <path d="M477.572 41.396v-7.151" display="inline"></path>
                <path d="M475.973 26.699v-5.656" display="inline"></path>
                <path
                    d="M467.483 37.255c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M463.6 37.255c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M491.934 11.742c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M488.05 11.742c.5-1.447.928-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M486.184 36.262c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M490.068 36.262c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M469.313 12.016c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M465.43 12.016c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M502.645 37.026c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M498.762 37.026c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M504.475 11.787c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M500.591 11.787c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M10.84 269.161v-10.085" display="inline"></path>
                <path d="M20.03 260.611v-10.085" display="inline"></path>
                <path d="M16.322 283.859v-7.152" display="inline"></path>
                <path d="M14.723 269.161v-5.656" display="inline"></path>
                <path
                    d="M6.233 279.717c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M2.35 279.717c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M30.684 254.204c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M26.801 254.204c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M24.934 278.724c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M28.818 278.724c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M8.063 254.478c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M4.18 254.478c-.144-1.885-1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M46.626 269.438v-10.085" display="inline"></path>
                <path d="M55.817 260.888v-10.084" display="inline"></path>
                <path d="M52.11 284.136v-7.152" display="inline"></path>
                <path d="M50.51 269.438v-5.655" display="inline"></path>
                <path
                    d="M42.02 279.994c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M38.137 279.994c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M66.472 254.481c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M62.588 254.481c.499-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M60.721 279.001c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M64.605 279.001c.144-1.885 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M43.85 254.756c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M39.967 254.756c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M81.788 269.21v-10.086" display="inline"></path>
                <path d="M90.98 260.66v-10.086" display="inline"></path>
                <path d="M87.271 283.907v-7.152" display="inline"></path>
                <path d="M85.672 269.21v-5.657" display="inline"></path>
                <path
                    d="M77.182 279.765c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M73.299 279.765c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M101.633 254.252c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M97.75 254.252c.499-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M95.883 278.772c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M99.767 278.772c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M79.012 254.526c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M75.129 254.526c-.144-1.885-1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M11.959 227.459v-10.085" display="inline"></path>
                <path d="M21.15 218.909v-10.085" display="inline"></path>
                <path d="M17.441 242.156v-7.151" display="inline"></path>
                <path d="M15.842 227.459v-5.656" display="inline"></path>
                <path
                    d="M7.353 238.015c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M3.469 238.015c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M31.804 212.502c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M27.92 212.502c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M26.054 237.022c-1.061-6.544-.669-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M29.937 237.022c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M9.182 212.776c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M5.299 212.776c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M47.746 227.736v-10.085" display="inline"></path>
                <path d="M56.937 219.186v-10.085" display="inline"></path>
                <path d="M53.228 242.433v-7.151" display="inline"></path>
                <path d="M51.63 227.736v-5.656" display="inline"></path>
                <path
                    d="M43.14 238.292c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M39.256 238.292c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M67.59 212.779c-.519-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M63.707 212.779c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M61.84 237.299c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M65.724 237.299c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M44.97 213.053c2.03-6.543.711-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M41.086 213.053c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M13.078 185.768v-10.085" display="inline"></path>
                <path d="M22.269 177.218v-10.085" display="inline"></path>
                <path d="M18.56 200.465v-7.151" display="inline"></path>
                <path d="M16.961 185.768v-5.656" display="inline"></path>
                <path
                    d="M8.472 196.324c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M4.589 196.324c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M32.923 170.81c-.52-3.038-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M29.04 170.81c.498-1.446.926-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M27.173 195.331c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M31.057 195.331c.143-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M10.302 171.085c2.031-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M6.418 171.085c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M48.865 186.045V175.96" display="inline"></path>
                <path d="M58.056 177.495V167.41" display="inline"></path>
                <path d="M54.348 200.743v-7.152" display="inline"></path>
                <path d="M52.749 186.045v-5.656" display="inline"></path>
                <path
                    d="M44.26 196.601c-.52-3.039-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M40.376 196.601c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M68.71 171.088c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M64.827 171.088c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M62.96 195.608c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M66.844 195.608c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M46.089 171.363c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M42.205 171.363c-.144-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M11.693 145.614V135.53" display="inline"></path>
                <path d="M20.884 137.064V126.98" display="inline"></path>
                <path d="M17.176 160.312v-7.152" display="inline"></path>
                <path d="M15.577 145.614v-5.656" display="inline"></path>
                <path
                    d="M7.087 156.17c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M3.204 156.17c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M31.538 130.657c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M27.655 130.657c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M25.788 155.177c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M29.672 155.177c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M8.917 130.931c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M5.034 130.931c-.144-1.885-1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M47.48 145.891v-10.085" display="inline"></path>
                <path d="M56.671 137.341v-10.084" display="inline"></path>
                <path d="M52.963 160.589v-7.152" display="inline"></path>
                <path d="M51.364 145.891v-5.655" display="inline"></path>
                <path
                    d="M42.874 156.447c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M38.991 156.447c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M67.326 130.934c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M63.442 130.934c.499-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M61.575 155.454c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M65.459 155.454c.144-1.885 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M44.704 131.209c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M40.82 131.209c-.143-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M12.04 106.153V96.068" display="inline"></path>
                <path d="M21.23 97.603V87.518" display="inline"></path>
                <path d="M17.522 120.85v-7.152" display="inline"></path>
                <path d="M15.923 106.153v-5.656" display="inline"></path>
                <path
                    d="M7.434 116.709c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M3.55 116.709c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M31.885 91.195c-.52-3.039-1.695-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M28.001 91.195c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M26.135 115.716c-1.061-6.544-.669-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M30.018 115.716c.144-1.886 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M9.263 91.47c2.032-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M5.38 91.47c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M47.827 106.43V96.345" display="inline"></path>
                <path d="M57.018 97.88V87.795" display="inline"></path>
                <path d="M53.31 121.127v-7.151" display="inline"></path>
                <path d="M51.71 106.43v-5.656" display="inline"></path>
                <path
                    d="M43.22 116.986c-.519-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M39.337 116.986c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M67.672 91.473c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M63.788 91.473c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M61.922 115.993c-1.061-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M65.805 115.993c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M45.05 91.747c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M41.167 91.747c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M11.693 66.345V56.26" display="inline"></path>
                <path d="M20.884 57.795V47.71" display="inline"></path>
                <path d="M17.176 81.042v-7.151" display="inline"></path>
                <path d="M15.577 66.345v-5.656" display="inline"></path>
                <path
                    d="M7.087 76.901c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M3.204 76.901c.074-1.88 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M31.538 51.388c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M27.655 51.388c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M25.788 75.908c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M29.672 75.908c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M8.917 51.662c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M5.034 51.662c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M47.48 66.622V56.537" display="inline"></path>
                <path d="M56.671 58.072V47.987" display="inline"></path>
                <path d="M52.963 81.32v-7.152" display="inline"></path>
                <path d="M51.364 66.622v-5.656" display="inline"></path>
                <path
                    d="M42.874 77.178c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M38.991 77.178c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M67.325 51.665c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M63.442 51.665c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M61.575 76.185c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M65.459 76.185c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M44.704 51.94c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M40.82 51.94c-.143-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M82.098 227.427v-10.085" display="inline"></path>
                <path d="M91.289 218.877v-10.085" display="inline"></path>
                <path d="M87.58 242.125v-7.152" display="inline"></path>
                <path d="M85.981 227.427v-5.656" display="inline"></path>
                <path
                    d="M77.492 237.983c-.52-3.039-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M73.608 237.983c.074-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M101.943 212.47c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M98.06 212.47c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M96.193 236.99c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M100.076 236.99c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M79.321 212.744c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M75.438 212.744c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M83.217 185.736v-10.084" display="inline"></path>
                <path d="M92.408 177.186v-10.084" display="inline"></path>
                <path d="M88.7 200.434v-7.152" display="inline"></path>
                <path d="M87.1 185.736v-5.655" display="inline"></path>
                <path
                    d="M78.611 196.292c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M74.728 196.292c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M103.062 170.78c-.52-3.04-1.694-5.636 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M99.179 170.78c.498-1.447.926-3.82 0-5.657"
                    display="inline"
                ></path>
                <path
                    d="M97.312 195.3c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M101.196 195.3c.144-1.886 1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M80.44 171.054c2.032-6.544.713-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M76.557 171.054c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M81.833 145.583v-10.085" display="inline"></path>
                <path d="M91.023 137.033v-10.085" display="inline"></path>
                <path d="M87.315 160.28v-7.152" display="inline"></path>
                <path d="M85.716 145.583v-5.656" display="inline"></path>
                <path
                    d="M77.227 156.139c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M73.343 156.139c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M101.678 130.625c-.52-3.039-1.695-5.634 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M97.794 130.625c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M95.928 155.146c-1.061-6.544-.669-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M99.811 155.146c.144-1.886 1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M79.056 130.9c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M75.173 130.9c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M82.179 106.121V96.036" display="inline"></path>
                <path d="M91.37 97.571V87.486" display="inline"></path>
                <path d="M87.661 120.818v-7.151" display="inline"></path>
                <path d="M86.062 106.121v-5.656" display="inline"></path>
                <path
                    d="M77.573 116.677c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M73.69 116.677c.073-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M102.024 91.164c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M98.14 91.164c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M96.274 115.684c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M100.157 115.684c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M79.402 91.438c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M75.519 91.438c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M81.833 66.313V56.228" display="inline"></path>
                <path d="M91.023 57.763V47.678" display="inline"></path>
                <path d="M87.315 81.01V73.86" display="inline"></path>
                <path d="M85.716 66.313v-5.656" display="inline"></path>
                <path
                    d="M77.227 76.87c-.52-3.04-1.695-5.635 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M73.343 76.87c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M101.678 51.356c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M97.794 51.356c.499-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M95.928 75.876c-1.061-6.543-.669-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M99.811 75.876c.144-1.885 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M79.056 51.63c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M75.173 51.63c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M412.838 227.876V217.79" display="inline"></path>
                <path d="M422.03 219.326V209.24" display="inline"></path>
                <path d="M418.32 242.573v-7.152" display="inline"></path>
                <path d="M416.722 227.876v-5.656" display="inline"></path>
                <path
                    d="M408.232 238.432c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M404.349 238.432c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M432.683 212.918c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M428.8 212.918c.498-1.446.926-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M426.933 237.439c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M430.817 237.439c.144-1.886 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M410.062 213.193c2.031-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M406.178 213.193c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M448.625 228.153v-10.085" display="inline"></path>
                <path d="M457.816 219.603v-10.085" display="inline"></path>
                <path d="M454.108 242.85V235.7" display="inline"></path>
                <path d="M452.509 228.153v-5.656" display="inline"></path>
                <path
                    d="M444.02 238.709c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M440.136 238.709c.074-1.88 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M468.47 213.196c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M464.587 213.196c.499-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M462.72 237.716c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M466.604 237.716c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M445.849 213.47c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M441.966 213.47c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M413.958 186.185V176.1" display="inline"></path>
                <path d="M423.149 177.635V167.55" display="inline"></path>
                <path d="M419.44 200.882v-7.151" display="inline"></path>
                <path d="M417.841 186.185v-5.656" display="inline"></path>
                <path
                    d="M409.352 196.74c-.52-3.038-1.695-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M405.468 196.74c.074-1.878 1.358-3.656 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M433.803 171.228c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M429.92 171.228c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M428.053 195.748c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M431.936 195.748c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M411.181 171.502c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M407.298 171.502c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M449.745 186.462v-10.085" display="inline"></path>
                <path d="M458.936 177.912v-10.085" display="inline"></path>
                <path d="M455.227 201.16v-7.152" display="inline"></path>
                <path d="M453.628 186.462v-5.656" display="inline"></path>
                <path
                    d="M445.139 197.018c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M441.255 197.018c.075-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M469.59 171.505c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M465.706 171.505c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M463.84 196.025c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M467.723 196.025c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M446.968 171.78c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M443.085 171.78c-.144-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M412.573 146.031v-10.085" display="inline"></path>
                <path d="M421.764 137.481v-10.085" display="inline"></path>
                <path d="M418.056 160.728v-7.151" display="inline"></path>
                <path d="M416.457 146.031v-5.656" display="inline"></path>
                <path
                    d="M407.967 156.587c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M404.084 156.587c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M432.418 131.074c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M428.535 131.074c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M426.668 155.594c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M430.552 155.594c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M409.797 131.348c2.031-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M405.913 131.348c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M448.36 146.308v-10.085" display="inline"></path>
                <path d="M457.551 137.758v-10.085" display="inline"></path>
                <path d="M453.843 161.006v-7.152" display="inline"></path>
                <path d="M452.244 146.308v-5.656" display="inline"></path>
                <path
                    d="M443.754 156.864c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M439.87 156.864c.075-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M468.205 131.351c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M464.322 131.351c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M462.455 155.871c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M466.339 155.871c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M445.584 131.626c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M441.7 131.626c-.144-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M412.92 106.57V96.484" display="inline"></path>
                <path d="M422.11 98.02V87.935" display="inline"></path>
                <path d="M418.402 121.267v-7.152" display="inline"></path>
                <path d="M416.803 106.57v-5.656" display="inline"></path>
                <path
                    d="M408.313 117.126c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M404.43 117.126c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M432.764 91.612c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M428.88 91.612c.5-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M427.014 116.133c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M430.898 116.133c.144-1.886 1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M410.143 91.887c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M406.26 91.887c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M448.706 106.847V96.762" display="inline"></path>
                <path d="M457.897 98.297V88.212" display="inline"></path>
                <path d="M454.189 121.544v-7.152" display="inline"></path>
                <path d="M452.59 106.847v-5.656" display="inline"></path>
                <path
                    d="M444.1 117.403c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M440.217 117.403c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M468.551 91.89c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M464.668 91.89c.499-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M462.801 116.41c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M466.685 116.41c.144-1.886 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M445.93 92.164c2.032-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M442.047 92.164c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M412.573 66.762V56.677" display="inline"></path>
                <path d="M421.764 58.212V48.127" display="inline"></path>
                <path d="M418.056 81.46v-7.152" display="inline"></path>
                <path d="M416.457 66.762v-5.656" display="inline"></path>
                <path
                    d="M407.967 77.318c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M404.084 77.318c.074-1.88 1.357-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M432.418 51.805c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M428.535 51.805c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M426.668 76.325c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M430.552 76.325c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M409.797 52.08c2.031-6.544.712-8.728 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M405.913 52.08c-.144-1.886-1.062-3.771 0-5.657"
                    display="inline"
                ></path>
                <path d="M448.36 67.039V56.954" display="inline"></path>
                <path d="M457.551 58.49V48.403" display="inline"></path>
                <path d="M453.843 81.736v-7.151" display="inline"></path>
                <path d="M452.244 67.039v-5.656" display="inline"></path>
                <path
                    d="M443.754 77.595c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M439.87 77.595c.075-1.879 1.358-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M468.205 52.082c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M464.322 52.082c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M462.455 76.602c-1.06-6.543-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M466.339 76.602c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M445.584 52.356c2.031-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M441.7 52.356c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M482.977 227.844V217.76" display="inline"></path>
                <path d="M492.168 219.294V209.21" display="inline"></path>
                <path d="M488.46 242.541v-7.151" display="inline"></path>
                <path d="M486.86 227.844v-5.656" display="inline"></path>
                <path
                    d="M478.371 238.4c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M474.488 238.4c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M502.822 212.887c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M498.939 212.887c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M497.072 237.407c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M500.956 237.407c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M480.201 213.161c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M476.318 213.161c-.144-1.885-1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M484.097 186.153v-10.085" display="inline"></path>
                <path d="M493.288 177.603v-10.085" display="inline"></path>
                <path d="M489.58 200.85V193.7" display="inline"></path>
                <path d="M487.98 186.153v-5.656" display="inline"></path>
                <path
                    d="M479.49 196.71c-.519-3.04-1.693-5.636 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M475.607 196.71c.075-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M503.942 171.196c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M500.058 171.196c.5-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M498.192 195.716c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M502.075 195.716c.144-1.885 1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M481.32 171.47c2.032-6.543.712-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M477.437 171.47c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M482.712 146v-10.085" display="inline"></path>
                <path d="M491.903 137.45v-10.085" display="inline"></path>
                <path d="M488.195 160.697v-7.152" display="inline"></path>
                <path d="M486.596 146v-5.656" display="inline"></path>
                <path
                    d="M478.106 156.555c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M474.223 156.555c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M502.557 131.042c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M498.674 131.042c.498-1.446.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M496.807 155.562c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M500.69 155.562c.145-1.885 1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M479.936 131.317c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M476.052 131.317c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M483.058 106.538V96.453" display="inline"></path>
                <path d="M492.25 97.988V87.903" display="inline"></path>
                <path d="M488.541 121.235v-7.151" display="inline"></path>
                <path d="M486.942 106.538v-5.656" display="inline"></path>
                <path
                    d="M478.452 117.094c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M474.569 117.094c.074-1.88 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M502.903 91.58c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M499.02 91.58c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M497.153 116.1c-1.06-6.542-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M501.037 116.1c.144-1.884 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M480.282 91.855c2.032-6.543.712-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M476.399 91.855c-.144-1.885-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M482.712 66.73V56.645" display="inline"></path>
                <path d="M491.903 58.18V48.095" display="inline"></path>
                <path d="M488.195 81.428v-7.152" display="inline"></path>
                <path d="M486.596 66.73v-5.656" display="inline"></path>
                <path
                    d="M478.106 77.286c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M474.223 77.286c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M502.557 51.773c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M498.674 51.773c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M496.807 76.293c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M500.69 76.293c.145-1.885 1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M479.936 52.048c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M476.052 52.048c-.144-1.886-1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M117.575 269.486v-10.085" display="inline"></path>
                <path d="M126.766 260.936v-10.085" display="inline"></path>
                <path d="M123.058 284.184v-7.152" display="inline"></path>
                <path d="M121.459 269.486v-5.656" display="inline"></path>
                <path
                    d="M112.97 280.042c-.52-3.039-1.695-5.634 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M109.086 280.042c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M137.42 254.53c-.52-3.04-1.694-5.636 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M133.537 254.53c.499-1.447.927-3.821 0-5.657"
                    display="inline"
                ></path>
                <path
                    d="M131.67 279.05c-1.06-6.544-.668-8.728 0-10.086"
                    display="inline"
                ></path>
                <path
                    d="M135.554 279.05c.144-1.886 1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M114.8 254.804c2.03-6.544.711-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M110.916 254.804c-.144-1.886-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M152.926 269.15v-10.084" display="inline"></path>
                <path d="M162.117 260.6v-10.084" display="inline"></path>
                <path d="M158.409 283.848v-7.152" display="inline"></path>
                <path d="M156.81 269.15v-5.655" display="inline"></path>
                <path
                    d="M148.32 279.707c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M144.436 279.707c.075-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M172.77 254.193c-.519-3.039-1.693-5.634 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M168.887 254.193c.5-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M167.02 278.714c-1.06-6.544-.667-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M170.904 278.714c.144-1.886 1.063-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M150.15 254.468c2.031-6.544.711-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M146.266 254.468c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M188.713 269.428v-10.085" display="inline"></path>
                <path d="M197.904 260.878v-10.085" display="inline"></path>
                <path d="M194.196 284.125v-7.152" display="inline"></path>
                <path d="M192.596 269.428v-5.656" display="inline"></path>
                <path
                    d="M184.107 279.984c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M180.223 279.984c.075-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M208.558 254.47c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M204.674 254.47c.5-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M202.808 278.99c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M206.691 278.99c.144-1.885 1.063-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M185.937 254.745c2.031-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M182.053 254.745c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M223.875 269.198v-10.084" display="inline"></path>
                <path d="M233.066 260.649v-10.085" display="inline"></path>
                <path d="M229.357 283.896v-7.152" display="inline"></path>
                <path d="M227.758 269.198v-5.655" display="inline"></path>
                <path
                    d="M219.269 279.754c-.52-3.039-1.695-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M215.385 279.754c.074-1.879 1.358-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M243.72 254.241c-.52-3.039-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M239.836 254.241c.499-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M237.97 278.761c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M241.853 278.761c.144-1.885 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M221.098 254.516c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M217.215 254.516c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M259.662 269.476V259.39" display="inline"></path>
                <path d="M268.853 260.926V250.84" display="inline"></path>
                <path d="M265.144 284.173v-7.152" display="inline"></path>
                <path d="M263.545 269.476v-5.656" display="inline"></path>
                <path
                    d="M255.056 280.032c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M251.172 280.032c.075-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M279.507 254.518c-.52-3.039-1.694-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M275.623 254.518c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M273.757 279.039c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M277.64 279.039c.144-1.886 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M256.885 254.793c2.032-6.543.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M253.002 254.793c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M294.818 269.092v-10.085" display="inline"></path>
                <path d="M304.01 260.542v-10.085" display="inline"></path>
                <path d="M300.301 283.79v-7.152" display="inline"></path>
                <path d="M298.702 269.092v-5.656" display="inline"></path>
                <path
                    d="M290.212 279.648c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M286.329 279.648c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M314.663 254.135c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M310.78 254.135c.499-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M308.913 278.655c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M312.797 278.655c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M292.042 254.41c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M288.159 254.41c-.144-1.886-1.063-3.771 0-5.656"
                    display="inline"
                ></path>
                <path d="M330.605 269.37v-10.086" display="inline"></path>
                <path d="M339.796 260.82v-10.085" display="inline"></path>
                <path d="M336.088 284.067v-7.152" display="inline"></path>
                <path d="M334.489 269.37v-5.656" display="inline"></path>
                <path
                    d="M326 279.925c-.52-3.039-1.695-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M322.116 279.925c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M350.45 254.412c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M346.567 254.412c.499-1.446.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M344.7 278.932c-1.06-6.543-.668-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M348.584 278.932c.144-1.885 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M327.83 254.687c2.03-6.544.711-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M323.946 254.687c-.144-1.886-1.063-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M365.767 269.14v-10.085" display="inline"></path>
                <path d="M374.958 260.59v-10.085" display="inline"></path>
                <path d="M371.25 283.838v-7.152" display="inline"></path>
                <path d="M369.65 269.14v-5.656" display="inline"></path>
                <path
                    d="M361.161 279.696c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M357.278 279.696c.074-1.879 1.357-3.657 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M385.612 254.183c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M381.729 254.183c.498-1.447.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M379.862 278.703c-1.06-6.543-.668-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M383.746 278.703c.144-1.885 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M362.99 254.457c2.032-6.543.713-8.727 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M359.107 254.457c-.144-1.885-1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path d="M401.554 269.417v-10.085" display="inline"></path>
                <path d="M410.745 260.867v-10.085" display="inline"></path>
                <path d="M407.037 284.115v-7.152" display="inline"></path>
                <path d="M405.438 269.417v-5.656" display="inline"></path>
                <path
                    d="M396.948 279.973c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M393.065 279.973c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M421.4 254.46c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M417.516 254.46c.498-1.446.926-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M415.65 278.98c-1.061-6.543-.669-8.727 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M419.533 278.98c.144-1.885 1.062-3.77 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M398.778 254.735c2.031-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M394.894 254.735c-.143-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M436.905 269.082v-10.085" display="inline"></path>
                <path d="M446.096 260.532v-10.085" display="inline"></path>
                <path d="M442.387 283.779v-7.152" display="inline"></path>
                <path d="M440.788 269.082v-5.656" display="inline"></path>
                <path
                    d="M432.299 279.638c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M428.415 279.638c.074-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M456.75 254.124c-.52-3.039-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M452.866 254.124c.499-1.446.927-3.82 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M451 278.645c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M454.883 278.645c.144-1.886 1.062-3.771 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M434.128 254.399c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M430.245 254.399c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path d="M472.692 269.359v-10.085" display="inline"></path>
                <path d="M481.883 260.809v-10.085" display="inline"></path>
                <path d="M478.175 284.056v-7.152" display="inline"></path>
                <path d="M476.575 269.359v-5.656" display="inline"></path>
                <path
                    d="M468.086 279.915c-.52-3.04-1.695-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M464.202 279.915c.074-1.88 1.358-3.658 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M492.537 254.402c-.52-3.04-1.694-5.635 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M488.653 254.402c.499-1.447.927-3.82 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M486.787 278.922c-1.06-6.544-.668-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M490.67 278.922c.144-1.886 1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M469.916 254.676c2.031-6.543.711-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M466.032 254.676c-.144-1.885-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
                <path
                    d="M503.248 279.685c-.52-3.039-1.695-5.634 0-10.084"
                    display="inline"
                ></path>
                <path
                    d="M499.364 279.685c.074-1.879 1.357-3.657 0-5.655"
                    display="inline"
                ></path>
                <path
                    d="M505.077 254.447c2.032-6.544.712-8.728 0-10.085"
                    display="inline"
                ></path>
                <path
                    d="M501.194 254.447c-.144-1.886-1.062-3.77 0-5.656"
                    display="inline"
                ></path>
            </g>
            <g className='z-[20]' display="inline">
                <path
                    fill="#d3b66b"
                    fillOpacity="1"
                    stroke="#67340c"
                    strokeDasharray="none"
                    strokeLinecap="round"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="5.556"
                    d="M111.125 46.302v193.146h285.75V46.302z"
                ></path>
            </g>
            <g display="inline">
                <path
                    fill="none"
                    stroke="#67340c"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="1.852"
                    d="M108.347 143.007h291.306"
                    display="inline"
                ></path>
            </g>
        </svg>
    );
}

export default Board;
