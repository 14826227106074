const AttackIcon = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 26.458 26.458"
        >
            <g
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
            >
                <path
                    fill="#184084"
                    fillOpacity="1"
                    strokeWidth="0.794"
                    d="M2.441 20.996v1.955h1.955l3.422-3.472-2.038-1.798z"
                ></path>
                <path fill="none" strokeWidth="1.587" d="M3.36 15.17l7.183 6.1"></path>
                <path
                    fill="#184084"
                    fillOpacity="1"
                    strokeWidth="0.794"
                    d="M5.004 16.554L17.85 3.493h3.72l-.042 3.288L9.072 19.929"
                ></path>
                <path fill="none" strokeWidth="1.587" d="M3.36 15.17l7.183 6.1"></path>
                <path
                    fill="#184084"
                    fillOpacity="1"
                    strokeWidth="0.794"
                    d="M23.948 21.114v1.955h-1.955l-3.422-3.472 2.038-1.798z"
                ></path>
                <path
                    fill="none"
                    strokeWidth="1.587"
                    d="M23.029 15.288l-7.183 6.1"
                ></path>
                <path
                    fill="#184084"
                    fillOpacity="1"
                    strokeWidth="0.794"
                    d="M21.384 16.672L8.54 3.611H4.818l.043 3.288 12.456 13.148"
                ></path>
                <path
                    fill="none"
                    strokeWidth="1.587"
                    d="M23.029 15.288l-7.183 6.1"
                ></path>
            </g>
        </svg>
    );
}

export default AttackIcon;