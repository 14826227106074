import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom'
import Home from './Home'
import Header from './Header'
import TurtleTreasure from './TurtleTreasure/TurtleTreasurePage'
import TurtleBrawl from './TurtleBrawl/TurtleBrawl'
import TurtleGameBoard from './TurtleBrawl/GameBoard'
import BrawlGameRules from './TurtleBrawl/BrawlGameRules'
import store from './redux/store';
import { Provider } from 'react-redux';
import { useState } from 'react'

function App() {
  const [currentPathname, setCurrentPathname] = useState(window.location.pathname);

  function myFunction() {
    setCurrentPathname(window.location.pathname);
  }

  const intervalId = setInterval(myFunction, 100);


  return (
    <Router>
      <Provider store={store}>
        {!currentPathname.startsWith("/brawls") ? <Header/> : <></>}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/treasure' element={<TurtleTreasure />} />
          <Route path='/brawl' element={<TurtleBrawl />} />
          <Route path='/brawls/:id' element={<TurtleGameBoard />} />
          <Route exact path='/brawl/rules' element={<BrawlGameRules />} />
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;
