import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../redux/data/dataActions'
import { LatestBlock } from '../../components/getLatestBlock'

const ProgressBar = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, []);

    console.log(data)

    // ------------------------------------------

    let currentBlock = LatestBlock();
    const [endBlockNumber, setEndBlockNumber] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setEndBlockNumber(data.TurtleTreasure.getLotteryEndBlock);
    }, [data.TurtleTreasure.getLotteryEndBlock]);

    useEffect(() => {
        setProgress( (100-(((endBlockNumber-currentBlock)/51840) *100)).toFixed(2) )
    }, [endBlockNumber, currentBlock]);


    return (
        <div className="pt-4 px-4">
            <div className="flex items-center justify-between mb-2">
                <p>Latest block: {currentBlock}</p>
                <p>Current game ends on block: {endBlockNumber}</p>
            </div>

            <div className="relative pt-1">
                <div className="flex mb-2 items-center justify-between">
                    <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                            In Progress
                        </span>
                    </div>
                    <div className="text-right">
                        <span className="text-xs font-semibold inline-block text-teal-600">
                            {progress}%
                        </span>
                    </div>
                </div>
                <div className="rounded flex h-2 mb-4 overflow-hidden text-xs bg-teal-200">
                    <div
                        style={{ width: `${progress}%` }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
                    ></div>
                </div>
            </div>

            <p>Blocks until game end: {endBlockNumber-currentBlock}</p>
        </div>
    );
};

export default ProgressBar;
