import { connect } from '../redux/blockchain/blockchainActions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../redux/data/dataActions'
import { useEffect, useState } from 'react'
import { LatestBlock } from '../components/getLatestBlock'
import ClipLoader from 'react-spinners/ClipLoader'
import BackgroundSvg from './TurtleTreasureBackground'
import bgimg from '../assets/images/bg.png'
import { db } from '../firebase'
import { onValue, ref, get } from 'firebase/database'
import ProgressBar from './components/ProgressBar'

const TurtleTreasurePage = () => {
    /* global BigInt */
    const [isConnected, setIsConnected] = useState(false);

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    //const [claimingNft, setClaimingNft] = useState(false);
    //const [feedback, setFeedback] = useState(`Click 'Mint' to mint your turtle.`);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: null
        },
        GAS_LIMIT: null
    });
    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);

    const buyTickets = () => {
        let ticketAmount = document.getElementById('ticketAmountInput').value
        console.log(ticketAmount)

        let gasLimit = CONFIG.GAS_LIMIT;
        let totalGasLimit = String(gasLimit);
        console.log('Gas limit: ', totalGasLimit);
        //setFeedback(`Buying your Turtle Treasure tickets...`);

        //setClaimingNft(true);
        blockchain.smartContract.methods
            .enter(parseInt(ticketAmount))
            .send({
                gasPrice: 1000000000,
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            })
            //.once('error', (err) => {
            //    console.log(err);
            //    setFeedback('Sorry, something went wrong please try again later.');
            //    setClaimingNft(false);
            //})
            //.then((receipt) => {
            //    console.log(receipt)

                //setTimeout(function () {
                //    fire()
                //}, 5000);
            //    setClaimingNft(false);
            //    dispatch(fetchData(blockchain.account));
            //});
    };

    const drawWinner = () => {
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalGasLimit = String(gasLimit);
        console.log('Gas limit: ', totalGasLimit);

        blockchain.smartContract.methods
            .pickWinner()
            .send({
                gasPrice: 1000000000,
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            })
    }

    useEffect(() => {
        getData();
        if (blockchain.account !== "" && blockchain.account !== null) {
            dispatch(fetchData());
            setIsConnected(true);
        }
    }, [blockchain.account, dispatch]);

    function countUniqueStrings(arr) {
        const uniqueStrings = new Set(arr);
        return uniqueStrings.size;
    }

    function countOccurrences(list, targetString) {
        let count = 0;
        for (const str of list) {
            if (str.toLowerCase() === targetString.toLowerCase()) {
                count++;
            }
        }
        return count;
    }

    const [recentWinner, setRecentWinner] = useState("?");
    const [ticketCost, setTicketCost] = useState("?");
    const [recentWinPot, setRecentWinPot] = useState("?");
    const [isLotteryClaimable, setIsLotteryClaimable] = useState("?");
    const [ticketCount, setTicketCount] = useState("?");
    const [playerTicketsBought, setPlayerTicketsBought] = useState("?");
    const [uniquePlayerCount, setUniquePlayerCount] = useState("?");
    const [getLotteryEndBlock, setGetLotteryEndBlock] = useState("?");
    const [finderFeePoints, setFinderFeePoints] = useState("?");
    const [currentPot, setCurrentPot] = useState("?");
    const [cooldownBlocks, setCooldownBlocks] = useState("?");
    const [contractFeePoints, setContractFeePoints] = useState("?");
    useEffect(() => {
        setRecentWinner(data.TurtleTreasure.recentWinner);
        setTicketCost(data.TurtleTreasure.ticketCost);
        setRecentWinPot(data.TurtleTreasure.recentWinPot);
        setIsLotteryClaimable(data.TurtleTreasure.isLotteryClaimable);
        setTicketCount((data.TurtleTreasure.getPlayers).length);
        setPlayerTicketsBought(countOccurrences(data.TurtleTreasure.getPlayers, blockchain.account));
        setUniquePlayerCount(countUniqueStrings(data.TurtleTreasure.getPlayers));
        setGetLotteryEndBlock(data.TurtleTreasure.getLotteryEndBlock);
        setFinderFeePoints(data.TurtleTreasure.finderFeePoints);
        setCurrentPot(data.TurtleTreasure.currentPot);
        setCooldownBlocks(data.TurtleTreasure.cooldownBlocks);
        setContractFeePoints(data.TurtleTreasure.contractFeePoints);
    }, [data, blockchain.account]);

    const [projectedEndLottery, setProjectedEndLottery] = useState('?');
    let currentBlock = LatestBlock();
    useEffect(() => {
        setProjectedEndLottery(parseInt(getLotteryEndBlock) - parseInt(currentBlock))
    }, [data.TurtleTreasure.getLotteryEndBlock]);

    const [userApprovedShlToLottery, setUserApprovedShlToLottery] = useState(0);
    function approveShlToLottery() {
        let approveAmountStaking = BigInt(1e+30);
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.shlSmartContract.methods.approve(CONFIG.CONTRACT_ADDRESS, approveAmountStaking)
            .send({
                to: CONFIG.SHL_CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once('error', (err) => {
                console.log(err);
            })
            .then((receipt) => {
                console.log(receipt)
            });
    }

    useEffect(() => {
        setUserApprovedShlToLottery(parseInt(BigInt(data.TurtleTreasure.approvedShlToTurtleTreasure) / BigInt(1e+18)));
        console.log(userApprovedShlToLottery);
    }, [data.TurtleTreasure.approvedShlToTurtleTreasure]);

    //const [potNumberFirebase, setPotNumberFirebase] = useState(0)
    //async function getPriceDataDB() {
    //    let numberFirebase = await get(ref(db, '/' + 'TreasurePotSize' + '/' + 'hex'));
    //    numberFirebase = numberFirebase["_node"]["value_"];
    //    numberFirebase = BigInt(numberFirebase);
    //    setPotNumberFirebase(numberFirebase);
    //}
    //useEffect(() => { getPriceDataDB(); }, []);

    return (
        <div>
            <BackgroundSvg className='absolute top-0 left-0 z-0' />
            <section className='border-[6px] border-blue-300 sm:text-lg md:text-xl
            bg-white absolute z-2 rounded-2xl shadow-lg
            left-[2.5%] w-[95%] sm:left-[5%] sm:w-[90%] md:left-[10%] md:w-[80%] lg:left-[15%] lg:w-[70%] xl:left-[20%] xl:w-[60%] 2xl:left-[25%] 2xl:w-[50%] max-w-[1000px]
            top-[90px]
            xs:p-1 p-2 sm:p-3 md:p-4 lg:p-6 xl:p-8'>
                
                    <div className='mx-auto text-center'>
                        {/*
                        <div className='mb-7'>
                            {recentWinner === "0x0000000000000000000000000000000000000000" ? (
                                <div>
                                    <p>The last rounds winner was: No winner yet</p>
                                    <p>Last rounds treasure: No treasure yet</p>
                                </div>
                            ) : (
                                <div>
                                    <p>The last rounds winner was: {recentWinner}</p>
                                    <p>Last rounds treasure: {recentWinPot}</p>
                                </div>
                            )}
                        </div>
                        */}

                        <div className='mx-auto text-center'>
                            <p>Approved SHL: {(Math.round(parseInt(BigInt(data.TurtleTreasure.approvedShlToTurtleTreasure) / BigInt(1e+18)))).toLocaleString()}</p>
                            <p>Ticket cost: {(50000).toLocaleString()} SHL</p>
                            <div className='mx-auto p-1 w-fit border-2 border-green-200 rounded-lg'>
                                <input id='ticketAmountInput' className='px-2 bg-green-100 rounded-md' placeholder='Amount of entries' type="text" />
                                <button onClick={approveShlToLottery} className='ml-1 px-2 rounded-md w-auto h-full bg-blue-300'>
                                    Approve SHL
                                </button>
                                <button onClick={buyTickets} className='ml-1 px-2 rounded-md w-auto h-full bg-green-300'>
                                    🎟️ Buy
                                </button>
                            </div>
                        </div>

                        <div className='my-7 mx-auto text-center'>
                            <p>Current treasure:</p>
                            {parseInt(currentPot) >= 0 ? (
                                <div>
                                    <p className='font-bold text-xl sm:text-2xl md:text-3xl text-green-600'>{parseInt(BigInt(currentPot)/BigInt(1e+18)).toLocaleString()} SHL</p>
                                    <p>{(parseInt(BigInt(currentPot)/BigInt(1e+18))*0.95).toLocaleString()} (-5% combined fees)</p>
                                    <p className='mb-1'>{(parseInt(BigInt(currentPot)/BigInt(1e+18))*0.04).toLocaleString()} SHL will get burned</p>
                                    <p>Current amount of unique players: {uniquePlayerCount}</p>
                                    <p>Current amount of tickets: {data.TurtleTreasure.getPlayers.length}</p>
                                    <p>Your chance of winning this round: {((playerTicketsBought)/(data.TurtleTreasure.getPlayers.length)*100).toFixed(2)}%</p>
                                </div>
                            ) : (
                                <div>
                                    {/*
                                    <p className='font-bold text-xl sm:text-2xl md:text-3xl text-green-600'>{parseInt(BigInt(potNumberFirebase)/BigInt(1e18)).toLocaleString()} SHL</p>
                                    <p>{(parseInt(BigInt(potNumberFirebase)/BigInt(1e18))*0.95).toLocaleString()} (-5% combined fees)</p>
                                    */}
                                </div>
                            )}
                        </div>

                        <div className='mx-auto text-center'>
                            {projectedEndLottery > 1 ? (
                                <div>
                                    <div className='relative'>
                                        <button className='hover:cursor-default disabled ml-1 px-2 rounded-md w-auto h-8 bg-green-300'>
                                            <ClipLoader className='mr-[3px]' size={16} color="#007BFF" />🏆 Pick Winner
                                        </button>
                                        <div className="absolute inset-0 pointer-events-none">
                                            <span className="flex items-center justify-center rounded-md text-sm font-bold bg-[rgba(255,255,255,0.6)] w-full h-full">🔒</span>
                                        </div>
                                    </div>
                                    <p>There needs to be atleast 2 tickets purchased to end the game.</p>
                                </div>
                            ) : (
                                (data.TurtleTreasure.getPlayers.length) > 0 && currentBlock > getLotteryEndBlock ? (
                                <div>
                                    <p><p className='mb-1'>Click to claim: {(parseInt(BigInt(currentPot)/BigInt(1e+18))*0.01).toLocaleString()} SHL</p></p>
                                    <button onClick={drawWinner} className='animate-pulse mb-1 hover:brightness-110 ml-1 px-2 rounded-md w-auto h-8 bg-green-300'>
                                    🏆 Pick Winner
                                    </button>
                                </div>
                                ) : (
                                <div>
                                    <div className='relative'>
                                        <button className='hover:cursor-default disabled ml-1 px-2 rounded-md w-auto h-8 bg-green-300'>
                                            <ClipLoader className='mr-[3px]' size={16} color="#007BFF" />🏆 Pick Winner
                                        </button>
                                        <div className="absolute inset-0 pointer-events-none">
                                            <span className="flex items-center justify-center rounded-md text-sm font-bold bg-[rgba(255,255,255,0.6)] w-full h-full">🔒</span>
                                        </div>
                                    </div>
                                </div>
                                )
                            )}
                            <p className='max-w-[800px] mx-auto'>If the button above is clickable then you are able to end the game. Everyone is able to end the game even if you yourself aren't participating. To incentivise people to draw the winner you will get 1% of the total treasure if you are the first to end the game!</p>
                        </div>
                        
                        <div className='mt-7'>
                            {blockchain.account !== '' && blockchain.account !== undefined && blockchain.smartContract !== null ? (
                                <ProgressBar />
                            ) : (
                                <></>
                            )}
                            </div>
                    </div>
            </section>
        </div>
    );
}

export default TurtleTreasurePage;