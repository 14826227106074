import elementsSheet from '../assets/images/elementSheet.png'
import AttackIcon from '../assets/AttackIcon'
import DefendIcon from '../assets/DefenseIcon'
import HealIcon from '../assets/HealingIcon'

import GrassHome from '../assets/images/grassHome.png'
import TabSwitcher from './TabSwitcher'

const BrawlGameRules = () => {
    return (
        <div className='py-2 sm:py-4 md:py-6' style={{ backgroundImage: `url(${GrassHome})` }}>
            <div className='border-[6px] border-[#779B49] shadow-2xl rounded-3xl bg-white max-w-[800px] mx-auto'>

                <TabSwitcher />
                <div className='px-2 sm:px-4 md:px-6 lg:px-8'>
                    <div className='my-3 sm:my-5 md:my-7'>
                        <h2 className='xs:text-lg text-xl font-bold'>Brawls</h2>
                        <p className='xs:text-md text-lg'>
                            Get ready for epic Tubby Turtle brawls!
                            You have two options: join an existing brawl or start your own.
                            When you create a brawl, you can decide how much SHL to wager on the outcome.
                            If you win, you'll receive 95% of the wagered amount.
                            However, if your opponent wins, they'll take home 95% of the wagered amount.
                            Choose your battles wisely!
                        </p>
                    </div>

                    <div className='my-3 sm:my-5 md:my-7'>
                        <h2 className='xs:text-lg text-xl font-bold'>Brawl moves</h2>
                        <p className='xs:text-md text-lg'>
                            There are 3 types of moves:
                            <ul className='border-gray-300 border-2 rounded-xl'>
                                <li className='border-b-2 border-gray-200' style={{ display: 'flex', alignItems: 'center' }}>
                                    <p className='min-w-[94px] text-[#184084] flex justify-center'>
                                        <span className='mr-1'>
                                            <AttackIcon size={24} />
                                        </span>
                                        <span>
                                            Attack:
                                        </span>
                                    </p>
                                    <span className='px-1 border-l-2 border-gray-200'>
                                        You can use your turtle's elemental powers to attack the opposing turtle.
                                        The attack's effectiveness varies, influenced by factors such as the element type of your turtle,
                                        your opponent's turtle,
                                        and your turtle's rarity.
                                    </span>
                                </li>
                                <li className='border-b-2 border-gray-200' style={{ display: 'flex', alignItems: 'center' }}>
                                    <p className='min-w-[94px] text-[#862525] flex justify-center'>
                                        <span className='mr-1'>
                                            <DefendIcon size={24} />
                                        </span>
                                        <span>
                                            Defend:
                                        </span>
                                    </p>
                                    <span className='px-1 border-l-2 border-gray-200'>
                                        Shield your turtle from half of the incoming damage caused by the enemy turtle's attack.
                                    </span>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <p className='min-w-[94px] text-[#209B50] flex justify-center'>
                                        <span className='mr-1'>
                                            <HealIcon size={24} />
                                        </span>
                                        <span>
                                            Heal:
                                        </span>
                                    </p>
                                    <span className='px-1 border-l-2 border-gray-200'>
                                        Restore your turtle's health points. The amount of healing depends on your turtle's rarity.
                                    </span>
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className='my-3 sm:my-5 md:my-7'>
                        <h2 className='xs:text-lg text-xl font-bold'>Turtle element advantage chart</h2>
                        <p className='xs:text-md text-lg'>
                            Below a handy chart displaying element matchups and their damage boosts during brawls.
                            For instance, if a fire turtle brawls against a nature turtle,
                            the chart will show a boost in damage for the fire element,
                            indicated by the red square.
                        </p>
                        <img className='w-[70%] sm:w-[60%] md:w-[50%] lg:w-[45%]' src={elementsSheet} />
                    </div>

                    <div className='my-3 sm:my-5 md:my-7'>
                        <h2 className='xs:text-lg text-xl font-bold'>Turtle rarity advantage</h2>
                        <p className='xs:text-md text-lg'>
                            Turtles with higher rarity also have a slightly higher chance to deal more damage and heal more.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrawlGameRules;