const HealingIcon = ({ size }) => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 26.458 26.458"
        >
            <g
                fill="#209b50"
                fillOpacity="1"
                stroke="#000"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="11.4"
                strokeOpacity="1"
                strokeWidth="0.794"
            >
                <path d="M8.599 20.647v3.869h3.868v-3.869h3.868V16.78h-3.868v-3.868H8.599v3.868H4.73v3.868z"></path>
                <path d="M18.021 11.612v3.326h3.327v-3.326h3.326V8.286h-3.326V4.96H18.02v3.326h-3.326v3.326z"></path>
                <path d="M5.039 7.65v2.784h2.784V7.65h2.784V4.866H7.823V2.082H5.039v2.784H2.255V7.65z"></path>
            </g>
        </svg>
    );
}

export default HealingIcon;