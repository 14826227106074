import { Link } from 'react-router-dom'
import walleticon from './assets/images/walleticon.png'
import { connect } from './redux/blockchain/blockchainActions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from './redux/data/dataActions'
import { useEffect, useState } from 'react'

const Header = () => {
    /* global BigInt */
    const [isConnected, setIsConnected] = useState(false);

    const dispatch = useDispatch();
    // blockchain contains the connection state of the blockchain
    const blockchain = useSelector((state) => state.blockchain);
    // data contains various variables such as: turtles a user owns/has staked, amount of approved SHL
    const data = useSelector((state) => state.data);

    // Get and update the data variables on every change of connected address
    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    function formatNumber(num) {
        if (num >= 1000 && num < 1000000) { return (num / 1000).toFixed(0) + 'K'; } else if (num >= 1000000 && num < 1000000000) { return (num / 1000000).toFixed(1) + 'M'; } else if (num >= 1000000000 && num < 1000000000000) { return (num / 1000000000).toFixed(2) + 'T'; } else { return num.toString(); }
    }

    const [userBalanceComma, setUserBalanceComma] = useState("?");
    const [userBalanceLetter, setUserBalanceLetter] = useState("?");

    useEffect(() => {
        if (data.userBalance !== null && data.userBalance !== 0) {
            let transformedNum = ((data.userBalance).slice(0, -18)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setUserBalanceComma(transformedNum);

            setUserBalanceLetter(formatNumber(parseInt((data.userBalance).slice(0, -18))));
        }
    }, [data.loading])

    useEffect(() => {
        getData();
        if (blockchain.account !== "" && blockchain.account !== null) {
            dispatch(fetchData());
            setIsConnected(true);
        }
    }, [blockchain.account, dispatch]);
    // --------------------------------------

    const [numDisplayMode, setNumDisplayMode] = useState('Comma');
    function changeNumberDisplay() {
        if (numDisplayMode === 'Comma') { setNumDisplayMode('Letters'); }
        else { setNumDisplayMode('Comma'); }
    }

    return (
        <header id='HeaderID' className='fixed flex w-full h-[64px] z-[200]'>
            <div className='mt-2 ml-2 sm:ml-5 md:ml-8 lg:ml-12 xl:ml-16 flex absolute left-0 w-auto h-full'>
                <a className='border-2 border-black hover:brightness-110 shadow-md rounded-lg text-xl bg-white m-auto xs:h-[70%] h-[75%] text-center flex items-center 2xs:px-[2px] xs:px-1 px-2' href="https://tubbyturtles.com" rel='noreferrer'>
                    🐢 TubbyTurtles
                </a>
            </div>
            <div className='mt-2 flex absolute left-[45%] w-auto h-full'>
                <Link className='border-2 border-black hover:brightness-110 shadow-md rounded-lg text-xl bg-white m-auto xs:h-[70%] h-[75%] text-center flex items-center 2xs:px-[2px] xs:px-1 px-2' to='/'>
                    🎮 Home
                </Link>
            </div>
            <div className='mt-2 mr-2 sm:mr-5 md:mr-8 lg:mr-12 xl:mr-16 flex items-center absolute right-0 w-auto h-full'>
                <div className='border-2 border-black xs:h-[70%] h-[75%] shadow-md rounded-lg bg-white flex flex-row'>
                    {isConnected === false ? (
                        <div>
                            <button className='hover:brightness-110 h-full rounded-lg bg-green-400 2xs:px-[2px] xs:px-1 px-2 flex items-center text-center text-xl' onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                            }}>
                                <svg width="28px" height="28px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                </svg>
                                Connect
                            </button>
                        </div>
                    ) : (
                        <div onClick={changeNumberDisplay} className='cursor-pointer hover:bg-[rgb(210,241,255)] hover:rounded-lg flex flex-row'>
                            {numDisplayMode === 'Comma' ? (
                                <p className='2xs:px-[2px] xs:px-1 px-2 flex items-center text-center text-xl'>💰 {userBalanceComma}</p>
                            ) : (
                                <p className='2xs:px-[2px] xs:px-1 px-2 flex items-center text-center text-xl'>💰 {userBalanceLetter}</p>
                            )}
                            <img className='py-2 pr-1 h-full text-xl m-auto text-center flex items-center' src={walleticon} alt="" />
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;