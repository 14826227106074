import BoardLayout from './boardAssets/BoardLayout';
import { connect } from '../redux/blockchain/blockchainActions'
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../redux/data/dataActions'
import MetamaskAccountIcon from '../components/MetamaskAccountIcon';
import Fire from '../assets/images/elementsymbols/Fire.png';
import Water from '../assets/images/elementsymbols/Water.png';
import Poison from '../assets/images/elementsymbols/Poison.png';
import Electric from '../assets/images/elementsymbols/Electric.png';
import Frost from '../assets/images/elementsymbols/Frost.png';
import Rock from '../assets/images/elementsymbols/Rock.png';
import Nature from '../assets/images/elementsymbols/Nature.png';
import { dbTwo, dbThree } from '../firebase'
import { onValue, ref, get, update, startAt, equalTo, orderByKey } from 'firebase/database'
import { Link } from 'react-router-dom'
import { FaHeart } from "react-icons/fa";
import { Tooltip, TooltipReference, useTooltipState } from "reakit/Tooltip";

import { BiSolidTree as TreeIcon1 } from "react-icons/bi";
import { BiSolidTreeAlt as TreeIcon2 } from "react-icons/bi";
import { PiTreeEvergreenFill as TreeIcon3 } from "react-icons/pi"
import { BsTreeFill as TreeIcon4 } from "react-icons/bs";
import { FaTree as TreeIcon5 } from "react-icons/fa6";
import { FaTree as TreeIcon6 } from "react-icons/fa";

import AttackIcon from '../assets/AttackIcon'
import DefenseIcon from '../assets/DefenseIcon'
import HealingIcon from '../assets/HealingIcon'
import QuestionMark from '../assets/QuestionmarkIcon'

import AttackSound from '../assets/sounds/AttackSound.mp3'
import DefendSound from '../assets/sounds/DefendSound.mp3'
import HealSound from '../assets/sounds/HealSound.mp3'

import { GoUnmute, GoMute } from 'react-icons/go'
import { FiArrowLeft } from 'react-icons/fi'
import { TurleRarities, TurtleElements } from './TurtleData'

const GameBoard = ({ connectStatus, background }) => {
    const [currentToolTip, setCurrentToolTip] = useState('');

    /* global BigInt */
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: null
        },
        GAS_LIMIT: null
    });
    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);

    const [turtleSelectedMove, setTurtleSelectedMove] = useState('');
    const [turtleIsPerformingMove, setTurtleIsPerformingMove] = useState(false);

    const [healthTurtle1, setHealthTurtle1] = useState(1000);
    const [healthTurtle2, setHealthTurtle2] = useState(1000);
    const [player1, setPlayer1] = useState('');
    const [player2, setPlayer2] = useState('');
    const [wager, setWager] = useState(0);
    const [moves, setMoves] = useState([]);
    const [turtleId1, setTurtleId1] = useState('');
    const [turtleId2, setTurtleId2] = useState('');
    const [brawlsListFirebase, setBrawlsListFirebase] = useState(null);

    useEffect(() => {
        let currentBrawlId = window.location.pathname.slice(8);
        const dbRef = ref(dbTwo, `/${currentBrawlId}`);
        const unsubscribe = onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const brawlsObject = {};
                snapshot.forEach((childSnapshot) => {
                    const key = childSnapshot.key;
                    const value = childSnapshot.val();
                    brawlsObject[key] = value;
                });
                setPlayer1(brawlsObject['player1'])
                setPlayer2(brawlsObject['player2'])

                setTurtleId1(brawlsObject['turtleId1'])
                setTurtleId2(brawlsObject['turtleId2'])

                setWager(brawlsObject['wagerAmount'])

                setMoves(brawlsObject['moves'])

                setHealthTurtle1(brawlsObject['turtleHealthPoints1'])
                setHealthTurtle2(brawlsObject['turtleHealthPoints2'])

                setBrawlsListFirebase(brawlsObject);
            } else {
                setBrawlsListFirebase({});
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    function getElementTurtleIcon(input) {
        if (input === 'Fire') { return Fire }
        if (input === 'Water') { return Water }
        if (input === 'Poison') { return Poison }
        if (input === 'Electric') { return Electric }
        if (input === 'Frost') { return Frost }
        if (input === 'Rock') { return Rock }
        if (input === 'Nature') { return Nature }
    }

    function getHealthBars(turtleHealth) {
        let amountOfBars = [];

        if (turtleHealth === 0) {
            return amountOfBars;
        } else {
            for (let i = 0; i < Math.round(turtleHealth / 50); i++) {
                amountOfBars.push('green');
            }
        }
        while (amountOfBars.length < 20) {
            amountOfBars.push('gray');
        }
        return amountOfBars;
    }

    function performSelectedMove(playerAddress, move) {
        setTurtleIsPerformingMove(true)

        let currentBrawlId = window.location.pathname.slice(8);
        const dataRef = ref(dbTwo, `/${currentBrawlId}`);

        try {
            let cost = 0;
            let totalCostWei = String(cost);
            blockchain.brawlSmartContract.methods.makeMove(currentBrawlId)
                .send({
                    to: CONFIG.BRAWL_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .then((receipt) => {
                    console.log(`Transaction receipt: ${receipt}`)
                    if (receipt["blockHash"] !== undefined && receipt["blockHash"] !== "") {


                        get(dataRef)
                            .then((snapshot) => {
                                if (snapshot.exists()) {
                                    const data = snapshot.val();
                                    if (data["player1"].toLowerCase() === (blockchain.account).toLowerCase()) {
                                        const updatedData = {
                                            currentTurtle1Move: move
                                        };
                                        update(dataRef, updatedData)
                                            .then(() => {
                                                console.log('Data updated successfully');
                                            })
                                            .catch((error) => {
                                                console.error('Error updating data:', error);
                                            });
                                    }
                                    else if (data["player2"].toLowerCase() === (blockchain.account).toLowerCase()) {
                                        const updatedData = {
                                            currentTurtle2Move: move
                                        };
                                        update(dataRef, updatedData)
                                            .then(() => {
                                                console.log('Data updated successfully');
                                            })
                                            .catch((error) => {
                                                console.error('Error updating data:', error);
                                            });
                                    }
                                } else {
                                    console.log("No data found for the given reference.");
                                }
                            })
                            .catch((error) => {
                                console.error("Error getting data:", error);
                            });


                        if (turtleSelectedMove === 'Attack') {
                            const turtlePlayer1 = document.getElementById('turtlePlayer1');
                            turtlePlayer1.classList.add('attack-animation');
                            const turtlePlayer1AttackIcon = document.getElementById('turtlePlayer1AttackIcon');
                            turtlePlayer1AttackIcon.style.marginTop = '8px';

                            //document.getElementById('AttackingIconText').textContent  = '-200'

                            const audio = new Audio(AttackSound);
                            if (muted === false) {
                                audio.play();
                            }

                            setTimeout(() => {
                                turtlePlayer1.classList.remove('attack-animation');
                                setTurtleSelectedMove('')
                                setTurtleIsPerformingMove(false)
                                document.getElementById('AttackingIconText').textContent = ''
                            }, 1500);
                        }

                        else if (turtleSelectedMove === 'Defend') {
                            document.getElementById('turtlePlayer1DefendIcon').classList.remove('animate-pulse');
                            document.getElementById('turtlePlayer1DefendIcon').classList.add('animate-bounce');

                            const audio = new Audio(DefendSound);
                            if (muted === false) {
                                audio.play();
                            }

                            setTimeout(() => {
                                setTurtleSelectedMove('')
                                setTurtleIsPerformingMove(false)
                            }, 4000);
                        }

                        else if (turtleSelectedMove === 'Heal') {
                            if (healthTurtle1 > 799) {
                                setHealthTurtle1(1000)
                            } else {
                                setHealthTurtle1(healthTurtle1 + 200)
                            }

                            document.getElementById('turtlePlayer1HealIcon').classList.remove('animate-pulse');
                            document.getElementById('turtlePlayer1HealIcon').classList.add('animate-ping');

                            //document.getElementById('HealingIconText').textContent  = '+200'

                            const audio = new Audio(HealSound);
                            if (muted === false) {
                                audio.play();
                            }

                            setTimeout(() => {
                                setTurtleSelectedMove('')
                                setTurtleIsPerformingMove(false)
                                document.getElementById('HealingIconText').textContent = ''
                            }, 2500);
                        }
                        else {
                            return
                        }

                    }
                })
                .catch(function (error) {
                    console.log('\x1b[31m%s\x1b[0m', 'Transaction interrupted or canceled', error);
                });
        } catch (error) {
            console.log('\x1b[31m%s\x1b[0m', 'Error:', error);
        }

    }

    const colors = {
        blue: '#184084',
        red: '#702424',
        green: '#209B50',
        lightBlue: '#607BAA',
        lightRed: '#9E6969',
        lightGreen: '#65BA86'
    }

    const [muted, setMuted] = useState(false);
    const toggleMute = () => {
        setMuted(!muted);
    };

    const [turtleName1, setTurtleName1] = useState(null);
    const [turtleName2, setTurtleName2] = useState(null);
    useEffect(() => {
        if (turtleId1 !== '') {
            const dataRef = ref(dbThree, `/${turtleId1}`);

            get(dataRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        setTurtleName1(data['name'])
                    }
                })
        }
        if (turtleId2 !== '') {
            const dataRef = ref(dbThree, `/${turtleId2}`);

            get(dataRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        setTurtleName2(data['name'])
                    }
                })
        }
    }, [turtleId1, turtleId2]);

    function getTurtleColor(element) {
        if (element === 'Fire') { return '#E64E5B'; }
        else if (element === 'Water') { return '#496BFF'; }
        else if (element === 'Poison') { return '#A566FF'; }
        else if (element === 'Electric') { return '#FFEA59'; }
        else if (element === 'Frost') { return '#8FEDEE'; }
        else if (element === 'Rock') { return '#FE993F'; }
        else if (element === 'Nature') { return '#9EFF74'; }
    }

    const treeIcons = [TreeIcon1, TreeIcon2, TreeIcon3, TreeIcon4, TreeIcon5, TreeIcon6];
    const greenColors = [
        "#008800", "#167516", "#237C43",
        "#009E00", "#31AF50", "#009900",
        "#006600", "#003300", "#006633", "#009966"
    ];


    const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const getRandomBoolean = () => Math.random() < 0.5;

    const iconElements = useMemo(() => {
        return Array.from({ length: 200 }).map((_, index) => {
            const RandomTreeIcon = treeIcons[getRandomNumber(0, treeIcons.length - 1)];
            const randomColor = greenColors[getRandomNumber(0, greenColors.length - 1)];
            const randomLeftOffset = `${getRandomNumber(-50, -15)}px`;
            const randomBottomOffset = `${getRandomNumber(-3, 100)}vh`;
            const randomSize = getRandomNumber(50, 125);
            const randomBoolean = getRandomBoolean();

            return (
                <>
                    {randomBoolean ? (
                        <div
                            key={index}
                            style={{ right: randomLeftOffset, bottom: randomBottomOffset }}
                            className='fixed flex items-center z-10'
                        >
                            <RandomTreeIcon style={{ color: randomColor }} size={randomSize} />
                        </div>
                    ) : (
                        <div
                            key={index}
                            style={{ left: randomLeftOffset, bottom: randomBottomOffset }}
                            className='fixed flex items-center z-10'
                        >
                            <RandomTreeIcon style={{ color: randomColor }} size={randomSize} />
                        </div>
                    )}
                </>
            );
        });
    }, []);

    const [resultMoves, setResultMoves] = useState([])
    useEffect(() => {
        let result = []
        for (const key in moves) {
            if (moves.hasOwnProperty(key)) {
                const movesValue = moves[key].moves;
                const player = movesValue.includes("Player1") ? "player1" : "player2";
                const move = movesValue.substring(7);
                const effect = moves[key].effect;
                result.push([player, move, effect]);
            }
        }
        while (result.length < 8) {
            result.push(["player0", "None", "None"]);
        }

        result.reverse();

        result = result.slice(0, 8);

        setResultMoves(result)
    }, [moves])

    console.log(resultMoves)

    return (
        blockchain.account !== null && blockchain.account !== '' && player1 !== '' && player2 !== '' ? (
            <div>
                <div>
                    {
                        currentToolTip === 'Attack' ? (
                            <div className='border-black border-2 text-xs bg-white rounded-md fixed z-[200] p-2 left-2 bottom-10 flex flex-col'>
                                <p>Will deal 180-220 *base* damage (if opponent attacks)</p>
                                <p>Will get dealt 5-15 *base* damage (if opponent defends)</p>
                                <p>Will deal 180-220 *base* damage (if opponent heals)</p>
                            </div>
                        ) : (
                            currentToolTip === 'Defend' ? (
                                <div className='border-black border-2 text-xs bg-white rounded-md fixed z-[200] p-2 left-2 bottom-10 flex flex-col'>
                                    <p>Will deal 5-15 *base* damage (if opponent attacks)</p>
                                    <p>Will deal 5-15 *base* damage and get 5-15 *base* damage dealt (if opponent defends)</p>
                                    <p>Will do 0 *base* damage (if opponent heals)</p>
                                </div>
                            ) : (
                                currentToolTip === 'Heal' ? (
                                    <div className='border-black border-2 text-xs bg-white rounded-md fixed z-[200] p-2 left-2 bottom-10 flex flex-col'>
                                        <p>Will heal 80-120 *base* health points (if opponent attacks)</p>
                                        <p>Will heal 80-120 *base* health points (if opponent defends)</p>
                                        <p>Will heal 80-120 *base* health points (if opponent heals)</p>
                                    </div>
                                ) : (
                                    <></>
                                )
                            )
                        )
                    }
                </div>

                {/* end game overlay */}
                {healthTurtle1 === 0 || healthTurtle2 === 0 ? (
                    <div className="fixed top-0 left-0 z-[100] bg-[rgba(230,230,230,0.5)] w-screen h-screen flex items-center justify-center">
                        <div className="absolute rounded-3xl p-2 md:p-6 w-[90%] h-[70%] sm:w-[70%] sm:h-[40%] bg-[rgba(245,245,245,0.7)] top-[15%] left-[5%] sm:top-[30%] sm:left-[15%] flex items-center justify-center">
                            <div className='text-lg font-bold'>
                                {healthTurtle2 === 0 ? (
                                    <div>
                                        {player1.toLowerCase() === blockchain.account.toLowerCase() ? (
                                            <div>
                                                <p className='text-center'>You won!</p>
                                                <p className='text-center'>You won <span className='text-blue-400'>{wager*2}</span> SHL</p>
                                            </div>
                                        ) : (
                                            player2.toLowerCase() === blockchain.account.toLowerCase() ? (
                                                <p className='text-center'>You lost!</p>
                                            ) : (
                                                <p className='text-center'>This game ended!</p>
                                            )
                                        )}
                                        <p className='text-center'>{player1} Won</p>
                                        {player1.toLowerCase() !== blockchain.account.toLowerCase() && player2.toLowerCase() !== blockchain.account.toLowerCase() ? (
                                            <p className='text-center'>Player 1 won <span className='text-blue-400'>{wager*2}</span> SHL</p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {player2.toLowerCase() === blockchain.account.toLowerCase() ? (
                                            <div>
                                                <p className='text-center'>You won!</p>
                                                <p className='text-center'>You won <span className='text-blue-400'>{wager*2}</span> SHL</p>
                                            </div>
                                        ) : (
                                            player1.toLowerCase() === blockchain.account.toLowerCase() ? (
                                                <p className='text-center'>You lost!</p>
                                            ) : (
                                                <p className='text-center'>This game ended!</p>
                                            )
                                        )}
                                        <p className='text-center'>{player2} Won</p>
                                        {player1.toLowerCase() !== blockchain.account.toLowerCase() && player2.toLowerCase() !== blockchain.account.toLowerCase() ? (
                                            <p className='text-center'>Player 2 won <span className='text-blue-400'>{wager*2}</span> SHL</p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {/* end game overlay */}


                <div className="fixed left-2 h-screen flex flex-col justify-center z-[15]">
                    <div className='border-2 border-black bg-white rounded-lg p-1 h-fit'>
                        {resultMoves.map((moveElem) => (
                            moveElem[0] === 'player0' ? (
                                <div className='hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[4px] mx-[2px] bg-gray-300 border-4 border-gray-600 grayscale-[0%] w-[50px] h-[50px]'>
                                    <div className='w-full h-full bg-gray-200'></div>
                                </div>
                            ) : (
                                moveElem[0] === 'player1' ? (
                                    (blockchain.account).toLowerCase() === player1.toLowerCase() ? (
                                        moveElem[1] === 'Attack' ? (

                                            <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-green-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#184084] absolute rounded-lg font-bold border-4 border-green-600'>{moveElem[2]}</p>
                                                <div className='w-full h-full bg-gray-200'><AttackIcon /></div>
                                            </div>

                                        ) : (
                                            moveElem[1] === 'Defend' ? (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-green-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#862525] absolute rounded-lg font-bold border-4 border-green-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><DefenseIcon /></div>
                                                </div>
                                            ) : (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-green-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#209B50] absolute rounded-lg font-bold border-4 border-green-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><HealingIcon /></div>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        moveElem[1] === 'Attack' ? (
                                            <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-red-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#184084] absolute rounded-lg font-bold border-4 border-red-600'>{moveElem[2]}</p>
                                                <div className='w-full h-full bg-gray-200'><AttackIcon /></div>
                                            </div>
                                        ) : (
                                            moveElem[1] === 'Defend' ? (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-red-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#862525] absolute rounded-lg font-bold border-4 border-red-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><DefenseIcon /></div>
                                                </div>
                                            ) : (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-red-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#209B50] absolute rounded-lg font-bold border-4 border-red-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><HealingIcon /></div>
                                                </div>
                                            )
                                        )
                                    )
                                ) : (
                                    (blockchain.account).toLowerCase() === player2.toLowerCase() ? (
                                        moveElem[1] === 'Attack' ? (
                                            <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-green-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#184084] absolute rounded-lg font-bold border-4 border-green-600'>{moveElem[2]}</p>
                                                <div className='w-full h-full bg-gray-200'><AttackIcon /></div>
                                            </div>
                                        ) : (
                                            moveElem[1] === 'Defend' ? (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-green-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#862525] absolute rounded-lg font-bold border-4 border-green-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><DefenseIcon /></div>
                                                </div>
                                            ) : (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-green-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#209B50] absolute rounded-lg font-bold border-4 border-green-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><HealingIcon /></div>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        moveElem[1] === 'Attack' ? (
                                            <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-red-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#184084] absolute rounded-lg font-bold border-4 border-red-600'>{moveElem[2]}</p>
                                                <div className='w-full h-full bg-gray-200'><AttackIcon /></div>
                                            </div>
                                        ) : (
                                            moveElem[1] === 'Defend' ? (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-red-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#862525] absolute rounded-lg font-bold border-4 border-red-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><DefenseIcon /></div>
                                                </div>
                                            ) : (
                                                <div className='flex justify-center items-end hover:scale-[1.05] hover:brightness-110 transition-all rounded-md my-[10px] mx-[2px] border-4 border-red-600 grayscale-[0%] w-[50px] h-[50px]'>
                                                    <p className='leading-[16px] text-[12px] translate-y-[10px] bg-white px-[3px] text-[#209B50] absolute rounded-lg font-bold border-4 border-red-600'>{moveElem[2]}</p>
                                                    <div className='w-full h-full bg-gray-200'><HealingIcon /></div>
                                                </div>
                                            )
                                        )
                                    )
                                ))
                        ))}
                    </div>
                </div>


                <div className="z-[20] fixed right-2 h-screen flex flex-col justify-center">
                    {turtleSelectedMove !== '' && (brawlsListFirebase.currentTurtle1Move === '' || brawlsListFirebase.currentTurtle2Move === '') ? (
                        <button onClick={() => performSelectedMove('0x748923', turtleSelectedMove)} style={{
                            backgroundColor: turtleSelectedMove === 'Attack' ? colors['lightBlue'] : turtleSelectedMove === 'Defend' ? colors['lightRed'] : turtleSelectedMove === 'Heal' ? colors['lightGreen'] : '#FFFFFF',
                            borderColor: turtleSelectedMove === 'Attack' ? colors['blue'] : turtleSelectedMove === 'Defend' ? colors['red'] : turtleSelectedMove === 'Heal' ? colors['green'] : '#000000'
                        }}
                            className='hover:brightness-[1.15] s:pl-1 pl-2 pr-1 border-4 border-black rounded-2xl p-1 h-fit flex flex-row'>
                            <div className='s:hidden text-xl flex my-auto font-bold'>Lock in move</div>
                            <div className='brightness-100 rounded-md m-[2px] grayscale-[0%] w-[50px] h-[50px]'>
                                {turtleSelectedMove === 'Attack' && (<AttackIcon />)}
                                {turtleSelectedMove === 'Defend' && (<DefenseIcon />)}
                                {turtleSelectedMove === 'Heal' && (<HealingIcon />)}
                                {turtleSelectedMove === '' && (<QuestionMark />)}
                            </div>
                        </button>
                    ) : (
                        turtleIsPerformingMove === false && (player1.toLowerCase() === blockchain.account.toLowerCase() || player2.toLowerCase() === blockchain.account.toLowerCase()) ? (
                            <button style={{
                                backgroundColor: turtleSelectedMove === 'Attack' ? colors['lightBlue'] : turtleSelectedMove === 'Defend' ? colors['lightRed'] : turtleSelectedMove === 'Heal' ? colors['lightGreen'] : '#949494'
                            }}
                                disabled
                                className='grayscale s:pl-1 pl-2 pr-1 border-4 border-black rounded-2xl p-1 h-fit flex flex-row'>
                                <div className='s:hidden text-xl flex my-auto font-bold'>Move is locked in</div>
                                <div className='brightness-100 rounded-md m-[2px] grayscale-[0%] w-[50px] h-[50px]'>
                                    <QuestionMark />
                                </div>
                            </button>
                        ) : (
                            <button style={{
                                backgroundColor: turtleSelectedMove === 'Attack' ? colors['lightBlue'] : turtleSelectedMove === 'Defend' ? colors['lightRed'] : turtleSelectedMove === 'Heal' ? colors['lightGreen'] : '#949494'
                            }}
                                disabled
                                className='grayscale s:pl-1 pl-2 pr-1 border-4 border-black rounded-2xl p-1 h-fit flex flex-row'>
                                <div className='s:hidden text-xl flex my-auto font-bold'>Move is locked in</div>
                                <div className='brightness-100 rounded-md m-[2px] grayscale-[0%] w-[50px] h-[50px]'>
                                    <QuestionMark />
                                </div>
                            </button>
                        )
                    )}
                </div>

                {brawlsListFirebase["player1"].toLowerCase() === blockchain.account.toLowerCase() ? (
                    <div>
                        <div className='fixed bottom-[7.5%] w-screen flex flex-col items-center justify-center'>
                            <div className={`${brawlsListFirebase["turtle1HasSetMove"] === false ? 'animate-pulse rounded-full border-[6px]' : ''} `}>
                                <div className='border-[6px] border-green-600 w-fit rounded-full'>
                                    {brawlsListFirebase["player1"].toLowerCase() === blockchain.account.toLowerCase() ? (
                                        <MetamaskAccountIcon address={blockchain.account} size={80} />
                                    ) : (
                                        <div className='w-[80px] h-[80px] bg-gray-200 rounded-full'></div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='fixed top-[7.5%] w-screen flex flex-col items-center justify-center'>
                            <div className={`${brawlsListFirebase["turtle2HasSetMove"] === false ? 'rounded-full border-[6px]' : ''} `}>
                                <div className='border-[6px] border-red-600 w-fit rounded-full'>
                                    {brawlsListFirebase["player1"].toLowerCase() !== blockchain.account.toLowerCase() && brawlsListFirebase["player2"].toLowerCase() !== blockchain.account.toLowerCase() ? (
                                        <div className='w-[80px] h-[80px] bg-gray-200 rounded-full'></div>
                                    ) : (
                                        brawlsListFirebase["player1"].toLowerCase() === blockchain.account ? (
                                            <MetamaskAccountIcon address={brawlsListFirebase["player2"]} size={80} />
                                        ) : (
                                            <MetamaskAccountIcon address={brawlsListFirebase["player1"]} size={80} />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='fixed bottom-[7.5%] w-screen flex flex-col items-center justify-center'>
                            <div className={`${brawlsListFirebase["turtle2HasSetMove"] === false ? 'animate-pulse rounded-full border-[6px]' : ''} `}>
                                <div className='border-[6px] border-green-600 w-fit rounded-full'>
                                    {brawlsListFirebase["player2"].toLowerCase() === blockchain.account.toLowerCase() || brawlsListFirebase["player2"].toLowerCase() === blockchain.account.toLowerCase() ? (
                                        <MetamaskAccountIcon address={blockchain.account} size={80} />
                                    ) : (
                                        <div className='w-[80px] h-[80px] bg-gray-200 rounded-full'></div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='fixed top-[7.5%] w-screen flex flex-col items-center justify-center'>
                            <div className={`${brawlsListFirebase["turtle1HasSetMove"] === false ? 'rounded-full border-[6px]' : ''} `}>
                                <div className='border-[6px] border-red-600 w-fit rounded-full'>
                                    {brawlsListFirebase["player1"].toLowerCase() !== blockchain.account.toLowerCase() && brawlsListFirebase["player2"].toLowerCase() !== blockchain.account.toLowerCase() ? (
                                        <div className='w-[80px] h-[80px] bg-gray-200 rounded-full'></div>
                                    ) : (
                                        brawlsListFirebase["player1"].toLowerCase() === blockchain.account ? (
                                            <MetamaskAccountIcon address={brawlsListFirebase["player2"]} size={80} />
                                        ) : (
                                            <MetamaskAccountIcon address={brawlsListFirebase["player1"]} size={80} />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Cards start */}
                <div className='absolute w-screen h-screen flex flex-col items-center justify-center'>
                    {brawlsListFirebase !== null && (player1.toLowerCase() === blockchain.account.toLowerCase() || player2.toLowerCase() === blockchain.account.toLowerCase()) ? (
                        <div className='flex flex-col gap-[36px] translate-y-[36px]'>
                            <div className='z-[5] flex flex-col items-center'>
                                <div id='turtlePlayer2'>
                                    <div className='nftcard hover:scale-[1.05] transition-all w-[140px] 2xl:w-[150px] 3xl:w-[160px] 4xl:w-[170px] bg-white rounded-xl mb-3'>
                                        {player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <img className='border-[5px] bg-white elementicon absolute w-[3.0em] -translate-x-4 -translate-y-4 rounded-full' src={getElementTurtleIcon(TurtleElements[brawlsListFirebase['turtleId2'] - 1])} alt="" />
                                        ) : (
                                            player2.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                                <img className='border-[5px] bg-white elementicon absolute w-[3.0em] -translate-x-4 -translate-y-4 rounded-full' src={getElementTurtleIcon(TurtleElements[brawlsListFirebase['turtleId1'] - 1])} alt="" />
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <div className='relative'>
                                                <div className='absolute -translate-y-3 translate-x-3 w-full flex'>
                                                    <div className='h-[40px] w-[40px] ml-auto mr-0 bg-white rounded-xl p-[4px]'><FaHeart size={32} style={{ color: getTurtleColor(brawlsListFirebase['turtleElement2']) }} />
                                                        <div className='font-bold text-center text-[0.68em] -translate-y-[26px]'>{brawlsListFirebase['turtleHealthPoints2']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='relative'>
                                                <div className='absolute -translate-y-3 translate-x-3 w-full flex'>
                                                    <div className='h-[40px] w-[40px] ml-auto mr-0 bg-white rounded-xl p-[4px]'><FaHeart size={32} style={{ color: getTurtleColor(brawlsListFirebase['turtleElement1']) }} />
                                                        <div className='font-bold text-center text-[0.68em] -translate-y-[26px]'>{brawlsListFirebase['turtleHealthPoints1']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <img className='pt-[5px] nftimg w-[calc(100%-10px)] h-auto rounded-xl flex mx-auto' src={`https://storage.googleapis.com/turtlestubby/${brawlsListFirebase["turtleId2"]}.png`} alt="" />
                                        ) : (
                                            player2.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                                <img className='pt-[5px] nftimg w-[calc(100%-10px)] h-auto rounded-xl flex mx-auto' src={`https://storage.googleapis.com/turtlestubby/${brawlsListFirebase["turtleId1"]}.png`} alt="" />
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        <div className='flex flex-col mb-1'>
                                            <div className='nftinfocardtext py-1'>
                                                {player2.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                                    <h2 className='mt-[2px] text-[rgb(0,0,0)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[0.9em]'>{turtleName1}</h2>
                                                ) : (<h2 className='mt-[2px] text-[rgb(0,0,0)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[0.9em]'>{turtleName2}</h2>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='hidden'>
                                    <button style={{
                                        borderColor: turtleSelectedMove === 'Attack' ? colors['blue'] : '#FFFFFF'
                                    }} onClick={() => { setTurtleSelectedMove('Attack'); setCurrentToolTip('Attack') }} className='border-box border-[4px] hover:brightness-110 bg-blue-100 rounded-xl mx-1 p-[2px]'>
                                        <AttackIcon size={40} />
                                    </button>
                                    <button style={{
                                        borderColor: turtleSelectedMove === 'Defend' ? colors['red'] : '#FFFFFF'
                                    }} onClick={() => { setTurtleSelectedMove('Defend'); setCurrentToolTip('Defend') }} className='border-box border-[4px] hover:brightness-110 bg-red-100 rounded-xl mx-1 p-[2px]'>
                                        <DefenseIcon size={40} />
                                    </button>
                                    <button style={{
                                        borderColor: turtleSelectedMove === 'Heal' ? colors['green'] : '#FFFFFF'
                                    }} onClick={() => { setTurtleSelectedMove('Heal'); setCurrentToolTip('Heal') }} className='border-box border-[4px] hover:brightness-110 bg-green-100 rounded-xl mx-1 p-[2px]'>
                                        <HealingIcon size={40} />
                                    </button>
                                </div>
                            </div>

                            <div className='z-[10] flex flex-col items-center'>
                                <div id='turtlePlayer1'>
                                    <div className='nftcard hover:scale-[1.05] transition-all w-[140px] 2xl:w-[150px] 3xl:w-[160px] 4xl:w-[170px] bg-white rounded-xl mb-3'>
                                        {player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <img className='border-[5px] bg-white elementicon absolute w-[3.0em] -translate-x-4 -translate-y-4 rounded-full' src={getElementTurtleIcon(TurtleElements[brawlsListFirebase['turtleId1'] - 1])} alt="" />
                                        ) : (
                                            player2.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                                <img className='border-[5px] bg-white elementicon absolute w-[3.0em] -translate-x-4 -translate-y-4 rounded-full' src={getElementTurtleIcon(TurtleElements[brawlsListFirebase['turtleId2'] - 1])} alt="" />
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {player2.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <div className='relative'>
                                                <div className='absolute -translate-y-3 translate-x-3 w-full flex'>
                                                    <div className='h-[40px] w-[40px] ml-auto mr-0 bg-white rounded-xl p-[4px]'><FaHeart size={32} style={{ color: getTurtleColor(brawlsListFirebase['turtleElement2']) }} />
                                                        <div className='font-bold text-center text-[0.68em] -translate-y-[26px]'>{brawlsListFirebase['turtleHealthPoints2']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='relative'>
                                                <div className='absolute -translate-y-3 translate-x-3 w-full flex'>
                                                    <div className='h-[40px] w-[40px] ml-auto mr-0 bg-white rounded-xl p-[4px]'><FaHeart size={32} style={{ color: getTurtleColor(brawlsListFirebase['turtleElement1']) }} />
                                                        <div className='font-bold text-center text-[0.68em] -translate-y-[26px]'>{brawlsListFirebase['turtleHealthPoints1']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {player2.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <img className='pt-[5px] nftimg w-[calc(100%-10px)] h-auto rounded-xl flex mx-auto' src={`https://storage.googleapis.com/turtlestubby/${brawlsListFirebase["turtleId2"]}.png`} alt="" />
                                        ) : (
                                            player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                                <img className='pt-[5px] nftimg w-[calc(100%-10px)] h-auto rounded-xl flex mx-auto' src={`https://storage.googleapis.com/turtlestubby/${brawlsListFirebase["turtleId1"]}.png`} alt="" />
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        <div className='flex flex-col mb-1'>
                                            <div className='nftinfocardtext py-1'>
                                                {player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                                    <h2 className='mt-[2px] text-[rgb(0,0,0)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[0.9em]'>{turtleName1}</h2>
                                                ) : (<h2 className='mt-[2px] text-[rgb(0,0,0)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[0.9em]'>{turtleName2}</h2>)}
                                            </div>
                                        </div>
                                    </div>
                                    {turtleSelectedMove === 'Attack' && (
                                        player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <>
                                                <div id='turtlePlayer1AttackIcon' className="animate-pulse h-[120px] w-[120px] mt-[230px] 2xl:mt-[250px] 3xl:w-mt-[260px] 4xl:mt-[270px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]">
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Fire' && (<img className='w-[40px] h-[40px]' src={Fire} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Water' && (<img className='w-full' src={Water} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Poison' && (<img className='w-full' src={Poison} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Electric' && (<img className='w-full' src={Electric} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Frost' && (<img className='w-full' src={Frost} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Rock' && (<img className='w-full' src={Rock} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Nature' && (<img className='w-full' src={Nature} alt='' />)}</div>
                                                <span style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                                                    className={`text-[${colors['blue']}] text-5xl font-bold absolute z-[50] w-full flex justify-center -translate-y-36`} id='AttackingIconText'>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <div id='turtlePlayer1AttackIcon' className="animate-pulse h-[120px] w-[120px] mt-[230px] 2xl:mt-[250px] 3xl:w-mt-[260px] 4xl:mt-[270px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]">
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Fire' && (<img className='w-[40px] h-[40px]' src={Fire} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Water' && (<img className='w-full' src={Water} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Poison' && (<img className='w-full' src={Poison} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Electric' && (<img className='w-full' src={Electric} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Frost' && (<img className='w-full' src={Frost} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Rock' && (<img className='w-full' src={Rock} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Nature' && (<img className='w-full' src={Nature} alt='' />)}</div>
                                                <span style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                                                    className={`text-[${colors['blue']}] text-5xl font-bold absolute z-[50] w-full flex justify-center -translate-y-36`} id='AttackingIconText'>
                                                </span>
                                            </>
                                        )
                                    )}
                                    {turtleSelectedMove === 'Defend' && (
                                        <div id='turtlePlayer1DefendIcon' className="animate-pulse h-[120px] w-[120px] mt-[240px] 2xl:mt-[260px] 3xl:w-mt-[270px] 4xl:mt-[280px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]"><DefenseIcon /></div>
                                    )}
                                    {turtleSelectedMove === 'Heal' && (
                                        <>
                                            <div id='turtlePlayer1HealIcon' className="animate-pulse h-[120px] w-[120px] mt-[230px] 2xl:mt-[250px] 3xl:w-mt-[260px] 4xl:mt-[270px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]">
                                                <HealingIcon />
                                            </div>
                                            <span style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                                                className={`text-[${colors['green']}] text-5xl font-bold absolute z-[50] w-full flex justify-center -translate-y-36 -translate-x-6`} id='HealingIconText'></span>
                                        </>
                                    )}
                                </div>
                                {turtleIsPerformingMove === false ? (
                                    <div className='flex flex-row'>


                                        <button
                                            style={{
                                                borderColor: turtleSelectedMove === 'Attack' ? colors['blue'] : '#FFFFFF'
                                            }} onClick={() => { setTurtleSelectedMove('Attack'); setCurrentToolTip('Attack') }} className='border-box border-[4px] hover:brightness-110 bg-blue-100 rounded-xl mx-1 p-[2px]'>
                                            <AttackIcon size={40} />
                                        </button>




                                        <audio id="MoveSound"></audio>


                                        <button

                                            style={{
                                                borderColor: turtleSelectedMove === 'Defend' ? colors['red'] : '#FFFFFF'
                                            }} onClick={() => { setTurtleSelectedMove('Defend'); setCurrentToolTip('Defend') }} className='border-box border-[4px] hover:brightness-110 bg-red-100 rounded-xl mx-1 p-[2px]'>
                                            <DefenseIcon size={40} />
                                        </button>




                                        <button

                                            style={{
                                                borderColor: turtleSelectedMove === 'Heal' ? colors['green'] : '#FFFFFF'
                                            }} onClick={() => { setTurtleSelectedMove('Heal'); setCurrentToolTip('Heal') }} className='border-box border-[4px] hover:brightness-110 bg-green-100 rounded-xl mx-1 p-[2px]'>
                                            <HealingIcon size={40} />
                                        </button>


                                    </div>
                                ) : (
                                    <div className='flex flex-row'>
                                        <button disabled className='grayscale border-box border-[4px] bg-blue-100 rounded-xl mx-1 p-[2px]'>
                                            <AttackIcon size={40} />
                                        </button>
                                        <button disabled className='grayscale border-box border-[4px] bg-red-100 rounded-xl mx-1 p-[2px]'>
                                            <DefenseIcon size={40} />
                                        </button>
                                        <button disabled className='grayscale border-box border-[4px] bg-green-100 rounded-xl mx-1 p-[2px]'>
                                            <HealingIcon size={40} />
                                        </button>
                                    </div>
                                )}
                            </div>




                        </div>




                    ) : (
                        /* SPECTATOR MODE */
                        <div className='flex flex-col gap-[52px] translate-y-[14px]'>
                            <div className='z-[5] flex flex-col items-center'>
                                <div id='turtlePlayer2'>
                                    <div className='nftcard hover:scale-[1.05] transition-all w-[140px] 2xl:w-[150px] 3xl:w-[160px] 4xl:w-[170px] bg-white rounded-xl mb-3'>

                                            <img className='border-[5px] bg-white elementicon absolute w-[3.0em] -translate-x-4 -translate-y-4 rounded-full' src={getElementTurtleIcon(TurtleElements[brawlsListFirebase['turtleId2'] - 1])} alt="" />


                                            <div className='relative'>
                                                <div className='absolute -translate-y-3 translate-x-3 w-full flex'>
                                                    <div className='h-[40px] w-[40px] ml-auto mr-0 bg-white rounded-xl p-[4px]'><FaHeart size={32} style={{ color: getTurtleColor(brawlsListFirebase['turtleElement2']) }} />
                                                        <div className='font-bold text-center text-[0.68em] -translate-y-[26px]'>{brawlsListFirebase['turtleHealthPoints2']}</div>
                                                    </div>
                                                </div>
                                            </div>


                                            <img className='pt-[5px] nftimg w-[calc(100%-10px)] h-auto rounded-xl flex mx-auto' src={`https://storage.googleapis.com/turtlestubby/${brawlsListFirebase["turtleId2"]}.png`} alt="" />

                                        <div className='flex flex-col mb-1'>
                                            <div className='nftinfocardtext py-1'>
                                                    <h2 className='mt-[2px] text-[rgb(0,0,0)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[0.9em]'>{turtleName2}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='z-[10] flex flex-col items-center'>
                                <div id='turtlePlayer1'>
                                    <div className='nftcard hover:scale-[1.05] transition-all w-[140px] 2xl:w-[150px] 3xl:w-[160px] 4xl:w-[170px] bg-white rounded-xl mb-3'>

                                            <img className='border-[5px] bg-white elementicon absolute w-[3.0em] -translate-x-4 -translate-y-4 rounded-full' src={getElementTurtleIcon(TurtleElements[brawlsListFirebase['turtleId1'] - 1])} alt="" />


                                            <div className='relative'>
                                                <div className='absolute -translate-y-3 translate-x-3 w-full flex'>
                                                    <div className='h-[40px] w-[40px] ml-auto mr-0 bg-white rounded-xl p-[4px]'><FaHeart size={32} style={{ color: getTurtleColor(brawlsListFirebase['turtleElement1']) }} />
                                                        <div className='font-bold text-center text-[0.68em] -translate-y-[26px]'>{brawlsListFirebase['turtleHealthPoints1']}</div>
                                                    </div>
                                                </div>
                                            </div>

                                                <img className='pt-[5px] nftimg w-[calc(100%-10px)] h-auto rounded-xl flex mx-auto' src={`https://storage.googleapis.com/turtlestubby/${brawlsListFirebase["turtleId1"]}.png`} alt="" />

                                        <div className='flex flex-col mb-1'>
                                            <div className='nftinfocardtext py-1'>

                                                    <h2 className='mt-[2px] text-[rgb(0,0,0)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[0.9em]'>{turtleName1}</h2>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    {turtleSelectedMove === 'Attack' && (
                                        player1.toLowerCase() === (blockchain.account).toLowerCase() ? (
                                            <>
                                                <div id='turtlePlayer1AttackIcon' className="animate-pulse h-[120px] w-[120px] mt-[230px] 2xl:mt-[250px] 3xl:w-mt-[260px] 4xl:mt-[270px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]">
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Fire' && (<img className='w-[40px] h-[40px]' src={Fire} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Water' && (<img className='w-full' src={Water} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Poison' && (<img className='w-full' src={Poison} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Electric' && (<img className='w-full' src={Electric} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Frost' && (<img className='w-full' src={Frost} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Rock' && (<img className='w-full' src={Rock} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId1'] - 1] === 'Nature' && (<img className='w-full' src={Nature} alt='' />)}</div>
                                                <span style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                                                    className={`text-[${colors['blue']}] text-5xl font-bold absolute z-[50] w-full flex justify-center -translate-y-36`} id='AttackingIconText'>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <div id='turtlePlayer1AttackIcon' className="animate-pulse h-[120px] w-[120px] mt-[230px] 2xl:mt-[250px] 3xl:w-mt-[260px] 4xl:mt-[270px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]">
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Fire' && (<img className='w-[40px] h-[40px]' src={Fire} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Water' && (<img className='w-full' src={Water} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Poison' && (<img className='w-full' src={Poison} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Electric' && (<img className='w-full' src={Electric} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Frost' && (<img className='w-full' src={Frost} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Rock' && (<img className='w-full' src={Rock} alt='' />)}
                                                    {TurtleElements[brawlsListFirebase['turtleId2'] - 1] === 'Nature' && (<img className='w-full' src={Nature} alt='' />)}</div>
                                                <span style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                                                    className={`text-[${colors['blue']}] text-5xl font-bold absolute z-[50] w-full flex justify-center -translate-y-36`} id='AttackingIconText'>
                                                </span>
                                            </>
                                        )
                                    )}
                                    {turtleSelectedMove === 'Defend' && (
                                        <div id='turtlePlayer1DefendIcon' className="animate-pulse h-[120px] w-[120px] mt-[240px] 2xl:mt-[260px] 3xl:w-mt-[270px] 4xl:mt-[280px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]"><DefenseIcon /></div>
                                    )}
                                    {turtleSelectedMove === 'Heal' && (
                                        <>
                                            <div id='turtlePlayer1HealIcon' className="animate-pulse h-[120px] w-[120px] mt-[230px] 2xl:mt-[250px] 3xl:w-mt-[260px] 4xl:mt-[270px] flex mx-auto absolute top-0 left-0 right-0 bottom-0 opacity-[0.8] z-[20]">
                                                <HealingIcon />
                                            </div>
                                            <span style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                                                className={`text-[${colors['green']}] text-5xl font-bold absolute z-[50] w-full flex justify-center -translate-y-36 -translate-x-6`} id='HealingIconText'></span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Cards end */}

                    {player1.toLowerCase() === blockchain.account.toLowerCase() ? (
                        <div className="fixed bottom-2 w-screen flex justify-center">
                            <div className='bg-white rounded-xl border-2 border-black p-1 w-fit flex flex-row justify-center'>
                                {healthTurtle1 === 0 ? (
                                    Array.from({ length: 20 }, (_, index) => (
                                        <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                    ))
                                ) : (
                                    getHealthBars(healthTurtle1).map((bar) => (
                                        bar === 'green' ? (
                                            <div className='animate-customPulseGreen rounded-md mx-[1px] bg-green-600 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        ) : (
                                            <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        )
                                    )))}
                            </div>
                        </div>
                    ) : (
                        <div className="fixed bottom-2 w-screen flex justify-center">
                            <div className='bg-white rounded-xl border-2 border-black p-1 w-fit flex flex-row justify-center'>
                                {healthTurtle2 === 0 ? (
                                    Array.from({ length: 20 }, (_, index) => (
                                        <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                    ))
                                ) : (
                                    getHealthBars(healthTurtle2).map((bar) => (
                                        bar === 'green' ? (
                                            <div className='animate-customPulseGreen rounded-md mx-[1px] bg-green-600 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        ) : (
                                            <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        )
                                    )))}
                            </div>
                        </div>
                    )}

                    {player2.toLowerCase() === blockchain.account.toLowerCase() ? (
                        <div className="fixed top-2 w-screen flex justify-center">
                            <div className='bg-white rounded-xl border-2 border-black p-1 w-fit flex flex-row justify-center'>
                                {healthTurtle1 === 0 ? (
                                    Array.from({ length: 20 }, (_, index) => (
                                        <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                    ))
                                ) : (
                                    getHealthBars(healthTurtle1).map((bar) => (
                                        bar === 'green' ? (
                                            <div className='animate-customPulseRed rounded-md mx-[1px] bg-red-600 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        ) : (
                                            <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        )
                                    )))
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="fixed top-2 w-screen flex justify-center">
                            <div className='bg-white rounded-xl border-2 border-black p-1 w-fit flex flex-row justify-center'>
                                {healthTurtle2 === 0 ? (
                                    Array.from({ length: 20 }, (_, index) => (
                                        <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                    ))
                                ) : (
                                    getHealthBars(healthTurtle2).map((bar) => (
                                        bar === 'green' ? (
                                            <div className='animate-customPulseRed rounded-md mx-[1px] bg-red-600 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        ) : (
                                            <div className='rounded-md mx-[1px] bg-gray-400 w-[8px] sm:w-[10px] md:w-[12px] h-[26px] sm:h-[30px] md:h-[38px]'></div>
                                        )
                                    )))
                                }
                            </div>
                        </div>
                    )}

                    <div>{iconElements}</div>


                    <div className="fixed top-2 left-2 flex z-[110]">
                        <Link to='/brawl' className='hover:bg-gray-300 bg-white border-2 border-black rounded-full p-1 md:p-2' onClick={toggleMute}>
                            <FiArrowLeft />
                        </Link>
                    </div>

                    <div className="fixed top-2 right-2 flex z-[110]">
                        <button className='hover:bg-gray-300 bg-white border-2 border-black rounded-full p-1 md:p-2' onClick={toggleMute}>
                            {muted ? (
                                <GoMute />
                            ) : (
                                <GoUnmute />
                            )}
                        </button>
                    </div>

                </div>


                <BoardLayout />
            </div>
        ) : (
            <div className='relative'>
                <button className='absolute top-[48vh] left-[45vw] font-bold hover:brightness-110 h-fit rounded-lg bg-green-400 2xs:px-[2px] xs:px-1 px-2 sm:px-3 xs:py-[2px] py-[3px] sm:py-[4px] flex items-center text-center text-xl' onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                }}>
                    <svg width="32px" height="32px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                        <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                    </svg>
                    Connect
                </button>
                <BoardLayout />
            </div>
        )
    );
};

export default GameBoard;
