import Lottery from './assets/images/lottery.png'
import Turtlebattlegame from './assets/images/turtlebattlegame.png'
import Moon from './assets/images/moon.png'
import Turtlegif from './assets/images/gif/turtlegif.gif'
import Dice from './assets/images/dice.png'
import Eggs from './assets/images/eggs.png'

import { Link } from 'react-router-dom'

import BackgroundSvg from './components/backgroundSvg'

const Home = () => {
    return (
        <div>
            <div className='absolute top-0 left-0 z-1'>
                <BackgroundSvg />
            </div>
            <section className='border-[6px] border-gray-200 bg-white absolute z-2 rounded-2xl shadow-2xl
                left-[2.5%] w-[95%] sm:left-[5%] sm:w-[90%] md:left-[10%] md:w-[80%] lg:left-[15%] lg:w-[70%] xl:left-[20%] xl:w-[60%] 2xl:left-[25%] 2xl:w-[50%] max-w-[1000px]
                top-[90px]'>
                <div className='px-6 sm:px-10 py-4'>
                    <h1 className='text-center text-lg sm:text-xl md:text-2xl font-bold'>Tubby Turtles Games Portal</h1>
                </div>
                <div className='flex flex-wrap justify-center'>
                    <Link to='/treasure' className='border-2 border-[rgb(60,60,60)] hover:scale-[110%] transition duration-300 ease-in-out
                    3xs:mx-[2vw] 3xs:w-[40vw] 2xs:w-[150px] w-[175px] sm:w-[200px] lg:w-[225px]
                    m-4 p-4 rounded-xl shadow-lg'>
                        <div className='-translate-y-1'>
                            <abbr title="Game is live">
                                <div id='blinkingicon' className='z-10 relative ml-auto w-3 h-3 rounded-full'></div>
                            </abbr>
                        </div>
                        <img className='border-blue-200 border-2 rounded-md block w-full h-auto 
                            transition duration-300 ease-in-out contrast-[0.8] hover:contrast-[1.1]'
                            src={Lottery} />
                        <h2 className='pt-1 font-bold text-center'>Title: <span className='font-normal'>Turtle Treasure</span></h2>
                        <h2 className='text-xs pt-1 font-bold text-center'>Description: <span className='font-normal'>Turtle Treasure is a lottery game where each ticket costs 50.000 SHL and the fee gets burned</span></h2>
                        <h2 className='text-xs font-bold text-center'>Fee: <span className='font-normal'>5%</span></h2>
                    </Link>
                    <Link to='/brawl' className='border-2 border-[rgb(60,60,60)] hover:scale-[110%] transition duration-300 ease-in-out
                    3xs:mx-[2vw] 3xs:w-[40vw] 2xs:w-[150px] w-[175px] sm:w-[200px] lg:w-[225px]
                    m-4 p-4 rounded-xl shadow-lg'>
                        <div className='-translate-y-1'>
                            <abbr title="Game is live yet">
                                <div id='blinkingicon' className='z-10 relative ml-auto w-3 h-3 rounded-full'></div>
                            </abbr>
                        </div>
                        <img className='border-[#779B49] rounded-md block w-full h-auto 
                            transition duration-300 ease-in-out'
                            src={Turtlebattlegame} />
                        <h2 className='pt-1 font-bold text-center'>Title: <span className='font-normal'>Turtle Brawlers</span></h2>
                        <h2 className='text-xs pt-1 font-bold text-center'>Description: <span className='font-normal'>Turtle Brawlers is a game where you battle your Tubby Turtle against others, wagering SHL for victory</span></h2>
                        <h2 className='text-xs font-bold text-center'>Fee: <span className='font-normal'>2%</span></h2>
                    </Link>
                    <div className='border-2 border-[rgb(60,60,60)] transition duration-300 ease-in-out
                    3xs:mx-[2vw] 3xs:w-[40vw] 2xs:w-[150px] w-[175px] sm:w-[200px] lg:w-[225px]
                    m-4 p-4 rounded-xl shadow-lg'>
                        <div className='-translate-y-1'>
                            <abbr title="Game is not live yet">
                                <div id='blinkingiconNotStarted' className='z-10 relative ml-auto w-3 h-3 rounded-full'></div>
                            </abbr>
                        </div>
                        <img className='rounded-md block w-full h-auto 
                            transition duration-300 ease-in-out blur-[3px] contrast-[0.3]'
                            src={Moon} />
                        <h2 className='pt-1 font-bold text-center'>Title: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs pt-1 font-bold text-center'>Description: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs font-bold text-center'>Fee: <span className='font-normal'>?</span></h2>
                    </div>
                    <div className='border-2 border-[rgb(60,60,60)] transition duration-300 ease-in-out
                    3xs:mx-[2vw] 3xs:w-[40vw] 2xs:w-[150px] w-[175px] sm:w-[200px] lg:w-[225px]
                    m-4 p-4 rounded-xl shadow-lg'>
                        <div className='-translate-y-1'>
                            <abbr title="Game is not live yet">
                                <div id='blinkingiconNotStarted' className='z-10 relative ml-auto w-3 h-3 rounded-full'></div>
                            </abbr>
                        </div>
                        <img className='rounded-md block w-full h-auto 
                            transition duration-300 ease-in-out blur-[3px] contrast-[0.3]'
                            src={Turtlegif} />
                        <h2 className='pt-1 font-bold text-center'>Title: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs pt-1 font-bold text-center'>Description: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs font-bold text-center'>Fee: <span className='font-normal'>?</span></h2>
                    </div>
                    <div className='border-2 border-[rgb(60,60,60)] transition duration-300 ease-in-out
                    3xs:mx-[2vw] 3xs:w-[40vw] 2xs:w-[150px] w-[175px] sm:w-[200px] lg:w-[225px]
                    m-4 p-4 rounded-xl shadow-lg'>
                        <div className='-translate-y-1'>
                            <abbr title="Game is not live yet">
                                <div id='blinkingiconNotStarted' className='z-10 relative ml-auto w-3 h-3 rounded-full'></div>
                            </abbr>
                        </div>
                        <img className='rounded-md block w-full h-auto 
                            transition duration-300 ease-in-out blur-[3px] contrast-[0.3]'
                            src={Dice} />
                        <h2 className='pt-1 font-bold text-center'>Title: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs pt-1 font-bold text-center'>Description: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs font-bold text-center'>Fee: <span className='font-normal'>?</span></h2>
                    </div>
                    <div className='border-2 border-[rgb(60,60,60)] transition duration-300 ease-in-out
                    3xs:mx-[2vw] 3xs:w-[40vw] 2xs:w-[150px] w-[175px] sm:w-[200px] lg:w-[225px]
                    m-4 p-4 rounded-xl shadow-lg'>
                        <div className='-translate-y-1'>
                            <abbr title="Game is not live yet">
                                <div id='blinkingiconNotStarted' className='z-10 relative ml-auto w-3 h-3 rounded-full'></div>
                            </abbr>
                        </div>
                        <img className='rounded-md block w-full h-auto 
                            transition duration-300 ease-in-out blur-[3px] contrast-[0.3]'
                            src={Eggs} />
                        <h2 className='pt-1 font-bold text-center'>Title: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs pt-1 font-bold text-center'>Description: <span className='font-normal'>?</span></h2>
                        <h2 className='text-xs font-bold text-center'>Fee: <span className='font-normal'>?</span></h2>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;