const AttackIcon = ({size}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 26.458 26.458"
        >
            <g>
                <path
                    fill="#862525ff"
                    fillOpacity="1"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="0.794"
                    d="M2.646 6.615c5.244.107 8.069-1.802 10.583-3.97 2.57 1.962 4.685 4.227 10.584 3.97-.023 7.393-2.44 13.847-10.584 17.197C4.863 20.68 2.637 14.348 2.646 6.616z"
                ></path>
            </g>
        </svg>
    );
}

export default AttackIcon;