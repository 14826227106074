import UnknownOponnent from '../assets/images/unknownOponnent.png'
import Fire from '../assets/images/elementsymbols/Fire.png';
import Water from '../assets/images/elementsymbols/Water.png';
import Poison from '../assets/images/elementsymbols/Poison.png';
import Electric from '../assets/images/elementsymbols/Electric.png';
import Frost from '../assets/images/elementsymbols/Frost.png';
import Rock from '../assets/images/elementsymbols/Rock.png';
import Nature from '../assets/images/elementsymbols/Nature.png';
import MetamaskAccountIcon from '../components/MetamaskAccountIcon'
import ShellCoin from '../assets/images/shellcoin.png'

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Web3 from 'web3'

import { TurtleRarities, TurtleElements } from './TurtleData';

const BrawlCard = ({ brawlInfo, turnedOff, noTurtleSelected, selectedTurtle }) => {
    const weiToEther = (weiInput) => {
        let ethBalance = Web3.utils.fromWei(weiInput, 'ether');
        return ethBalance;
    }
    
    const etherToWei = (etherInput) => {
        let ethBalance = Web3.utils.toWei(etherInput, 'ether');
        return ethBalance;
    }

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SHL_CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: null
        },
        GAS_LIMIT: null
    });
    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);

    const blockchain = useSelector((state) => state.blockchain);
    
    function getTokenIdOfTurtle(tokenId) {
        return TurtleElements[parseInt(tokenId-1)]
    }
    function joinBrawl(brawlId, turtleId) {
        try {
            let turtleElem = getTokenIdOfTurtle(turtleId)
            let cost = 0;
            let totalCostWei = String(cost);
            blockchain.brawlSmartContract.methods.joinBrawl(brawlId, turtleId, turtleElem)
                .send({
                    to: CONFIG.BRAWL_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .catch(function(error) {
                    console.log('\x1b[31m%s\x1b[0m', 'Transaction interrupted or canceled', error);
                });
        } catch (error) {
            console.log('\x1b[31m%s\x1b[0m', 'Error:', error);
        }
    }

    return (
        <div>
        {blockchain.account !== '' && blockchain.account !== null ? (

        <div className={`${(brawlInfo.player1.toLowerCase() === blockchain.account.toLowerCase()) || (brawlInfo.player2.toLowerCase() === blockchain.account.toLowerCase()) ? 'border-green-700 border-2 bg-green-100' : 'border-gray-700 border-2 bg-white'} rounded-xl mx-[10px] my-[5px] w-[220px] h-[200px]`}>
            {brawlInfo.ended === true ? (
                <div className='flex -translate-y-[2px] -translate-x-[2px] items-center justify-center absolute rounded-xl z-[50] w-[220px] h-[200px] bg-[rgba(240,240,240,0.7)]'>
                    <div className='bg-[rgba(250,250,250,0.9)] p-1 rounded-xl'>
                        <p>Game ended</p>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className='h-[106px] flex flex-row m-1 justify-around border-2 border-gray-700 rounded-lg'>
                
                <div className='w-full sm:w-[50%] relative'>
                <div className='absolute flex w-full z-[20]'>
                    {brawlInfo.turtleElement1 === 'Fire' && brawlInfo.player2 !== '' && (<img className='flex     2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Fire} />)}
                    {brawlInfo.turtleElement1 === 'Water' && brawlInfo.player2 !== '' && (<img className='flex    2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Water} />)}
                    {brawlInfo.turtleElement1 === 'Poison' && brawlInfo.player2 !== '' && (<img className='flex   2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Poison} />)}
                    {brawlInfo.turtleElement1 === 'Electric' && brawlInfo.player2 !== '' && (<img className='flex 2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Electric} />)}
                    {brawlInfo.turtleElement1 === 'Frost' && brawlInfo.player2 !== '' && (<img className='flex    2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Frost} />)}
                    {brawlInfo.turtleElement1 === 'Rock' && brawlInfo.player2 !== '' && (<img className='flex     2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Rock} />)}
                    {brawlInfo.turtleElement1 === 'Nature' && brawlInfo.player2 !== '' && (<img className='flex   2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Nature} />)}
                    {brawlInfo.turtleElement1 === '' || brawlInfo.player2 === '' && (<img className='flex         2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-0 mr-auto -translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={UnknownOponnent} />)}
                </div>
                {brawlInfo.turtleId1 !== 0 && brawlInfo.turtleId2 !== 0 ? (
                    <img className='rounded-l-md w-full' src={`https://storage.googleapis.com/turtlestubby/${brawlInfo.turtleId1}.png`} />
                    ) : (
                    <img className='rounded-l-md w-full' src={UnknownOponnent} />
                )}
                </div>
                <div className='w-full sm:w-[50%] relative'>
                <div className='absolute flex w-full z-[20]'>
                    {brawlInfo.turtleElement2 === 'Fire' && (<img className='flex     2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Fire} />)}
                    {brawlInfo.turtleElement2 === 'Water' && (<img className='flex    2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Water} />)}
                    {brawlInfo.turtleElement2 === 'Poison' && (<img className='flex   2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Poison} />)}
                    {brawlInfo.turtleElement2 === 'Electric' && (<img className='flex 2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Electric} />)}
                    {brawlInfo.turtleElement2 === 'Frost' && (<img className='flex    2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Frost} />)}
                    {brawlInfo.turtleElement2 === 'Rock' && (<img className='flex     2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Rock} />)}
                    {brawlInfo.turtleElement2 === 'Nature' && (<img className='flex   2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={Nature} />)}
                    {brawlInfo.turtleElement2 === '' && (<img className='flex         2xs:w-[20px] xs:w-[22px] w-[24px] sm:w-[26px] md:w-[28px] ml-auto mr-0 translate-x-1 -translate-y-1 border-2 border-gray-700 rounded-full' src={UnknownOponnent} />)}
                </div>
                {brawlInfo.turtleId2 !== 0 ? (
                    <img className='rounded-r-md w-full' src={`https://storage.googleapis.com/turtlestubby/${brawlInfo.turtleId2}.png`} />
                ) : (
                    <img className='rounded-r-md w-full' src={UnknownOponnent} />
                )}
                    <svg className="absolute inset-0 w-full h-full -translate-x-[48%]" version="1.1" viewBox="0 0 35.454 66.146" xmlns="http://www.w3.org/2000/svg">
                        <g strokeLinecap="butt" strokeLinejoin="miter">
                        <path d="M21.41 34.214l-.455-.032-.659-.094-.479-.133-.478-.188-.443-.29-.424-.38-.264-.356-.21-.416-.11-.32-.071-.315-.067-.596.04-.533.16-.58.212-.604.18-.587.079-.518-.031-.47-.071-.462-.086-.486.384.258.392.235.306.228.314.274.337.321.365-.528.31-.585.204-.603.063-.549.008-.486-.032-.517.463.376.66.604.391.423.338.455.337.533.204.479.188.563.118.4.314-.33.29-.274.18-.172.15-.157.02.321.074.326.157.454.243.85.074.485.012.448-.01.4-.037.369-.07.43-.228.651-.243.455-.314.376-.432.314-.337.203-.635.228-.502.094-.44.023z" fill="#ff2929" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.151"/>
                        <path d="M19.405 28.908l.017.556-.004.436-.047.433-.072.318-.072.229-.055.228-.05.297.003.34.038.275.094.305.12.218.105.197.077.127.105.134.114.117.14.116.152.09.163.082.175.075.187.077.182.07.185.055.205.051.215.038.314.02.244-.015.134-.013.326-.057.263-.08.276-.13.24-.163.2-.146.188-.189.11-.144.075-.161.06-.132.065-.205.085-.297.06-.299.03-.3.02-.304-.034-.364-.08-.375-.115-.337-.123-.346-.093-.244-.194.217-.186.186-.234.225-.17-.513-.124-.343-.17-.346-.242-.377-.216-.28-.297-.322-.187-.2-.208-.25-.034.255-.034.284-.046.288-.06.28-.076.246-.076.19-.11.212-.166.234-.22.275-.247-.233-.263-.195-.19-.11-.238-.144z" fill="#ff7e29" opacity=".645" stroke="#ff7519" fillOpacity="1" strokeOpacity="1" strokeWidth="0.012"/>
                        <path d="M20.715 32.633l-.007-.27.068-.377.11-.356.119-.263.148-.276.17-.216.274-.286.214.29.186.276.144.258.114.24.102.293.078.27.012.276-.042.246-.084.185-.126.174-.168.102-.243.096-.216.036-.23-.02-.185-.067-.194-.135-.123-.132-.08-.148z" fill="#ffde29" opacity=".645" stroke="#ffd800" fillOpacity="1" strokeOpacity="1" strokeWidth="0.012"/>
                        <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff228" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.164"/>
                        <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="ivory" fillOpacity="1" strokeOpacity="1" strokeWidth="0.017"/>
                        <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff228" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.164"/>
                        <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="ivory" fillOpacity="1" strokeOpacity="1" strokeWidth="0.017"/>
                        <path d="M21.41 34.214l-.455-.032-.659-.094-.479-.133-.478-.188-.443-.29-.424-.38-.264-.356-.21-.416-.11-.32-.071-.315-.067-.596.04-.533.16-.58.212-.604.18-.587.079-.518-.031-.47-.071-.462-.086-.486.384.258.392.235.306.228.314.274.337.321.365-.528.31-.585.204-.603.063-.549.008-.486-.032-.517.463.376.66.604.391.423.338.455.337.533.204.479.188.563.118.4.314-.33.29-.274.18-.172.15-.157.02.321.074.326.157.454.243.85.074.485.012.448-.01.4-.037.369-.07.43-.228.651-.243.455-.314.376-.432.314-.337.203-.635.228-.502.094-.44.023z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="0.25" strokeWidth="0.151"/>
                        <path d="M19.405 28.908l.017.556-.004.436-.047.433-.072.318-.072.229-.055.228-.05.297.003.34.038.275.094.305.12.218.105.197.077.127.105.134.114.117.14.116.152.09.163.082.175.075.187.077.182.07.185.055.205.051.215.038.314.02.244-.015.134-.013.326-.057.263-.08.276-.13.24-.163.2-.146.188-.189.11-.144.075-.161.06-.132.065-.205.085-.297.06-.299.03-.3.02-.304-.034-.364-.08-.375-.115-.337-.123-.346-.093-.244-.194.217-.186.186-.234.225-.17-.513-.124-.343-.17-.346-.242-.377-.216-.28-.297-.322-.187-.2-.208-.25-.034.255-.034.284-.046.288-.06.28-.076.246-.076.19-.11.212-.166.234-.22.275-.247-.233-.263-.195-.19-.11-.238-.144z" fill="#fff" opacity=".645" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.012"/>
                        <path d="M20.715 32.633l-.007-.27.068-.377.11-.356.119-.263.148-.276.17-.216.274-.286.214.29.186.276.144.258.114.24.102.293.078.27.012.276-.042.246-.084.185-.126.174-.168.102-.243.096-.216.036-.23-.02-.185-.067-.194-.135-.123-.132-.08-.148z" fill="#fff" opacity=".645" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.012"/>
                        <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="0.25" strokeWidth="0.164"/>
                        <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.017"/>
                        <path d="M9.614 30.334l5.7-5.769-2.034 4.369 2.528.114-5.415 5.74 2.017-4.446z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="0.25" strokeWidth="0.164"/>
                        <path d="M10.935 29.823l2.723-2.874-1.227 2.444 2.161.073-2.566 2.924 1.287-2.515z" fill="#fff" stroke="#fff" fillOpacity="0.25" strokeOpacity="0.25" strokeWidth="0.017"/>
                        <path d="m11.766 65.419 9.043-28.193 3.957 1.018-3.82 27.175z" fill="#fff" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.454"/>
                        <path d="M23.133.67l-9.394 23.771-3.171-1.129L14.393.67z" fill="#fff" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.346"/>
                        <path d="M5.623 45.855l2.313-7.364-7.365-.944 5.477-5.712-5.335-5.38 7.082-2.172-1.511-6.75 8.167 3.257 3.824-7.741 2.597 7.694 7.365-3.21-1.889 6.797 8.074.472-5.713 5.664 6.009 3.685-6.387 1.272 3.352 5.806-6.515-.897.047 6.325-5.523-4.248-5.24 6.23-1.653-7.269z" fill="#255cd0" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.546"/>
                        <path d="M9.257 23.575l-.944-6.184 7.316 3.635 4.107-6.94 1.793 7.506 6.94-2.36-2.455 5.995 7.222 1.133-5.381 4.767 4.909 3.682-5.712.991 2.407 5.381-5.758-1.227-.425 5.523-4.626-4.201-5.381 5.192-.897-6.608-6.844 3.21 2.596-6.467-6.326-1.464 5.193-4.956-4.39-5.098z" fill="#fff" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="1.159"/>
                        <path d="M4.544 26.054l7.734 12.2 3.587-.707 2.678-14.27-3.976.652-1.275 9.228-4.513-7.993z" stroke="#000" fillOpacity="1" strokeOpacity="1" strokeWidth="0.018"/>
                        <path d="M19.874 34.896l-1.502 2.57c3.13 1.381 6.07 2.444 8.968.308 2.623-2.393 2.482-5.087-1.053-7.344-1.677-1.043-3.862-2.276-2.473-2.646.988-.192 2.109.523 3.348 1.101l1.301-2.476c-1.813-1.536-3.879-2.012-6.313-1.25-1.719.71-2.823 1.463-2.9 3.88.188 1.236.9 2.493 2.72 3.792l2.05 1.435c.664.388 1.603.86 1 1.353-1.81.807-2.89.478-5.146-.723z" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.271"/>
                        <path d="M5.57 25.36l7.733 12.2 3.588-.708 2.677-14.269-3.975.651-1.275 9.228-4.513-7.992z" fill="#a30000" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.451"/>
                        <path d="M20.164 33.926l-1.502 2.57c3.129 1.381 6.401 2.596 9.299.46 2.622-2.393 1.82-4.378-2.21-6.942-1.677-1.044-3.036-2.63-1.647-3.001.988-.192 2.439.476 3.678 1.054l1.302-2.476c-1.813-1.535-3.88-2.012-6.314-1.25-1.718.71-2.823 1.463-2.9 3.88.188 1.236.9 2.493 2.72 3.793l2.05 1.434c.664.388 1.604.86 1 1.353-1.81.807-3.22.325-5.476-.875z" fill="#a30000" stroke="#000" fillOpacity="1" strokeDasharray="none" strokeMiterlimit="4" strokeOpacity="1" strokeWidth="0.451"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div className='h-[calc(94px-16px)] text-sm border-gray-700 rounded-lg m-1 border-2 flex flex-col xs:p-[2px] p-[3px] sm:p-[4px]'>
                <p className='text-sm text-center truncate'>Wager amount: {brawlInfo.wagerAmount.toLocaleString()} <img className='w-[16px] inline-block -translate-y-[2px]' src={ShellCoin} /></p>
                {brawlInfo.player1 !== '' && brawlInfo.player2 !== '' ? (
                <div className='flex flex-row'>
                    <p className='w-1/2 text-sm flex flex-row justify-center'>Player 1: <span className='ml-1 flex items-center -translate-y-[1px]'><MetamaskAccountIcon address={brawlInfo.player1} size={16} /></span></p>
                    <p className='w-1/2 text-sm flex flex-row justify-center'>Player 2: <span className='ml-1 flex items-center -translate-y-[1px]'><MetamaskAccountIcon address={brawlInfo.player2} size={16} /></span></p>
                </div>
                ) : (
                <div className='flex flex-row'>
                    <p className='w-1/2 text-sm flex flex-row justify-center'>Player 1: <span className='ml-1 flex items-center -translate-y-[1px]'><img className='w-[16px] h-[16px] rounded-full' src={UnknownOponnent} /></span></p>
                    <p className='w-1/2 text-sm flex flex-row justify-center'>Player 2: <span className='ml-1 flex items-center -translate-y-[1px]'><img className='w-[16px] h-[16px] rounded-full' src={UnknownOponnent} /></span></p>
                </div>
                )}
                <div className='flex flex-row'>
                {turnedOff ? (
                    <div className='w-1/2 flex justify-center'>
                        <button disabled className='grayscale bg-blue-300 rounded-lg py-[2px] px-[5px]'>Join Brawl</button>
                    </div>
                ) : (
                    noTurtleSelected === true && (brawlInfo.player1 !== '' && brawlInfo.player2 == '') ? (
                        <div className='w-1/2 flex justify-center'>
                            <button onClick={() => { joinBrawl(brawlInfo.brawlId, selectedTurtle) }} className='bg-blue-300 rounded-lg py-[2px] px-[5px]'>Join Brawl</button>
                        </div>
                    ) : (
                        <div className='w-1/2 flex justify-center'>
                            <button disabled className='grayscale bg-blue-300 rounded-lg py-[2px] px-[5px]'>Join Brawl</button>
                        </div>
                    )
                )}

                    { (brawlInfo.player1).toLowerCase() === blockchain.account || (brawlInfo.player2).toLowerCase() === blockchain.account ? (
                    <div>
                        <Link to={`/brawls/${brawlInfo.brawlId}`} className='flex bg-green-300 rounded-lg py-[2px] px-[5px]'>Enter game</Link>
                    </div>
                    ) : (
                        brawlInfo.player1 !== '' && brawlInfo.player2 !== '' ? (
                            <div>
                            <Link to={`/brawls/${brawlInfo.brawlId}`} className='flex bg-green-300 rounded-lg py-[2px] px-[5px]'>Spectate</Link>
                        </div>
                        ) : (                    
                        <div>
                            <button disabled className='grayscale flex bg-green-300 rounded-lg py-[2px] px-[5px]'>Enter game</button>
                        </div>)
                    )}
                </div>
            </div>
        </div>
        ) : (
            <></>
        )}
        </div>
    );
}

export default BrawlCard;