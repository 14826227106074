import { useState, useEffect } from 'react';

export function LatestBlock() {
    const [currentBlock, setCurrentBlock] = useState("?");

    useEffect(() => {
        async function fetchBlock() {
            try {
                const response = await fetch('https://explorer.energyweb.org/api?module=block&action=eth_block_number');
                const data = await response.json();
                const blockNumber = parseInt(data.result, 16);
                setCurrentBlock(blockNumber);
            } catch (error) {
                console.error(error);
            }
        }

        const intervalId = setInterval(fetchBlock, 1000); // Fetch block number every second

        return () => clearInterval(intervalId);
    }, []);

    return (
        currentBlock
    );
}
